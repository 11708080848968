<template lang="pug">
h1(v-if="isNew") {{ t.new_data_report }}
  .right
    button.primary(@click="openStudio" style="margin-left: 8px;") {{ t.open_studio || 'Open studio' }}
h1(v-else) {{ `${t.data_report}: ${name}` }}
  button(style="visibility:hidden")
  button.primary(@click="openStudio" style="margin-left: 8px;") {{ t.open_studio || 'Open studio' }}

.block.expand(key="form" v-if="loading === false" )
  .column
    label(v-if="isNew") {{ t.name || 'name' }}
    input(v-if="isNew" type="text" :placeholder="t.name" v-model="name")
  .editor.expand
    .editor-left.expand
      .query
        label {{ t.query || 'query' }}
        // codemirror(v-model="query" :options="{ mode: 'javascript', theme: 'default' }" @changes="handleChanges")
        textarea.code(style="min-height: 50vh;" v-model="query" @input="handleChanges")
      .variables
        label {{ t.variables || 'variables' }}
        // codemirror(v-model="variables" :options="{ mode: 'javascript', theme: 'default' }" @changes="handleChanges")
        textarea.code(v-model="variables" @input="handleChanges")
  .row
    button.mr2.primary(v-if="!isDataReportProduct || this.$route.query.new" :disabled="!hasUnsavedChanges" @click="save") {{ t.save }}
    button.mr2(@click="$router.push($root.appath + 'data-reports')") {{ t.cancel }}
    button(v-if="!isNew && !hasUnsavedChanges" @click="$root.$router.push({ path: $root.appath + 'data-report-run', query: { id } })") {{ t.run }}
</template>

<script>
import dataReportService from '@100-m/hauru/src/services/DataReportService'

export const additions = {}
export default {
  data() {
    return {
      loading: true,
      queries: [],
      variables: '{}',
      id: 0,
      name: '',
      query: '',
      hasUnsavedChanges: false,
      isNew: false,
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.isNew = !this.$route.query.id
      this.loading = true
      this.isDataReportProduct = isNaN(parseInt(this.$route.query.id))
      this.id = this.isDataReportProduct ? this.$route.query.id : parseInt(this.$route.query.id)
      if (this.$route.query.id) {
        const q = await this.getQuery(this.id)
        this.query = q.query
        this.name = q.name
        try {
          this.variables = JSON.stringify(q.variables, null, 2)
        } catch (e) {
          this.variables = '{}'
        }
      } else {
        this.name = ''
        this.id = 0
        this.variables = '{}'
        this.query = 'query {}'
      }
      this.hasUnsavedChanges = false
      this.loading = false
    },
    handleChanges(e) {
      this.hasUnsavedChanges = true
    },
    async getQuery(id) {
      return await dataReportService.getById(id)
    },
    async save() {
      let variables = {}
      if (!this.name) {
        $root.toast({ description: 'Data report should have a valid name.', type: 'error', timeout: 5000 })
        return
      }
      try {
        variables = JSON.parse(this.variables)
      } catch (e) {
        $root.toast({ description: 'Variables JSON is not valid.', type: 'error', timeout: 5000 })
        return
      }
      if (this.isNew) {
        try {
          await dataReportService.create({ name: this.name, query: this.query, variables })
          this.$router.push($root.appath + 'data-reports')
        } catch (e) {
          $root.toast({ description: e.message, type: 'error', timeout: 5000 })
        } finally {
          this.hasUnsavedChanges = false
        }
      } else {
        try {
          await dataReportService.update(this.id, { name: this.name, query: this.query, variables })
          $root.toast({ description: $root.t.saved || 'Saved', type: 'success', timeout: 5000 })
        } catch (e) {
          $root.toast({ description: e.message, type: 'error', timeout: 5000 })
        } finally {
          this.hasUnsavedChanges = false
        }
      }
    },
    openStudio() {
      return window.open(this.studioLink, '_blank')
    },
  },
  computed: {
    url() {
      return `${config.graphqlEndpoint}/dr/${this.id}/run${this.varUrl}`
    },
    varUrl() {
      if (!this.variables) return ''
      let variables
      try {
        variables = JSON.parse(this.variables)
      } catch (e) {
        return ''
      }

      return Object.entries(variables).reduce((a, [k, v]) => {
        return `${a}${a ? '&' : '?'}${k}=${encodeURI(v)}`
      }, '')
    },
    studioLink() {
      const q =
        this.query.length > 5000
          ? this.query
              .replace(/\s+/g, ' ')
              .replace(/\s?{\s?/g, '{')
              .replace(/\s?}\s?/g, '}')
              .replace(/\s?:\s?/g, ':')
          : this.query
      const endpoint = config.graphqlEndpoint
      return `https://studio.apollographql.com/sandbox?endpoint=${encodeURI(
        new URL(endpoint, location.origin).href,
      )}&document=${encodeURI(q)}&variables=${encodeURI(this.variables)}&headers=${encodeURI(
        JSON.stringify({ Authorization: `Bearer ${$root?.profile?.idToken}` }),
      )}`
    },
  },
}
</script>

<style scoped>
.code {
  font-family: monospace;
  min-width: 100%;
  min-height: 80%;
}
.mr2 {
  margin-right: 8px;
}
.editor {
  display: flex;
}
.editor .editor-left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
}
.editor .editor-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
}
.editor .editor-left .query,
.editor .editor-left .variables {
  display: flex;
  flex: auto;
  flex-direction: column;
  margin: 5px;
  position: relative;
}
.editor .editor-left .variables {
  flex: 1;
}
.editor .editor-left .query {
  flex: 2;
}

.editor .CodeMirror {
}
.data-report button svg {
  height: 16px;
  width: 16px;
}
.data-report .buttons button {
  margin-right: 5px;
}
.data-report .vue-codemirror {
  height: calc(100% - 30px);
  margin-top: 30px;
  position: absolute;
  border: 1px solid #e3e3e3;
}
.data-report .CodeMirror {
  height: 100%;
}
</style>
