import { pagesFromGlobRecord, componentsFromGlobRecord, translationFromGlobRecord } from '@100-m/hauru/src/utils/platformBuilder'
import globalMixin from './mixins/global'
import Layout from "./Layout.vue";
import sorting from '../../sorting'

const config = {
  userflow: ['overview', 'performance', 'allocation', 'details'],
  sorting,
  formats: {
    performance: '.2%',
    var_X: '.2%',
    nav: ',.2f',
    aum: ',.5s',
    weight: '.2%',
    allocation: '.1%',
    volatility: '.2%',
    drawdown: '.1%',
    tracking_error: '.2%',
    information_ratio: '.2f',
    sharpe_ratio: '.2f',
    '1y': '.2%',
    '3y': '.2%',
    '1m': '.2%',
    '3m': '.2%',
    ytd: '.2%',
    inception: '.2%',
    interest_rate_sensitivity: '.2f',
    spread_modified_duration: '.2f',
    duration: '.2f',
    ytm: '.2%',
    ytc: '.2%',
    ytm_portfolio: '.2%',
    ytw: '.2%',
    spread: '.0f',
    wam: '.0f',
    wal: '.0f',
    discount_margin: '.0f',
    average_coupon: '.2%',
    average_life_at_call: '.2f',
    average_life: '.2f',
    hedge_cost: '.2%',
    alpha: '.2%',
    beta: '.2f',
    transaction_amount: ',.2f',
    weight_fund_pct: '.2%',
    weight_fund: '.2%',
    exposure_fund: '.2%',
    exposure_benchmark: '.2%',
    contrib_sensi_fund: '.2f',
    contrib_sensi_benchmark: '.2f',
    contrib_sensi_fund_filter: '.2f',
    weight_fund_filter: '.2%',
    score_esg_dirr: '.2f',
    score_e_dirr: '.2f',
    score_s_dirr: '.2f',
    score_g_dirr: '.2f',
    score_carbon_impact_dirr: '.2f',
    score_e: '.2f',
    score_s: '.2f',
    score_g: '.2f',
    score_esg: '.2f',
    carbon_footprint: '.0f',
    score_carbon_impact: '.2f'
  },
}
export default {
  /**
   * App name
   */
  name: 'digital-edition',
  /**
   * Layout component
   */
  layout: Layout,
  /**
   * Listed
   */
  listed: true,
  /**
   * If common, this app will be merged with others
   */
  common: false,
  /**
   * Prefix to apply on the pages routes
   */
  routePrefix: '/digital-edition',
  /**
   * Pages components to map to the router
   */
  pages: pagesFromGlobRecord(import.meta.globEager('./screens/**/*.vue'), './screens'),
  /**
   * Components to add to the app
   */
  components: componentsFromGlobRecord(import.meta.globEager('./components/**/*.vue')),
  /**
   * Translations to add
   */
  translations: translationFromGlobRecord(import.meta.globEager('./translations/**/*.yml')),
  /**
   * Config partial
   */
  config,
  /**
   * globalMixins backport
   */
  globalMixin,
}
