<style>
/* Total ligne in issuer and main line: */
.total { padding: 4px;display: flex; }
.total.border { border-top: 1px solid var(--colors-primary-default);border-bottom: 1px solid var(--colors-primary-default); }
.total > .indicator { display: flex; flex:1;}
.total .indicator div:first-child { font-weight: 600;}
.total .indicator div:nth-child(2){ padding-left: 4px; }
.total .indicator:nth-child(2) { justify-content: flex-end; }
</style>

<template lang="pug">
.row.total.border
  .indicator
      div {{ label1 + ': ' }}
      div {{ value1 }}
  .indicator
      div {{ label2 + ': ' }}
      div {{ value2 }}
</template>

<script>
export const additions = {"props":["label1","value1","label2","value2"]}
export default {
  props:["label1","value1","label2","value2"]
}
</script>
