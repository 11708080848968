<template>
  <div v-if="loading">{{ t.loading }}...</div>
  <div v-else-if="!dataReport">Error fetching data report</div>
  <div v-else>
    <h1>{{ dataReport.name }}</h1>
    <div class="expand block">
      <div class="editor column expand">
        <div class="section expand">
          <label>{{ t.builderSource || 'Builder source' }}</label>
          <select></select>
        </div>
        <div class="section expand">
          <label>{{ t.postProcess || 'Post Process' }}</label>
          <select></select>
        </div>
        <div class="section expand">
          <label>{{ t.mappingSchema || 'Mapping Schema' }}</label>
          <textarea class="code" style="min-height: 50vh" v-model="mappingSchema" @input="handleChanges"></textarea>
        </div>
      </div>
      <div class="row">
        <button class="mr2 primary" :disabled="!activeChanges" @click="save">{{ t.save }}</button>
        <button class="mr2" @click="$router.push($root.appath + 'data-reports')">{{ t.cancel }}</button>
      </div>
    </div>
    <!-- .row
    button.mr2.primary(v-if="!isDataReportProduct || this.$route.query.new" :disabled="!hasUnsavedChanges" @click="save") {{ t.save }}
    button.mr2(@click="$router.push($root.appath + 'data-reports')") {{ t.cancel }}
    button(v-if="!isNew && !hasUnsavedChanges" @click="$root.$router.push({ path: $root.appath + 'data-report-run', query: { id } })") {{ t.run }} -->
  </div>
</template>
<script>
import dataReportService from '@100-m/hauru/src/services/DataReportService'

export const additions = {}
export default {
  data() {
    return {
      loading: true,
      id: null,
      dataReport: null,
      activeChanges: false,
      mappingSchema: '{}',
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      this.id = parseInt(this.$route.query.id)
      this.dataReport = await this.getReport(this.id)
      this.mappingSchema = JSON.stringify(this.dataReport?.settings?.mappingSchema || {})
      this.loading = false
    },
    async getReport(id) {
      return await dataReportService.getById(id)
    },
    async save() {
      this.activeChanges = false
      let mappingSchema
      try {
        mappingSchema = JSON.parse(this.mappingSchema)
      } catch (e) {
        $root.toast({ description: 'Mapping Schema JSON is not valid.', type: 'error', timeout: 5000 })
        return
      }
      const dataReport = { ...this.dataReport, settings: { ...this.dataReport.settings, mappingSchema } }
      await dataReportService.update(this.id, dataReport)
    },
    handleChanges() {
      this.activeChanges = true
    },
  },
  computed: {},
}
</script>
<style scoped>
.code {
  font-family: monospace;
  min-width: 100%;
  min-height: 80%;
}
.mr2 {
  margin-right: 8px;
}
.editor {
  display: flex;
}
.editor .editor-left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
}
.editor .editor-right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
}
.editor .section,
.editor .section {
  display: flex;
  flex: auto;
  flex-direction: column;
  margin: 6px 0;
  position: relative;
}
.editor .editor-left .variables {
  flex: 1;
}
.editor .editor-left .query {
  flex: 2;
}
</style>
