<style scoped>
h1 .search-filters {
  display: flex;
  flex: 1;
  margin-left: 10px;
}
h1 .search-filters .autocomplete {
  min-width: 90% !important;
}

h2 input {
  opacity: 0.6;
  margin: 0;
  width: 400px;
}
.first-line .cell.select {
  height: 18px;
}
.first-line .select-all {
  left: -2px;
}
.spreadsheet + .row {
  margin-top: auto !important;
}
:deep() .cell > * {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
}
.block .row:last-child .ghost {
  margin-right: 16px;
}
.cell.cell-details {
  left: 0 !important;
}
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1 
  span {{ t[$root.screen.path] + ' ('+ reports?.length +')' }}
  .search-filters
    autocomplete.right(:data="reports.reduce((acc, v) => (report_metadata.columns.filter(k => k && typeof v[k] === 'string').map(k => (acc[k] = acc[k] || {}, acc[k][v[k]] =  k === 'schedule' ? cron2def[v[k]] :  v[k])), acc), {})" :options="{ placeholder: t.search }" :modelValue="$root.filters.filter((v, k) => k !== 'domain' && k !== 'year').reduce((acc, v, k) => (v && v.map(v => acc.push([k, v].join('.'))), acc), [])" @update:modelValue="$root.$router.push({ query: Object.assign($root.query.filter((v, k) => !$root.filters.keys().toggle('domain').includes(k)), $event.map(v => v.split('.')).group('0').map(g => g.map('1').join('|'))) })")
.block.expand(v-if="!$root.query.report")
  .row.center.left(v-if="selected.length")
    input(type="text" v-model="launch_date")
    select(@change="onChange($event)" v-model="period")
      option(:value="'weekly'") {{ t['weekly'] || 'weekly'.titleize() }}
      option(:value="'bimonthly'") {{ t['bimonthly'] || 'bimonthly'.titleize() }}
      option(:value="'monthly'") {{ t['monthly'] || 'monthly'.titleize() }}
      option(:value="'quarterly'") {{ t['quarterly'] || 'quarterly'.titleize() }}
      option(:value="'biannual'") {{ t['biannual'] || 'biannual'.titleize() }}
      option(:value="'yearly'") {{ t['yearly'] || 'yearly'.titleize() }}
    button.ghost(tt="Launch selected" @click="launch_selected(selected)")
      svg-icon(name="pt-icon-play")
    button.ghost(tt="Delete selected" @click="confirmRemove=true")
      svg-icon(name="pt-icon-trash")
  spreadsheet.stripped(:data="filtered_reports" :options="report_metadata")
    template(v-slot:header-check)
      input(type="checkbox" :checked="selected.length === filtered_reports.length" v-indeterminate="selected.length > 0 && selected.length !== filtered_reports.length" @click="selected = selected.length === filtered_reports.length ? [] : filtered_reports.map('id')")
    template(v-slot:cell-check="s")
      input(type="checkbox" :checked="selected.includes(s.line.id)" @click.stop="selected = selected.map().toggle(s.line.id)" @mousedown.prevent.stop="")
    template(v-slot:cell-patterns="s")
      div {{s.line.patterns.join(', ')}}
    template(v-slot:cell-workflow-name="s")
      div {{ s.line.workflow }} 
    template(v-slot:cell-schedule="s")
      div(v-if="s.line.tid !== null") {{ cron2def[s.line.schedule] }}
    template(v-slot:cell-template="s")
      div(v-if="s.line.template") {{ s.line.template }}
      div(v-else style="color: red;") Template removed
    template(v-slot:cell-asof="s")
      input(style="max-width: 100px;" :value="$root.query.domain || new Date().minus('1 month').end('month').format()" @change="uquery({'domain': $event.target.value })")
    template(v-slot:cell-code_mg_share="s")
      div(v-if="s.line.context.code_mg_share !== null") {{ s.line.context.code_mg_share }}
    template(v-slot:cell-fund_name="s")
      div {{ s.line.context.fund_name }}
    template(v-slot:cell-fund_id="s")
      div {{ s.line.context.fund_id?.titleize() }}
    template(v-slot:cell-language="s")
      .tt(style="display: flex;" :tt="s.line.language.upper()")
        svg-icon(class="icon" :name="'flag-' + s.line.language.lower().slice(0, 2)")
    template(v-slot:cell-jurisdiction="s")
      .tt(style="display: flex;" :tt="s.line.language.upper()")
        svg-icon(class="icon" :name="'flag-' + s.line.language.lower().slice(-2)")
    template(v-slot:cell-format="s")
      .tt(style="display: flex;" :tt="s.line.format.upper()")
        ui-asset.file_icon(:name="'icon_' + s.line.format.lower()")
    template(v-slot:cell-account="s")
      div {{ s.line.account || '-' }}
    template(v-slot:cell-account-type="s")
      div {{ t[s.line.account_type] || s.line.account_type }}
    template(v-slot:cell-regional="s")
      div {{ t[s.line.regional] || s.line.regional }}
  .row
    button.primary(@click="update_query({ report: 'Report' })") {{ t.create_report }}
    .total(style="margin-left: auto;") {{ 'Total : ' + filtered_reports.length }}
.block.expand(key="form" v-else)
  h2
  .form.expand
    .column
      label {{ t.stamping_pattern || 'stamping_pattern' }}
      autocomplete(:data="stamping_pattern_autocomplete" v-model="createReportForm.patterns")
      label {{ t.workflow }}
      select(v-model="createReportForm.workflowId")
        option
        option(:value="workflow.id" v-for="workflow in workflows.v().filter(w => w && !w.disabled)") {{ workflow.name }}
    .column
      label {{ t.schedule }}
      select(v-model="createReportForm.schedule")
        option
        option(value="0 0 * * *") Daily
        option(value="0 0 * * 0") Weekly
        option(value="0 0 1,15 * *") Bimonthly
        option(value="0 0 1 * *") Monthly
        option(value="0 0 1 3,6,9,12 *") Quarterly
        option(value="0 0 1 6,12 *") Semi-Annual
        option(value="0 0 1 1 *") Yearly
    .column
      label {{ t.template }}
      select(v-model="createReportForm.template")
        option
        <optgroup v-for="(typeList, type) in Object.values(templates).group('type')" :label="type">
          option(:value="template.name" v-for="template in typeList") {{ template.name }}
        </optgroup>
    .column
      label {{ t.funds }}
      autocomplete(:data="sharesAutocomplete" v-model="createReportForm.isin")
    .column
      label {{ t.lang + ' / ' + t.jurisdiction }}
      .row.left
        label.row.center.left(:tt="lang.upper()" v-for="lang in langs")
          input(style="height: 16px;margin: 6px;" type="radio" :value="lang" v-model="createReportForm.lang")
          svg-icon(class="icon" :name="'flag-' + (lang.lower().slice(0, 2))")
          span.space {{ lang.upper() }}
    .column
      label {{ t.format }}
      .row.left
        label.row.center(:tt="format.upper()" v-for="format in formats")
          input(style="height: 16px;margin: 6px;" type="checkbox" checked="true" :value="format" v-model="createReportForm.formats")
          ui-asset.file_icon(:name="'icon_' + format.lower()")
  .row
    button.primary.ghost(@click="$router.push('reports')") {{ t.cancel }}
    button.primary.ghost(@click="createReport") {{ t.create_report }}
    button.primary(@click="reset_form") Clear
popup(:show="confirmRemove")
  template(v-slot:header)
    | {{t["confirmation_remove"]}}
  template(v-slot:content)
    | {{t["text_remove"]}}
  template(v-slot:action)
    button.secondary-action(@click="confirmRemove = false") {{t['confirmation_btn_close']}}
    button.main-action(@click="delete_selected(selected)") {{t["confirmation_btn_delete"]}}
</template>

<script>
import { useWorkflows } from '../composables/useWorkflows'
import { usePresses } from '../composables/usePresses'
import useRunId from '../composables/useRunId'
import { useFirebaseTranslations } from '../composables/useFirebaseTranslations'
import { useTemplates } from '../composables/useTemplates'
import { computed } from 'vue'
import { useConfig } from '../composables/useConfig'
import { useProgress } from '../composables/useProgress'
import { useShares } from '../composables/useShares'
import templateService from '../../../services/TemplateService'
import Popup from '../../../components/ui/popup.vue'

export const additions = { icon: 'ic_storage' }
export default {
  components: { Popup },
  setup() {
    const { workflows, loaded: wfLoaded } = useWorkflows()
    const { presses, loaded: prLoaded, initialized } = usePresses()
    const { shares, fund_context, loaded: sLoaded } = useShares(false, false, $root.config.extra_share_characteristics)
    const { genRunId } = useRunId()
    const { langs, loaded: tLoaded } = useFirebaseTranslations()
    const { templates, loaded: tplLoaded } = useTemplates()
    const { config, define_scope_fn, loaded: confLoaded } = useConfig()
    const { progress } = useProgress([wfLoaded, prLoaded, tLoaded, tplLoaded, confLoaded, sLoaded])

    // Initial loading
    const isLoading = computed(() => progress.value !== 1 && !initialized.value)

    const templateNames = computed(() => Object.keys(templates.value))

    const sharesAutocomplete = computed(() => {
      return shares.value
        .map(s => ({
          id: s.share_id,
          name: [s.fund_name, s.characterstics?.share_letter || '', `(${s.share_id})`].filter(s => s).join(' - '),
        }))
        .sort((a, b) => a.name.localeCompare(b.name))
        .reduce((acc, s) => {
          acc[s.id] = s.name
          return acc
        }, {})
    })

    return {
      workflows,
      presses,
      shares,
      genRunId,
      fund_context,
      langs,
      templates,
      templateNames,
      config,
      define_scope_fn,
      isLoading,
      progress,
      sharesAutocomplete,
    }
  },
  data() {
    return {
      confirmRemove: false,
      selected: [],
      period: 'monthly',
      launch_date: $root.query.domain || new Date().minus('1 month').end('month').format().slice(0, 7),
      createReportForm: {
        name: 'Report',
        schedule: '',
        workflowId: null,
        template: '',
        patterns: [],
        isin: null,
        lang: '',
        formats: [],
      },
    }
  },
  methods: {
    onChange(event) {
      this.period = event.target.value
      switch (event.target.value) {
        case 'weekly':
          this.launch_date = new Date().minus('7 days').format() + '|' + new Date().format()
          break
        case 'bimonthly':
          this.launch_date = new Date().minus('14 days').format() + '|' + new Date().format()
          break
        case 'monthly':
          this.launch_date = new Date().minus('1 month').end('month').format().slice(0, 7)
          break
        case 'quarterly':
          this.launch_date =
            new Date().minus('3 month').end('month').format().slice(0, 7) +
            '|' +
            new Date().minus('1 month').end('month').format().slice(0, 7)
          break
        case 'biannual':
          this.launch_date =
            new Date().minus('6 month').end('month').format().slice(0, 7) +
            '|' +
            new Date().minus('1 month').end('month').format().slice(0, 7)
          break
        case 'yearly':
          this.launch_date =
            new Date().minus('1 month').start('years').format('YYYY-MM') +
            '|' +
            new Date().minus('1 month').end('month').format('YYYY-MM')
          break
        default:
          this.launch_date = new Date().minus('1 month').end('month').format().slice(0, 7)
          break
      }
    },
    define_asof(launch_date, period) {
      try {
        if (!this.config.reporting_asof) return '' // ASOF not enabled
        switch (period) {
          case 'weekly':
            return launch_date.split('|')[1]
          case 'bimonthly':
            return launch_date.split('|')[1]
          case 'monthly':
            return new Date(launch_date).end('month').format()
          case 'quarterly':
            return new Date(launch_date.split('|')[1]).end('month').format()
          case 'biannual':
            return new Date(launch_date.split('|')[1]).end('month').format()
          case 'yearly':
            return new Date(launch_date.split('|')[1]).end('month').format()
          default:
            return ''
        }
      } catch (error) {
        console.error(launch_date, period, error)
        $root.toast({ description: 'error_launch_press', type: 'error', timeout: 5000 })
      }
    },
    variablesToUrlParams(vars) {
      return Object.keys(vars)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(vars[key])}`)
        .join('&')
    },
    // get_period(date) {
    //   const yearly_regex = /[0-9]{4}-01\|[0-9]{4}-12/g
    //   const biannual_regex = /[0-9]{4}-((01)|(07))\|[0-9]{4}-((06)|(12))/g
    //   const quarterly_regex = /[0-9]{4}-((01)|(04)|(07)|(10))\|[0-9]{4}-((03)|(06)|(09)|(12))/g
    //   const monthly_regex = /[0-9]{4}-[0|1][0-9]/g
    //   if (date.match(yearly_regex)) return 'yearly'
    //   if (date.match(biannual_regex)) return 'biannual'
    //   if (date.match(quarterly_regex)) return 'quarterly'
    //   if (date.match(monthly_regex)) return 'monthly'
    //   return date
    // },
    async launch_selected(lines) {
      const presses = lines.map(id => this.presses[id]).filter()
      if (presses.find(l => !this.workflows.find(w => w.id === l.workflow_id)))
        return $root.toast({ description: 'Workflow is not valid', type: 'error', timeout: 5000 })
      if (presses.find(l => !this.templateNames.find(t => t === l.template)))
        return $root.toast({ description: 'Template is not valid', type: 'error', timeout: 5000 })
      if (
        this.launch_date &&
        !this.launch_date.includes('|') &&
        !this.launch_date.includes('Q') &&
        isNaN(new Date(this.launch_date || 0))
      )
        return $root.toast({ description: 'Date is not valid', type: 'error', timeout: 5000 })
      const new_runs = (await Promise.all(presses.map(this.launch_press))).reduce((acc, v) => {
        acc[v.id] = v
        return acc
      }, {})
      update('data.runs', new_runs)
      const years = new_runs
        .map(r => r.year)
        .reduce((acc, m) => {
          acc[m] = true
          return acc
        }, {})
      update('data.runs_years', years)
      $root.toast({ description: 'report_launched', type: 'success', timeout: 5000 })
    },
    async launch_press(line) {
      const share = this.shares.find(s => s.share_id === `${line.isin}`)
      if (!share) throw new Error('Share not found')
      const year = this.launch_date.split('|')[0].split('-')[0]
      const templateBuilder = await templateService.get(line.template)

      const context = {
        ...this.fund_context[line.isin],
        period: this.period,
        owner: $root?.profile?.email,
        run_time: new Date().toISOString(),
        asof: this.define_asof(this.launch_date, this.period),
        isin: line.isin,
        date: this.launch_date,
        domain: this.launch_date,
        closing_date: this.launch_date.includes('|') ? this.launch_date.split('|')[1] : this.launch_date, // If I want synchro a quarterly and a monthly workflow (Amiral case)
        language: line.language,
        format: line.format,
        patterns: line.patterns.map(d => this.stamping_pattern[d]), // commandr need pattern function
        patterns_name: line.patterns.join(','), // we want to see pattern name in fonds screens
        template: line.template,
        title: '-',
        account: '-',
        dataReportId: templateBuilder?.dataReportId || null,
        templateId: templateBuilder.id,
      }
      if (templateBuilder?.layout?.path) {
        // case of an Excel report
        context.excelTemplateFile = templateBuilder.layout.path
        const varParams = this.variablesToUrlParams({
          fundId: context.fundId,
          isin: context.isin,
          domain: context.domain,
          date: context.date,
          asof: context.asof,
          language: context.language,
        })
        const baseUrl = config.graphqlEndpoint.match(/http:\/\/|https:\/\/|localhost/)
          ? ''
          : `${window.location.protocol}//${window.location.host}`
        //context.data_report_url = `https://greenrock-impress-e2e.nx.digital/dr/dr/${context.dataReportId}/run?${varParams === '' ? 'dummy=0' : ''}${varParams}`
        context.data_report_url = `${baseUrl}${config.graphqlEndpoint}/dr/${context.dataReportId}/run?${varParams === '' ? 'dummy=0' : ''}${varParams}`
        console.log('context.data_report_url', context.data_report_url)
      } else {
        // not an Excel report, so templateBuilder.layout.theme is defined and the landscape key makes sense
        context.landscape = templateBuilder?.layout?.theme?.page_orientation
          ? templateBuilder.layout.theme.page_orientation === 'landscape'
          : this.templates[line.template].theme.includes('pdf-page landscape')
      }
      const id = await this.genRunId()
      const log = { id: 1, status: 'queued', action_id: 1 }
      console.log('context', context)
      return {
        context,
        id,
        year,
        press: line,
        workflow: this.workflows.find(w => w.id === line.workflow_id) || {},
        logs: { 1: log },
      }
    },
    delete_selected(lines) {
      const presses = this.presses.map(presse => {
        if (lines.includes(presse.id)) {
          presse.disabled = true
        }
        return presse
      })
      set('data.presses', presses)
      this.confirmRemove = false
    },
    is_press_exist(existing_presses, newPress) {
      return (
        existing_presses.filter(
          press =>
            !press.disabled &&
            newPress.name === press.name &&
            // patterns === press.pattern && ???
            newPress.workflow_id === +press.workflow_id &&
            newPress.schedule === press.schedule &&
            newPress.template_id === +press.template_id &&
            newPress.isin === press.isin &&
            newPress.language === press.language &&
            newPress.format === press.format,
        ).length > 0
      )
    },
    createReport() {
      const { name, patterns, workflowId, schedule, template, isin, lang, formats } = this.createReportForm
      if (!this.isFilled(this.createReportForm))
        return $root.toast({ description: 'missing_mandatory_fields', type: 'error', timeout: 5000 })

      const existing_presses = this.presses || []
      const context = this.fund_context[isin]
      for (const format of formats) {
        const newPress = {
          format,
          fund_id: context.fund_id || isin,
          id: (existing_presses.v().last() || {}).id + 1 || 0, // existing_presses.v().last()?.id || 0
          isin, // ????
          language: lang,
          name,
          schedule,
          template,
          workflow_id: +workflowId,
          patterns: patterns.map(p => this.stamping_pattern.find(stamping => stamping === p)).filter(),
        }
        if (this.is_press_exist(existing_presses, newPress))
          return $root.toast({
            description: 'A report already exists with these parameters',
            type: 'error',
            timeout: 5000,
          })
        existing_presses.push(newPress)
      }

      set('data.presses', existing_presses) // todo optimise for add only the new press and not all press
      this.reset_form()
      this.$router.push('reports')
    },
    reset_form() {
      this.createReportForm = {
        name: 'Report',
        schedule: '',
        workflowId: null,
        template: '',
        patterns: [],
        isin: null,
        lang: '',
        formats: [],
      }
    },
    isFilled(formData = {}) {
      // NOTE(wassim): maybe change the predicate to a binary operation
      // https://stackoverflow.com/questions/2641347/short-circuit-array-foreach-like-calling-break
      const nonFilled = Object.keys(formData).some(key => {
        // null, undefined or empty array or string
        const value = formData[key]
        if (value === null || value === undefined) return true
        if (typeof value === 'string' && value === '') return true
        if (Array.isArray(value) && value.length === 0) return true
        // empty object
        if (!Array.isArray(value) && typeof value === 'object') return this.isFilled(value)
      })
      return !nonFilled
    },
  },
  computed: {
    stamping_pattern() {
      // console.log('stamping', this.config.stamping_pattern)
      return {
        ...this.config.stamping_pattern, // from firebase (depracted)
        ...$root.config.stamping_pattern, // from module.js in client config code layer
        default: 'docs/[date]/[fund_id]-[isin]-[date]-[language].[format]',
      }
    },
    reports() {
      return (this.presses || {})
        .map(d =>
          Object.assign(
            {
              ...this.fund_context[d.isin],
              workflow: (this.workflows.find(e => e.id === d.workflow_id) || {}).name,
            },
            d,
          ),
        )
        .filter(d => !d.disabled)
        .map(report => {
          return {
            ...report,
            jurisdiction: report.language.slice(-2),
            scope: this.define_scope_fn(report),
          }
        })
    },
    filtered_reports() {
      const authorized_filter = this.report_metadata.columns || [
        'name',
        'template',
        'workflow',
        'fund_id',
        'language',
        'format',
      ]
      const filters = Object.entries($root.query)
        .filter(([k, v]) => !['domain', 'search', 'selected', 'id'].includes(k))
        .filter(d => authorized_filter.includes(d[0]))
        .map(([k, v]) => [k, v.split('|')])
      return this.reports
        .filter(d => !d.disabled)
        .filter(d =>
          filters.every(([k, vs]) =>
            vs.some(
              v =>
                d[k] === v ||
                (v.slice(0, 1) === '>' && d[k] > v.slice(1)) ||
                (v.slice(0, 1) === '<' && d[k] < v.slice(1)),
            ),
          ),
        )
    },
    stamping_pattern_autocomplete() {
      // HACK due to stange autocomplete behaviour
      return this.stamping_pattern.reduce((acc, v, k) => {
        acc[v] = k
        return acc
      }, {})
    },
  },
  watch: {
    '$root.query.report'() {
      this.$nextTick(() => scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' }))
    },
  },
}
</script>
