export default {
  // TODO REMOVE !!!
  data() {
    return {
      sortByOther: top => d => top.concat('other').indexOf(d.x),
      plotclikFilterDimension: dimension => (d, e) =>
        d.x === 'other'
          ? update_filter(
              dimension,
              $root.xf.attribution[dimension]
                .keys()
                .filter(d => !this.post_data(dimension).top_categories.includes(d))
                .join('|'),
            )
          : update_filter(dimension, d.x),
      kpi_rewrite: d => ({ title: d[0], value: format(d[2] || d[0])(d[1]) }),
      positive_kpi: d => d[1] > 0,
      negative_kpi: d => d[1] < 0,
    }
  },
  computed: {
    // period_comments() {
    //   if ($root.period[0] == 'monthly') return 'nxpack.comment'
    //   return 'nxpack.comment_' + $root.period[0]
    // },
    // id_isin() {
    //   if ($root.x && $root.x.mapping.first().keys().includes('id')) return 'id'
    //   return 'isin'
    // },
    // contribution() {
    //   if (!$root.xf || !$root.xf.contribution) return
    //   return $root.xf.contribution.map(dim => dim.map((v, k) => v && v['contribution_' + ($root.query.metric || 'fund')]).filter())
    // },
    // metadata() {
    //   if (!$root.xf || !$root.xf.keys().length) return
    //   const hack_key = $root.xf.allocation ? 'allocation' : 'contribution'
    //   return $root.xf[hack_key].map((dim, dimension) => {
    //     const fmt = $root.query.axis === 'sensitaux' ? 'pt' : $root.config.formats[$root.xf.allocation ? 'allocation' : 'contribution'] || '.2%'
    //     if (dimension.includes('rating'))
    //       return {
    //         dimension,
    //         group: d => d.x.replace(/[+-]/, '').replace('N.A.', 'NR'),
    //         sort: d => d.first().x.slice(0, 1) + [3, 2, 1].indexOf(d.first().x.replace(/-|\+/, '').length),
    //         format: fmt,
    //       }
    //     if (dimension === 'inst_capi_class')
    //       return {
    //         dimension: 'inst_capi_class',
    //         sort: d => ['Large Cap', 'Mid Cap', 'Small Cap', 'Micro Cap', 'NA', 'Unexplained', 'Undefined'].indexOf(d.first().x),
    //       }
    //     if (dimension === 'cap_size_category')
    //       return {
    //         dimension: 'cap_size_category',
    //         sort: d => ['Giant', 'Large', 'Mid', 'Small', 'Cash_Autres', 'NA', 'Unexplained', 'Undefined'].indexOf(d.first().x),
    //       }
    //     if (dimension === 'cap_size_micro')
    //       return { dimension: 'cap_size_micro', sort: d => ['>400', '>300', '>200', '>100', '<100', 'Cash_Autres', ''].indexOf(d.first().x) }
    //     if (dimension === 'years_to_maturity') {
    //       return {
    //         dimension: 'years_to_maturity',
    //         group: d => {
    //           if (d.x < 1) return '0-1'
    //           if (d.x < 3) return '1-3'
    //           if (d.x < 5) return '3-5'
    //           if (d.x < 7) return '5-7'
    //           if (d.x < 10) return '7-10'
    //           if (d.x < 15) return '10-15'
    //           return 'na'
    //         },
    //         sort: d => d.first().x.slice(0, 1),
    //         format: fmt,
    //       }
    //     }
    //     let max = 6
    //     // Avoid huge Other in allocation:
    //     if (hack_key === 'allocation') {
    //       const iteration = dim
    //         .v()
    //         .filter()
    //         .sort(d => -d.weight_fund)
    //         .reduce(
    //           (acc, v, i) => {
    //             //Display at least 80% of allocation
    //             if (acc['sum'] < 0.8) {
    //               acc['sum'] = acc['sum'] + v['weight_' + ($root.query.metric || 'fund')]
    //               acc['iter'] = i
    //             }
    //             return acc
    //           },
    //           { sum: 0, iter: 0 },
    //         )
    //       max = iteration.iter <= 6 ? 6 : iteration.iter
    //     }
    //     return { dimension: dimension, max, format: fmt }
    //   })
    // },
  },
  methods: {
    // animations
    leave(el, done) {
      el.style.opacity = 0
      setTimeout(() => done(), 500)
    },
  },
  mounted() {
    if ($root.screen.path !== 'allocation') {
      update_query({ axis: '' })
    }
  },
}
