import { pagesFromGlobRecord, componentsFromGlobRecord } from '@100-m/hauru/src/utils/platformBuilder'
import config from './index.yml'
import globalMixin from '@100-m/hauru/src/applications/client-edition/mixins/global'

const translations = config.translation

export default {
  /**
   * App name
   */
  name: 'client-edition',
  /**
   * Layout component
   */
  layout: undefined,
  /**
   * Listed
   */
  listed: true,
  /**
   * If common, this app will be merged with others
   */
  common: false,
  /**
   * Prefix to apply on the pages routes
   */
  routePrefix: '/client-edition',
  /**
   * Pages components to map to the router
   */
  pages: pagesFromGlobRecord(import.meta.globEager('./screens/**/*.vue'), './screens'),
  /**
   * Components to add to the app
   */
  components: componentsFromGlobRecord(import.meta.globEager('./components/**/*.vue')),
  /**
   * Translations to add
   */
  translations,
  /**
   * Config partial
   */
  config,
  /**
   * globalMixins
   */
  globalMixin,
}
