<template>
  <div class="flex flex-col">
    <lfr-table :data="data" :options="options"/>
    <div class="details !mt-0 leading-[11.2px] px-1 py-1">
      <div class="flex justify-between gap-2">
        <div class="flex gap-1">
          <div class="font-bold">{{ (options.data.translation[options.firstText] || options.firstText) + ': ' }}</div>
          <div>{{ data.length }}</div>
        </div>
        <div class="flex gap-1">
          <div class="font-bold">{{ (options.data.translation[options.secondText] || options.secondText) + ': ' }}</div>
          <div>{{ sum }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.lfr-table-lines .details {
    border-top: 1px solid rgb(var(--primary))!important;
    border-bottom: 1px solid rgb(var(--primary))!important;
}
.lfr-table-lines td:not(:first-child){
  text-align: end !important;
}
</style>

  
<script setup>
import { computed } from 'vue'
import format from '../../format.js'
const { data, options } = defineProps(['data', 'options'])
const sum = computed(() => {
  if (data[0].constructor === Object) {
    const key = ['weight', 'weight_fund', 'fund'].filter(el => data[0].hasOwnProperty(el))[0]
    return format(data.slice(0, options.limit || Infinity).reduce((acc, a)=> acc+a[key] ,0), options) || 'NA'
  }
  const index = data[0].indexOf('weight')
  return format(data.slice(1, options.limit+1 || Infinity).reduce((acc, a)=> acc+a[index] ,0), options) || 'NA'
})
</script>

<script>
export default {
  api: {
    unit: {
      label: 'Unit',
      default: () => '%',
    },
    digit: {
      label: 'Decimals',
      default: () => 2,
      attrs: {
        type: 'number',
      }
    },
    limit: {
      label: 'Limit',
      default: () => 10,
      attrs: {
        type: 'number',
      }
    },
    highlightFirstLine: {
      label: 'Highlight first line',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    highlightFirstColumn: {
      label: 'Highlight first column',
      default: () => false,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
    firstText: {
      label: 'Text 1',
      default: () => 'line_number',
      attrs: {
        type: 'text',
      }
    },
    secondText: {
      label: 'Text 2',
      default: () => 'main_10_lines',
      attrs: {
        type: 'text',
      }
    }
  },
}
</script>