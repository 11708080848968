<template>
  <builder-block v-bind="props">
    <div class="items start flex flex-col justify-between space-y-1">
      <div v-for="v in globalFootnotes" class="flex flex-row" :key="v.index">
        <sup>{{ v.index }}</sup>
        <span v-html="v.value"></span>
      </div>
    </div>
  </builder-block>
</template>

<script setup>
import useTranslations from '../composables/translations'

const props = defineProps(['data', 'options', 'context'])
const { globalFootnotes } = useTranslations(props, 'global')
</script>
