<style scoped>
.tag input[type='checkbox'] {
  width: 16px;
  height: 16px;
  margin-top: 1px;
  margin-right: 8px;
}

.icon {
  margin-right: 8px;
  fill: var(--primary);
}

.nowrap {
  white-space: nowrap !important;
  flex-wrap: nowrap !important;
}

h3 {
  margin-bottom: 8px;
}

.card {
  padding: 16px;
  border: 4px solid transparent;
  border-radius: 4px;
  background: white;
  box-shadow: 0 2px 4px 0 hsla(198, 45%, 10%, 0.12);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: space-between;
}
.tag {
  padding: 2px 4px;
  border-radius: var(--border-radius);
}

h3 .tag {
  margin: auto 8px auto 0;
}

h3 .count {
  margin: auto 0 auto 8px;
}

.link {
  color: var(--primary);
}

.details {
  width: 100%;
  text-decoration: underline;
}

.card.selected-run {
  background: var(--primary);
}

.card.selected-run h3,
.card.selected-run .row.report,
.card.selected-run .link,
.card.selected-run .icon {
  color: white;
  fill: white;
}

.card .row {
  margin: 8px 0;
}

button.ac {
  background: var(--primary-light);
}

button.ac .column:not(:first-of-type) {
  margin-left: 4px;
  padding-top: 1px;
}

.lang.icon {
  margin: -4px 8px 0 0;
  min-width: 0;
}

.admin-alert {
  color: var(--negative);
  background-color: #ffe5e5;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
}

.preview {
  width: 100%;
  height: 100%;
  background-color: rgb(227, 227, 227);
}
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1 {{ t['my-actions'] }} ({{ myRuns.length }})
.row.expand(style="margin-bottom: 4px;")
  .column.center(v-if="isAdmin()")
    .row.nowrap
      .column(style="margin-right: 12px") {{ t.admin_view }}
      .column
        toggle-switch(@toggle="onToggleAdmin" is-checked unchecked-color="var(--secondary)" checked-color="var(--primary)")
  .column.center
    div.nowrap(style="padding-right: 8px;") {{ t.period_domain }} :
  .column.center
    select(v-model="currentPeriodDomain")
      option(:value="null") {{ t.all }} ({{ myRuns.length }})
      option(:value="x" v-for="x in periodDomainValues") {{ x }} ({{ countMyRunsByPeriodDomain(x) }})
  .column.center
    .row.nowrap
      .column(v-for="v in Object.keys(checkedTagTypes)")
        .tag(style="text-align: left; margin-right: 12px;" :style="tagStyle(tagTypeCode(v))")
          input(type="checkbox" :checked="checkedTagTypes[v]" @click.stop="checkedTagTypes[v] = !checkedTagTypes[v]")
          span {{ t[v] }} ({{ countMyRunsByTagType(v) }})

.row.top(v-if="myRuns.length === 0" style="padding: 8px; flex-grow: 1000;")
  .column {{ t.no_actions }}

template(v-else)
  .row(v-if="myFilteredRuns.length > 0")
    .column.nowrap(style="height:calc(100vh - 130px); overflow: auto;")
      .card(v-for="r in myFilteredRuns" :class="r.id === selectedRunId ? 'selected-run' : ''")
        h3.nowrap
          .tag(:style="tagStyle(tagTypeCode(lastStep(r).type))") {{ t[lastStep(r).type] }}
          strong {{ lastStep(r).name }}
          span.count ({{ lastStep(r).id }}/{{ r.workflow.actions.v().length }})
          .task(:class="[lastLog(r).status, { skipped: lastLog(r).skipped && lastLog(r).skipped !== 'rerun' }]")
        .row.report(v-if="r.context.fund_name")
          div
            svg-icon.lang.icon.value(:name="'flag-' + (r.context.language.lower().slice(0, 2))")
          div.nowrap
            span {{ r.context.fund_name }}
        .row.report.nowrap
          div(v-if="!r.context.fund_name")
            svg-icon.lang.icon.value(:name="'flag-' + (r.context.language.lower().slice(0, 2))")
          div.nowrap
            span {{ periodDomain(r) }}
            span(v-if="r.context.template") - {{ r.context.template.titleize() }}

        .row
          .column
            button.ac(@click.stop="validate_step(r.id, r.logs.v().last().id)")
              .row.nowrap
                .column
                  svg-icon.icon(style="fill: var(--positive);" name="pt-icon-tick")
                .column {{ t.accept }}
          .column
            button.ac(style="margin-left: 16px" :tt="t.reject" @click.stop="reject_step(r.id, r.logs.v().last().id)")
              .row.nowrap
                .column
                  svg-icon.icon(style="fill: var(--negative);" name="pt-icon-cross")
                .column {{ t.reject }}
        .row.nowrap.between(style="margin-bottom: 0;")
          .column(style="margin-right: 16px")
            .row
              .column
                svg-icon.icon(name="pt-icon-arrow-right")
              .column
                router-link.link.details(:to="r.link") {{ t.details }}
          .column
            .row(v-show="r.id !== selectedRunId")
              .column
                svg-icon.icon(name="ic_remove_red_eye")
              .column
                a.link(@click.stop="showPreview(r)") {{ t.show_preview }}
        .row.expand(v-if="isAdmin() && !isAssignedToMe(r)")
          .column.expand.admin-alert
            div {{ t.view_action_admin1 }}
            div {{ t.view_action_admin2 }} {{ stepEmail(r, lastStep(r)) }}.

    .column.expand
      embed.preview(v-if="selectedPdfSrc" type="text/html" :src="selectedPdfSrc")
      div(v-else style="text-align: center;") {{ t.no_preview }}

  .row.top(v-else style="padding: 8px; flex-grow: 1000;")
    .column {{ t.no_actions_filtered }}
</template>

<script>
export const additions = { icon: 'pt-icon-check' }
import { computed } from 'vue'
import { useProduction } from '../composables/useProduction'
import { useShares } from '../composables/useShares'
import { useProgress } from '../composables/useProgress'
export default {
  setup() {
    const { production } = useProduction()
    const { shares, fund_context, loaded: sLoaded } = useShares(false, false, $root.config.extra_share_characteristics)
    const { progress } = useProgress([sLoaded])

    // Initial loading
    const isLoading = computed(() => progress.value !== 1)
    return {
      production,
      isLoading,
      progress,
      shares,
      fund_context,
    }
  },
  data() {
    return {
      filterRunsByEmail: !this.isAdmin(), // true for "normal" users
      currentPeriodDomain: null,
      checkedTagTypes: {
        user_input: true,
        user_validation: true,
      },
      selectedRunId: null,
      selectedPdfSrc: null,
    }
  },
  methods: {
    isAdmin() {
      return $root?.profile && $root?.profile?.role === 'admin'
    },
    onToggleAdmin() {
      this.filterRunsByEmail = !this.filterRunsByEmail
    },
    showPreview(run) {
      this.selectedRunId = run.id
      this.selectedPdfSrc = this.previewUrl(run)
    },
    previewUrl(run) {
      // if the run has a dataReportId, we are in the context of a dataReport, so we use the builder
      if (run?.dataReportId) return `${location.origin}/builder/?runId=${run.id}`
      // else legacy factsheet
      const s = `${location.origin}/digital-edition${this.businessUrl(run)}&cache=false&asof=${run.context?.asof}&token=${
        localStorage.idToken
      }`
      return s
    },
  },
  computed: {
    stepEmail() {
      return (run, step) => {
        const {
          context: { share_id },
        } = run
        let email = step.user
        if (!email) {
          // no email on the step: take the run owner:
          return run.context.owner
        }
        if (email.indexOf('@') > -1) {
          // the email is a true email (i.e. not an alias):
          return email
        }
        // the email is an alias, search for it in the userflows:
        email = (this.fund_context?.share_id && this.fund_context?.share_id[email]) || email
        if (Array.isArray(email)) email = email.join(', ')
        return email
      }
    },
    isAssignedToMe() {
      return run => {
        const theLastStep = this.lastStep(run)
        const email = this.stepEmail(run, theLastStep)
        return !email || [$root?.profile?.email, $root?.profile?.allowed_mailing_list || []].flat().includes(email)
      }
    },
    filteredProduction() {
      // NOTE: this computed has been copied from slash.js ==> need to make it common in the useProduction composable
      let production = this.production.v()
      if (!$root.query.scheduled)
        production = production
          .group(d =>
            ['template', 'isin', 'language', 'domain', 'workflow']
              .map(c => (typeof d[c] === 'object' ? ['wid', d[c].id].join('-') : d[c]))
              .join('-'),
          )
          .map(v => v.last())
          .v()
      if ($root.query.scheduled === 'assignee')
        production = production.filter(r => r.workflow.actions[r.logs.last().action_id].user === $root?.profile?.email)
      if ($root.query.scheduled === 'owner')
        production = production.filter(r => r.context.owner === $root?.profile?.email)
      if ($root.query.scheduled === 'year')
        production = production.filter(r => r.start_date >= new Date().minus('1 year').format())
      if ($root.query.scheduled === 'active')
        production = production.filter(
          r => !(r.workflow.actions.last().id === r.logs.last().action_id && r.logs.last().status === 'success'),
        )

      const filters = Object.entries($root.query)
        .filter(([k, v]) => !['search', 'selected', 'scheduled', 'year', 'action_id'].includes(k))
        .map(([k, v]) => [k, v.split('|')])
      return production.filter(d =>
        filters.every(
          ([k, vs]) =>
            d[k] &&
            vs.some(
              v =>
                `${d[k]}`.replace('.', '') === v ||
                (/^>/.test(v) && d[k] > v.slice(1)) ||
                (/^</.test(v) && d[k] < v.slice(1)),
            ),
        ),
      )
    },
    myRuns() {
      // get all runs for which the current user has something to do
      return this.filteredProduction.filter(run => {
        const theLastStep = this.lastStep(run)
        return (
          ['user_input', 'user_validation'].includes(theLastStep.type) &&
          run.logs.v().last().status === 'running' &&
          !run.disabled &&
          (!this.filterRunsByEmail || this.isAssignedToMe(run))
        )
      })
    },
    myFilteredRuns() {
      // apply the filters selected by the user at the top of the screen
      return this.myRuns.filter(
        run =>
          this.checkedTagTypes[this.lastStep(run).type] &&
          (!this.currentPeriodDomain || this.periodDomain(run) === this.currentPeriodDomain),
      )
    },
    periodDomain() {
      return run => `${run.context.period.titleize()} ${run.context.domain.replace(/\|/g, ' | ')}`
    },
    periodDomainValues() {
      return [...new Set(this.myRuns.map(run => this.periodDomain(run)))].sort()
    },
    tagTypeCode() {
      return tagType => ({ run_command: 4, wait_for: 1, user_input: 9, user_validation: 10 })[tagType]
    },
    tagStyle() {
      return code => `background: var(--cat${code});`
    },
    countMyRunsByPeriodDomain() {
      return perDom => this.myRuns.filter(run => !perDom || this.periodDomain(run) === perDom).length
    },
    countMyRunsByTagType() {
      return tagType => this.myRuns.filter(run => this.lastStep(run).type === tagType).length
    },
    lastStep() {
      return run => (!run.logs.v().last().action_id ? {} : run.workflow.actions[run.logs.v().last().action_id])
    },
    lastLog() {
      return run => run.logs.v().last()
    },
    businessUrl() {
      return run =>
        `/${run.context.fund_id}-${run.context.share_id}/${run.context.template}?app=default&domain=${run.context.domain}&lang=${run.context.language}`
    },
  },
}
</script>
