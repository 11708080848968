<style>

</style>

<template lang="pug">
.column
  brick.risk_disclaimer(title="disclaimer" v-if="(data.share.characteristics.disclaimer_list || []).length > 0 && !$root.lang.includes('be')")
    div(v-html="t[disclaimer] || disclaimer" v-for="disclaimer in data.share.characteristics.disclaimer_list.split(',')")
  brick.risk_disclaimer(title="definitions" v-if="(data.share.characteristics.definition_list || []).length > 0 && !$root.lang.includes('be')")
    div(v-html="t[definition] || definition" v-for="definition in data.share.characteristics.definition_list.split(',')")
  brick.risk_disclaimer(title="disclaimer_be" v-if="(data.share.characteristics.disclaimer_list_be || []).length > 0 && $root.lang.includes('be')")
    div(v-html="t[disclaimer] || disclaimer" v-for="disclaimer in data.share.characteristics.disclaimer_list_be.split(',')")
</template>


<script>
export const additions = {}
export default {
  props: { 'data': Object }
}
</script>
