<style scoped>
:deep() .kpi:is(.active, :hover) {
  cursor: pointer;
  border-color: var(--highlight);
}
.top-button .button {
  width: 300px;
  cursor: pointer;
}

button {
  max-width: 150px;
  cursor: pointer;
  margin-top: 8px;
}
.code-block {
  padding: 8px;
}
.code-block .column:first-child {
  min-width: 75%;
  max-width: 400px;
}
.code-block .column:first-child code {
  min-width: 100%;
  white-space: pre;
  font-size: 0.9em;
  border-left: 4px solid;
  overflow-y: auto;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.code-block .column:first-child input {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.code-block .column:nth-child(2) {
  min-width: 25%;
}
.code-block .column:nth-child(2) div {
  padding: 4px;
}
.code-block .column:nth-child(2) button {
  width: 100px;
}
span.ok {
  color: var(--positive) !important;
  margin-right: 4px;
}
span.warning {
  color: var(--cat1) !important;
  margin-right: 4px;
}
span.error,
:deep() .kpi .value {
  color: var(--negative) !important;
  margin-right: 4px;
}
span.ignored {
  color: var(--inactive) !important;
  margin-right: 4px;
}
span.ignored .button {
  margin-left: 4px;
  padding: 2px 4px;
  font-size: 12px;
}
.code-block.error .column:first-child code {
  border-left-color: var(--negative);
}
.code-block.warning .column:first-child code {
  border-left-color: var(--cat1);
}
.code-block.ignored .column:first-child code {
  border-left-color: var(--inactive);
}
.board .line .cell:first-child {
  align-items: start;
}
.tick_img::after {
  content: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 52 52' width='16pt' height='16pt' style='enable-background:new 0 0 52 52;' xml:space='preserve'><g><path fill='%2328ae71' d='M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M40.495,17.329l-16,18 C24.101,35.772,23.552,36,22.999,36c-0.439,0-0.88-0.144-1.249-0.438l-10-8c-0.862-0.689-1.002-1.948-0.312-2.811 c0.689-0.863,1.949-1.003,2.811-0.313l8.517,6.813l14.739-16.581c0.732-0.826,1.998-0.9,2.823-0.166 C41.154,15.239,41.229,16.503,40.495,17.329z'/></g></svg>");
}
.warning_img::after {
  content: url("data:image/svg+xml;utf8,<svg height='16pt' viewBox='0 0 512 512' width='16pt' xmlns='http://www.w3.org/2000/svg'><path d='m433.347656 512.921875h-352.898437c-27.71875-.003906-53.460938-14.355469-68.039063-37.9375-14.574218-23.578125-15.902344-53.023437-3.511718-77.820313l176.433593-352.914062c13.542969-27.117188 41.253907-44.25 71.566407-44.25s58.023437 17.132812 71.566406 44.25l176.433594 352.914062c12.390624 24.796876 11.0625 54.242188-3.511719 77.820313-14.574219 23.582031-40.320313 37.933594-68.039063 37.9375zm0 0' fill='%23ffb931'/><g fill='%23fff'><path d='m256.898438 128.203125c8.835937 0 16 7.164063 16 16v192c0 8.835937-7.164063 16-16 16-8.835938 0-16-7.164063-16-16v-192c0-8.835937 7.164062-16 16-16zm0 0'/><path d='m240.898438 384.203125h32v32h-32zm0 0'/></g></svg>");
}
.error_img::after {
  content: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 52 52' width='16pt' height='16pt' style='enable-background:new 0 0 52 52;' xml:space='preserve'><path fill='%23cc1c3a' d='M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M38.5,28h-25c-1.104,0-2-0.896-2-2 s0.896-2,2-2h25c1.104,0,2,0.896,2,2S39.604,28,38.5,28z'/></svg>");
}

.kpi.active,
.kpi:hover {
  cursor: pointer;
  border-color: var(--highlight);
}
.top-button .button {
  width: 300px;
  cursor: pointer;
}
.code-block {
  padding: 8px;
}
.code-block .column:first-child {
  min-width: 75%;
  max-width: 400px;
}
.code-block .column:first-child code {
  min-width: 100%;
  max-width: 100%;
  max-height: 200px;
  white-space: pre;
  font-size: 0.9em;
  border-left: 4px solid;
  overflow-y: auto;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.code-block .column:first-child input {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.code-block .column:nth-child(2) {
  min-width: 25%;
  padding-left: 8px;
  padding-right: 8px;
}
.code-block .column:nth-child(2) div {
  padding: 4px;
}
.code-block .column:nth-child(2) button {
  width: 100px;
}
span.ok {
  color: var(--positive) !important;
  margin-right: 4px;
}
span.warning {
  color: var(--cat1) !important;
  margin-right: 4px;
}
span.error,
.kpi .value {
  color: var(--negative) !important;
  margin-right: 4px;
}
span.ignored {
  color: var(--inactive) !important;
  margin-right: 4px;
}
span.ignored .button {
  margin-left: 4px;
  padding: 2px 4px;
  font-size: 12px;
}
.code-block.error .column:first-child code {
  border-left-color: var(--negative);
}
.code-block.warning .column:first-child code {
  border-left-color: var(--cat1);
}
.code-block.ignored .column:first-child code {
  border-left-color: var(--inactive);
}
.board .line .cell:first-child {
  align-items: start;
}
.tick_img::after {
  content: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 52 52' width='16pt' height='16pt' style='enable-background:new 0 0 52 52;' xml:space='preserve'><g><path fill='%2328ae71' d='M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M40.495,17.329l-16,18 C24.101,35.772,23.552,36,22.999,36c-0.439,0-0.88-0.144-1.249-0.438l-10-8c-0.862-0.689-1.002-1.948-0.312-2.811 c0.689-0.863,1.949-1.003,2.811-0.313l8.517,6.813l14.739-16.581c0.732-0.826,1.998-0.9,2.823-0.166 C41.154,15.239,41.229,16.503,40.495,17.329z'/></g></svg>");
}
.warning_img::after {
  content: url("data:image/svg+xml;utf8,<svg height='16pt' viewBox='0 0 512 512' width='16pt' xmlns='http://www.w3.org/2000/svg'><path d='m433.347656 512.921875h-352.898437c-27.71875-.003906-53.460938-14.355469-68.039063-37.9375-14.574218-23.578125-15.902344-53.023437-3.511718-77.820313l176.433593-352.914062c13.542969-27.117188 41.253907-44.25 71.566407-44.25s58.023437 17.132812 71.566406 44.25l176.433594 352.914062c12.390624 24.796876 11.0625 54.242188-3.511719 77.820313-14.574219 23.582031-40.320313 37.933594-68.039063 37.9375zm0 0' fill='%23ffb931'/><g fill='%23fff'><path d='m256.898438 128.203125c8.835937 0 16 7.164063 16 16v192c0 8.835937-7.164063 16-16 16-8.835938 0-16-7.164063-16-16v-192c0-8.835937 7.164062-16 16-16zm0 0'/><path d='m240.898438 384.203125h32v32h-32zm0 0'/></g></svg>");
}
.error_img::after {
  content: url("data:image/svg+xml;utf8,<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 52 52' width='16pt' height='16pt' style='enable-background:new 0 0 52 52;' xml:space='preserve'><path fill='%23cc1c3a' d='M26,0C11.664,0,0,11.663,0,26s11.664,26,26,26s26-11.663,26-26S40.336,0,26,0z M38.5,28h-25c-1.104,0-2-0.896-2-2 s0.896-2,2-2h25c1.104,0,2,0.896,2,2S39.604,28,38.5,28z'/></svg>");
}

.messages {
  display: flex;
  flex-direction: column;
}
.messages code {
  flex: auto;
}
.messages input {
  width: 100%;
  max-width: 100%;
}
.actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 8px;
}
.block.filter_inputs {
  font-size: 0.9em;
}
.block.filter_inputs .row .column {
  padding: 12px;
}
.block.filter_inputs .row .column .fund_name_select {
  width: 360px;
}
.block.filter_inputs .row .column .isin_share_select {
  width: 160px;
}
.block.filter_inputs .row .column .start_date_input,
.start_date_input {
  width: 140px;
}
.block.filter_inputs .row .column .start_date_input,
.end_date_input {
  width: 140px;
}
.filter_inputs button {
  margin-top: 20px;
  margin-left: 28px;
}
/* h1 input { opacity: .6;margin: 0;width: 400px; } */

.text-align-left {
  text-align: left;
  padding-right: 3px;
}
</style>
<template lang="pug">
transition(@leave='leave')
    loader(v-if="!this.loaded" )
template(v-if="this.loaded")
  h1 {{  t[$root.screen.path] }}
  .block.filter_inputs
    .row
      .column
        label {{ t.fund_name_slash_benchmark || 'fund_name / benchmark' }}
        //- autocomplete(:data="compileFilterValues('fund_name')" v-model="filterForm.fund_name" :modelValue="filterForm.fund_name" :options="{ placeholder: filterForm.isin_share ? t.search : '' }")
        select(type=text v-model="filterForm.fund_name" class="fund_name_select")
          option(v-for="fund_name in compileFilterOptions('fund_name')" :value="fund_name") {{ fund_name }}
      .column
        label {{ t.isin || 'Isin' }}
        //- autocomplete(:data="compileFilterValues('isin_share')" v-model="filterForm.isin_share" :modelValue="filterForm.isin_share" :options="{ placeholder: filterForm.isin_share ? t.search : '' }")
        select(type=text v-model="filterForm.isin_share" class="isin_share_select")
          option(v-for="isin_share in compileFilterOptions('isin_share')" :value="isin_share") {{ isin_share }}
      .column
        label {{ t.date_start || 'Start Date'}} 
        input(type="text" v-model="filterForm.start_date" @change="updateFormDate" class='start_date_input' placeholder="AAAA-MM-JJ")
      .column
        label {{ t.end_date || 'End Date'}} 
        input(type="text" v-model="filterForm.end_date" @change="updateFormDate" @input="null" class='end_date_input' placeholder="AAAA-MM-JJ")
      .column
        label 
        button(@click="reset_form") {{ t.clear || 'Clear' }}
  .row.stretch
    kpi(v-for="category in categories" :class="{ active: ($root.query.category || '').includes(category.name) }" @click.native="update_filter('category', category.name)" :data="category.kpiData")
  block()
    //- board(:data="($root.query?.fund_name ? filterDqcList : dqcData || []).filter(d => !$root.query.category || $root.query.category.split('|').includes(d.category)).sort('category')" :metadata="{ expand: 'label', desc: true, columns: ['label', 'category', 'result'] }")
    board(:data="(filterDqcList || []).filter(d => !$root.query.category || $root.query.category.split('|').includes(d.category)).sort('category')" :metadata="{ expand: 'label', desc: true, columns: ['label', 'category', 'result'] }")
      template(v-slot:cell-category="s")
        div {{ t[s.line.category] || s.line.category }}
      template(v-slot:cell-label="s")
        div.text-align-left {{ s.line.label }}
        span.ok(v-if="!s.line.trace.numErrors") {{ t.no_issues }}
        span.error(v-if="s.line.trace.numErrors") {{ s.line.trace.numErrors }} {{ t.errors }}
          //- span.button(@click.prevent.stop="update_query({ hidden: $root.query.hidden ? null : true })") {{ !$root.query.hidden ? t.hide : t.show }}
      template(v-slot:cell-result="s")
        div
          .tick_img(style="height: 18px;margin-top: 4px;" v-if="!s.line.trace.numErrors")
          .error_img(style="height: 18px;margin-top: 4px;" v-if="s.line.trace.numErrors")
      template(v-slot:expand="s")
        block(v-if="(s.line.trace.numErrors) || !$root.query.hidden && ((s.line.messages?.length || []) >0)")
          h2 Errors & warnings
          template(v-for="e in s.line.trace.messages")
            .row.code-block
              .column.messages
                code {{ e }}

</template>
<script>
import { ref, computed, toRaw } from 'vue'
import config from '../../../config'
import dataReportService from '../../../services/DataReportService'

export default {
  icon: 'playlist_add_check',
  setup() {
    const search = ref([])
    const dqcData = ref([])
    const dqc_copy = ref([])
    const dqcReset = ref(0)

    function compileFilterValues(key) {
      if (!dqcData.value.length) return
      const result = dqcData.value
        .sort('category')
        .reduce((acc, check, i) => {
          const v = acc[key] || []
          v.push(check.trace.messages.map(o => o[key]).filter())
          acc[key] = v
          return acc
        }, {})
        .v()
        .flatten(Infinity)
        .sort()
        .unique()
        .reduce((acc, el) => {
          acc[`${el}`] = el
          return acc
        }, {})
      //return result
      return Object.fromEntries(
        Object.entries(result).filter(([_, v]) => !$root.$route.query[key] || $root.$route.query[key] === v),
      )
    }

    function compileFilterOptions(key) {
      if (!dqcData.value.length) return
      const result = dqcData.value
        .sort('category')
        .reduce((acc, check, i) => {
          const v = acc[key] || []
          v.push(check.trace.messages.map(o => o[key]).filter())
          acc[key] = v
          return acc
        }, {})
        .v()
        .flatten(Infinity)
        .sort()
        .unique()
      result.unshift('')
      return result
    }

    function filterMessage(id, arr, filterFields, reset) {
      const filter_keys = filterFields && typeof filterFields === 'object' ? filterFields.keys() || [] : []
      const date_filter_keys =
        filterFields && typeof filterFields === 'object' && filterFields['date'] ? filterFields['date'] || [] : []
      const filter_val = $root.query[id]
      if (!filter_keys.length || !filter_val || reset) {
        return arr
      }
      const result = arr.filter(message =>
        Object.entries(message).some(([k, v]) => {
          if (k !== 'date' && filter_keys.includes(k) && k == id && v === filter_val) {
            return true
          } else if (
            k == 'date' &&
            filter_keys.includes(k) &&
            id === 'start_date' &&
            new Date(v) >= new Date(filter_val)
          ) {
            return true
          } else if (
            k == 'date' &&
            filter_keys.includes(k) &&
            id === 'end_date' &&
            new Date(v) <= new Date(filter_val)
          ) {
            return true
          }
        }),
      )
      return result
    }

    const dqcChecks = computed(() => {
      if ($root.query.fund_name) {
        return toRaw(dqcData)
      }
      return dqcData.value
    })

    const filterDqcList = computed(() => {
      if ($root.query.fund_name || $root.query.isin_share || $root.query.start_date || $root.query.end_date) {
        dqc_copy.value.map((a, i) => {
          dqcData.value[i].trace.messages = a
          dqcData.value[i].trace.numErrors = a.length
        })
        return dqcData.value.reduce((acc, dqc, i) => {
          dqc.trace.messages = filterMessage('fund_name', dqc.trace.messages, dqc.filterFields, dqcReset.value)
          dqc.trace.messages = filterMessage('isin_share', dqc.trace.messages, dqc.filterFields, dqcReset.value)
          dqc.trace.messages = filterMessage('start_date', dqc.trace.messages, dqc.filterFields, dqcReset.value)
          dqc.trace.messages = filterMessage('end_date', dqc.trace.messages, dqc.filterFields, dqcReset.value)
          dqc.trace.numErrors = dqc.trace.messages.length
          acc[i] = dqc
          return acc
        }, [])
      }
      return dqcData.value
    })

    const dqcOptions = computed(() => {
      const baseOptions = {
        dqcNames: ['checkInventaireWithNoAssets', 'checkNoNavJumps'],
        optionsList: [{}, {}],
      }
      return config.dqcOptions || baseOptions
    })

    return {
      search,
      dqcData,
      dqc_copy,
      dqcChecks,
      compileFilterOptions,
      filterDqcList,
      filterMessage,
      dqcReset,
      dqcOptions,
    }
  },
  data() {
    return {
      loaded: false,
      params: {
        lang: this.$route.query.lang || $root.lang,
      },
      filterForm: {
        fund_name: '',
        isin_share: '',
        start_date: '',
        end_date: '',
      },
    }
  },
  watch: {
    'filterForm.fund_name': {
      handler(newValue, oldValue) {
        if (!this.loaded) return
        if (newValue) {
          this.dqcReset = 0
          this.filterForm.fund_name = newValue
        }
        // needs generic isFormEmpty method
        else if (!this.filterForm.isin_share && !this.filterForm.start_date && !this.filterForm.end_date) {
          this.dqcReset = 1
          this.dqc_copy.map((a, i) => {
            this.dqcData[i].trace.messages = a
            this.dqcData[i].trace.numErrors = a.length
          })
          const query = {
            // needs a calculation; not hardcode
            year: '2023',
          }
          $root.$router.push({ query })
        }
        update_query({ fund_name: this.filterForm.fund_name })
      },
      deep: true,
      immediate: true,
    },
    'filterForm.isin_share': {
      handler(newValue, oldValue) {
        if (!this.loaded) return
        if (newValue) {
          this.dqcReset = 0
          this.filterForm.isin_share = newValue
        }
        // needs generic isFormEmpty method
        else if (!this.filterForm.fund_name && !this.filterForm.end_date && !this.filterForm.start_date) {
          this.dqcReset = 1
          this.dqc_copy.map((a, i) => {
            this.dqcData[i].trace.messages = a
            this.dqcData[i].trace.numErrors = a.length
          })
          const query = {
            // needs a calculation; not hardcode
            year: '2023',
          }
          $root.$router.push({ query })
        }
        update_query({ isin_share: this.filterForm.isin_share })
      },
      deep: true,
      immediate: true,
    },
    'filterForm.start_date': {
      handler(newValue, oldValue) {
        if (newValue) {
          this.dqcReset = 0
          this.filterForm.start_date = newValue
          this.dqcData = this.filterDqcList
        }
        // needs generic isFormEmpty method
        else if (!newValue && !this.filterForm.fund_name && !this.filterForm.isin_share && !this.filterForm.end_date) {
          this.dqcReset = 1
          this.dqc_copy.map((a, i) => {
            this.dqcData[i].trace.messages = a
            this.dqcData[i].trace.numErrors = a.length
          })
          const query = {
            // needs a calculation; not hardcode
            year: '2023',
          }
          $root.$router.push({ query })
        }
      },
      deep: true,
      immediate: true,
    },
    'filterForm.end_date': {
      handler(newValue, oldValue) {
        if (newValue) {
          this.dqcReset = 0
          this.filterForm.end_date = newValue
          this.dqcData = this.filterDqcList
        }
        // needs generic isFormEmpty method
        else if (!this.filterForm.fund_name && !this.filterForm.isin_share && !this.filterForm.start_date) {
          this.dqcReset = 1
          this.dqc_copy.map((a, i) => {
            this.dqcData[i].trace.messages = a
            this.dqcData[i].trace.numErrors = a.length
          })
          const query = {
            // needs a calculation; not hardcode
            year: '2023',
          }
          $root.$router.push({ query })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    console.log('mounted', this.params)
    if ($root.query.fund_name) {
      this.filterForm.fund_name = $root.query.fund_name
    }
    if ($root.query.isin_share) {
      this.filterForm.isin_share = $root.query.isin_share
    }
    if ($root.query.start_date) {
      this.filterForm.start_date = $root.query.start_date
    }
    if ($root.query.end_date) {
      this.filterForm.end_date = $root.query.end_date
    }
    if (!this.loaded) {
      await this.getDqcListData()
    }
  },
  methods: {
    update_comment(hash, comment) {
      set('data-quality-ignored.' + hash + '.comment', comment || null)
    },
    ignore_toggle(hash) {
      const ignored_list = this.dataQualityIgnored || {}
      const value = ignored_list[hash]
        ? null
        : { hash, name: $root?.profile?.name, date: new Date().format('YYYY-MM-DD hh:mm') }
      set('data-quality-ignored.' + hash, value)
    },
    async getDqcListData() {
      const resp = await dataReportService.run(
        'drDqcTrace',
        { dqcNames: this.dqcOptions.dqcNames || {}, optionsList: JSON.stringify(this.dqcOptions.optionsList) || {} },
        { preProcess: false, postProcess: false },
      )
      const raw = resp.data.businessData.dqcList
      this.dqcData = raw.sort('category')
      this.dqc_copy = toRaw(this.dqcData).map(o => (o.trace && o.trace.messages ? o.trace.messages : []))
      this.loaded = true
    },
    transformCategories(data) {
      if (!this.loaded) return []
      const countByCategory = data.reduce((acc, item) => {
        const numErrors = item.trace ? item.trace.numErrors : 0
        acc[item.category] = (acc[item.category] || 0) + numErrors
        return acc
      }, {})

      const result = Object.entries(countByCategory).map(([category, count]) => ({
        name: category,
        kpiData: [
          {
            title: category,
            value: count,
          },
        ],
      }))

      return result
    },
    reset_form() {
      this.filterForm = {
        fund_name: '',
        isin_share: '',
        start_date: '',
        end_date: '',
      }
    },
    updateFormDate($event) {
      if ($event.target.className === 'start_date_input') {
        this.filterForm.start_date = $event.target.value
        update_query({ start_date: this.filterForm.start_date })
      }
      if ($event.target.className === 'end_date_input') {
        this.filterForm.end_date = $event.target.value
        update_query({ end_date: this.filterForm.end_date })
      }
      console.log($event)
    },
  },
  computed: {
    categories() {
      return this.transformCategories({ ...this.dqcData })
    },
  },
}
</script>
