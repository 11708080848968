<style>
.esgscore-scale {
  margin: 6px;
  width: 80%;
}
.esgscore-scale > svg {
  width: 100%;
  height: auto;
}
.scale-legend {
  padding-bottom: 10px;
}
.scale-legend svg {
  width: 10px;
  height: 10px;
}
.esgscore-scale p {
  color: var(--colors-primary-default);
}
</style>

<template lang="pug">
.esgscore-scale
  .row.scale-legend.around
    .row
      <svg xmlns="http://www.w3.org/2000/svg">
        <circle cx="50%" cy="50%" r="3" fill="orange"/>
      </svg>
      p {{ t['score_esg'] + ' ' + t['fund'] + ' ' + score_esg_ex_derivative.toFixed(2) }}
    .row(v-if="score_esg_benchmark")
      <svg xmlns="http://www.w3.org/2000/svg">
        <circle cx="50%" cy="50%" r="3" fill="#687d85"/>
      </svg>
      p {{ t['score_esg'] + ' ' + t['univers'] + ' ' + score_esg_benchmark.toFixed(2) }}
  .row.between
    p {{ t['subtitle_esg_coverage'].split(' / ')[0] }}
    p {{ t['subtitle_esg_coverage'].split(' / ')[1] }}
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 18" fill="#2A4371">
    <path d="M0 9 9 0 18 9 9 18 0 9M15 6H385V12H15V6M382 9 391 0 400 9 391 18 382 9"/>
    <circle v-if="score_esg_benchmark" :cx="score_esg_benchmark*10+'%'" cy="9px" r="9px" fill="#687d85"/>
    <circle :cx="score_esg_ex_derivative*10+'%'" cy="9px" r="9px" fill="orange"/>
  </svg>
  .row.between
    p(v-for="(n, i) in 11") {{ i % 2 === 0 ? i : '' }}
</template>

<script>
export default {
  props: {
    score_esg_ex_derivative: {
      type: Number,
      required: true,
    },
    score_esg_benchmark: {
      type: Number,
    },
  },
}
</script>
