<template>
  <lfr-block :data="data" :options="options">
    <div class="mx-auto py-2 w-[210px]">
      <pdf-investment-period :horizon="data" />
    </div>
  </lfr-block>
</template>

<script setup>
const { data, options } = defineProps(['data', 'options'])
$root.t.m = 'mois'
$root.t.ms = 'mois'
$root.t.y = 'an'
$root.t.ys = 'ans'
</script>

<style>
.lfr-investment-period .num.active,
.lfr-investment-period .num.active_only {
  background: rgb(var(--primary));
}
.lfr-investment-period .row {
  display: flex;
}
</style>
