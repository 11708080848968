<style scoped>
.hide { visibility: hidden; }
.screen-documents .block { padding: 0; }
.screen-documents .block iframe { flex: 1; }
.screen-documents .nav-top .tabs { display: none; }
.screen-documents .block iframe {
  flex: 1;
}

</style>

<template lang="pug">
.row
  h1 {{ t.documents || documents }}
  //a.button.primary.download(download target="_blank" :href="'https://exporter.nx.digital/https://' + $root.project + '.nx.digital' + $route.path.replace('/documents', $root.config.screens['factsheet_' + localStorage.PROJECT] ? '/factsheet_' + localStorage.PROJECT : '/factsheet') + '?domain=' + $root.domain.join('|') + '&lang=' + $root.lang + '&token=' + localStorage.id_token + '&fn=pdf&wait=.pdf-page&format=A4&printBackground=true'") {{ t.save_pdf }}
  a.button.primary.download(download target="_blank" :href="downloadLink") {{ t.save_pdf }}
block.expand
  //iframe(:src="'https://exporter.nx.digital/https://' + $root.project + '.nx.digital' + $route.path.replace('/documents', $root.config.screens['factsheet_' + localStorage.PROJECT] ? '/factsheet_' + localStorage.PROJECT : '/factsheet') + '?domain=' + $root.domain.join('|') + '&lang=' + $root.lang + '&token=' + localStorage.id_token + '&fn=pdf&wait=.pdf-page&format=A4&printBackground=true'")
  iframe(:src="link")
</template>

<script>
export const additions = {"icon":"ic_folder_open","datasets":["performance"]}
export default {
  computed: {
    link() {
      return `${window.origin}${this.$route.path.replace('documents', 'factsheet')}?domain=${$root.domain.join('|')}&lang=${$root.lang}`
    },
    downloadLink() {
      return `https://exporter.nx.digital/${this.link}&token=${localStorage.accessToken}&fn=pdf&wait=.pdf-page&format=A4&printBackground=true`
    }
  }
}
</script>
