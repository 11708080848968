export type SortFn = (a: any, b: any) => -1 | 0 | 1
export type VizType =
  | 'bar'
  | 'pie'
  | 'line'
  | 'area'
  | 'dot'
  | 'box'
  | 'radar'
  | 'surface'
  | 'treemap'
  | 'worldmap'
  | 'sunburst'
  | 'table'
export type INxChartOptions = {
  //* Charting Library - default to 'dx'
  lib?: 'nx' | 'dx' | 'cjs' | 'plotly' | 'plot'
  //* Visualization
  viz: VizType
  //* Variant for "Orientation" - only for bar
  horizontal?: boolean
  //* Variant for "Stacking values" - only for bar or area and with a category - default to true
  stack?: boolean
  //* Variant for "Full Stacked values", rebase to percentages - only when stack is true
  fullstack?: boolean
  //* Axis X
  x: string
  //* Axis Y
  y: string
  //* Axis Z - only for dot and surface - color scale for dot
  z?: string
  //* Category - Group X values by category
  category?: string
  //* Aggregate X values - only for multiple values for x - default to 'none'
  aggregate?: 'none' | 'sum' | 'avg' | 'min' | 'max'
  //?* Rebase Y values - only for line, area - automatic for pie
  rebase?: boolean
  //* Sort Function
  sort?: SortFn | string
  // //* Axis X Direction
  // axisX?: 'asc' | 'desc'
  // //* Axis Y Direction
  // axisY?: 'asc' | 'desc'
  //* Format Function for X
  formatX?: (v: any) => string
  //* Format Function for Y
  formatY?: (v: any) => string
  //* Format Function for Z
  formatZ?: (v: any) => string
  //* Format Function for Label
  formatLabel?: (v: any) => string
  //* Maximum number of categories or x values
  limit?: number
  //* Show Other categories or x values
  other?: boolean
  //* Size Ratio - default to 'auto'
  ratio?: number
  //* Color Palette
  palette?: string[]
  //?* Chart Title
  title?: string
  //?* Chart Description
  description?: string
  //?* Legend & Title Position - default to true - automatically change from 'top' to 'left' or 'hide' according to space and ratio
  legend?: boolean
  //?* Tooltip Activation - default to true
  tooltip?: boolean
  //?* Label Activation - default to true
  label?: boolean
  //?* X or Category selected values - only for bar, pie
  selection?: string[]
  //?* Transformer - modify data and options before rendering
  transformer?: (...args: any[]) => any[]
}
export function validate(props: any): { data?: any[]; options?: INxChartOptions; error?: Error } {
  try {
    const options = transformOptions(props.data, props.options)
    const data = transformData(props.data, options)
    let component = `${options.lib}-${options.viz}`
    if (options.lib === 'cjs') component = 'nx-chartjs'
    if (options.lib === 'dx') component = 'nx-devextreme'
    if (options.lib === 'plot') component = 'nx-observable-plot'
    if (options.lib === 'plotly') component = 'nx-plotly'
    return {
      is: component,
      data: data,
      options: options,
    }
  } catch (error) {
    if (!/^(missing|invalid)/i.test(error.message)) error.message = 'unexpected error'
    return { error }
  }
}
export const vizCompatibility = {
  dx: ['bar', 'line', 'area', 'pie', 'dot', 'box', 'radar'],
  cjs: ['bar', 'line', 'area', 'pie', 'dot', 'radar', 'treemap', 'worldmap'],
  plot: ['bar'],
  plotly: ['surface'],
  nx: ['sunburst', 'table', 'line', 'pie', 'bar'],
}
interface TypeCompatibility {
  x: string[]
  y: string[]
  z?: string[]
  category?: string[]
}
export const typeCompatibility: Record<VizType, TypeCompatibility> = {
  bar: { x: ['string'], y: ['number', 'boolean'], category: ['string', 'boolean'] },
  pie: { x: ['string'], y: ['number', 'boolean'] },
  line: { x: ['number', 'date'], y: ['number'], category: ['string', 'boolean'] },
  area: { x: ['number', 'date'], y: ['number'], category: ['string', 'boolean'] },
  dot: { x: ['number'], y: ['number'], z: ['number'], category: ['string', 'boolean'] },
  box: { x: ['string', 'number', 'date'], y: ['number'], z: ['number'] },
  radar: { x: ['string'], y: ['number', 'boolean'], category: ['string', 'boolean'] },
  sunburst: { x: ['string'], y: ['number', 'boolean'], category: ['string', 'boolean'] },
  surface: { x: ['string', 'number'], y: ['string', 'number'], z: ['number'] },
  treemap: { x: [], y: [] },
  worldmap: { x: [], y: [] },
  table: { x: ['string'], y: ['number', 'boolean', 'string'], category: ['string', 'boolean'] },
}

function getDefaultSort(options: INxChartOptions) {
  if (['line'].includes(options.viz)) {
    return options.x
  }
  return '-' + options.y
}
export function transformOptions(data: any[], options: INxChartOptions) {
  const opts = { ...options }

  //* Default "lib"
  if (!opts.viz) throw new Error(`Missing option viz`)
  if (!opts.lib) opts.lib = Object.entries(vizCompatibility).find(([k, v]) => v.includes(opts.viz))?.[0]
  if (!vizCompatibility[opts.lib]?.includes(opts.viz))
    throw new Error(`Invalid option viz=${opts.viz} for lib=${opts.lib}`)

  //* Default "data" options
  if (!['dot', 'surface'].includes(opts.viz) && opts.z) delete opts.z
  if (opts.aggregate == null) opts.aggregate = 'sum'
  if (opts.aggregate === 'count') opts.aggregateFn = grp => grp.filter(v => v[opts.y] != null).length
  if (opts.aggregate === 'sum')
    opts.aggregateFn = grp => grp.filter(v => v[opts.y] != null).reduce((acc, v) => acc + v[opts.y], 0)
  if (opts.aggregate === 'avg')
    opts.aggregateFn = grp =>
      grp.filter(v => v[opts.y] != null).reduce((acc, v) => acc + v[opts.y], 0) /
      grp.filter(v => v[opts.y] != null).length
  if (opts.aggregate === 'min')
    opts.aggregateFn = grp => Math.min(...grp.filter(v => v[opts.y] != null).map(v => v[opts.y]))
  if (opts.aggregate === 'max')
    opts.aggregateFn = grp => Math.max(...grp.filter(v => v[opts.y] != null).map(v => v[opts.y]))
  if (opts.sort == null) opts.sort = getDefaultSort(opts)
  if (opts.limit == null && ['bar', 'pie'].includes(opts.viz)) opts.limit = 10
  if (opts.other == null) opts.other = true
  if (opts.palette == null) opts.palette = ['#ffb931', '#f55a3a', '#cc1c3a', '#169889', '#28ae71', '#a1de66', '#7eeb89', '#55dec1', '#92e5f7', '#7fa0f4'] // prettier-ignore
  if (opts.z && !typeCompatibility[opts.viz]?.z) delete opts.z
  if (opts.category && !typeCompatibility[opts.viz]?.category) delete opts.category
  //! TEMPORARY
  if (opts.viz === 'pie' && opts.label == null) opts.label = false
  if (opts.viz === 'pie' && opts.legend == null) opts.legend = !opts.label
  if (opts.viz === 'table') opts.legend = false
  if (opts.viz === 'table') opts.checks = () => null
  if (opts.viz === 'line' && opts.label == null) opts.label = false
  if (opts.viz === 'area' && opts.label == null) opts.label = false

  //* Default "display" options
  function defaultFormat(v) {
    if (typeof v === 'number') return +v.toPrecision(3)
    if (v instanceof Date) return v.format('YYYY-MM-DD')
    return v
  }
  defaultFormat.default = true // for devextreme to ignore the default formatX
  if (opts.viz !== 'bar' && opts.horizontal) delete opts.horizontal
  if (opts.legend == null) opts.legend = !!opts.category
  if (opts.tooltip == null) opts.tooltip = true
  if (opts.label == null) opts.label = true
  if (opts.formatX == null) opts.formatX = defaultFormat
  if (opts.formatY == null) opts.formatY = defaultFormat
  if (opts.formatZ == null) opts.formatZ = defaultFormat
  if (opts.formatLabel == null) opts.formatLabel = defaultFormat

  if (opts.checks == null)
    opts.checks = (data, options) => {
      const { viz, x, y, z, category, rebase } = options
      if (!data || !data.length) throw new Error(`Missing data`)
      if (!x) throw new Error(`Missing option x`)
      if (!y) throw new Error(`Missing option y`)
      const dx = data.find(x)?.[x]
      const dy = data.find(y)?.[y]
      const dz = z && data.find(z)?.[z]
      const dc = category && data.find(category)?.[category]
      if (!dx) throw new Error(`Invalid option x=${x}`)
      if (!dy) throw new Error(`Invalid option y=${y}`)
      if (z && !dz) throw new Error(`Invalid option z=${z}`)
      if (category && !dc) throw new Error(`Invalid option category=${category}`)
      if (category === x) throw new Error(`Invalid option category is equal to x`)
      if (category && data.map(category).unique().length > 10)
        throw new Error(`Invalid option category.\nToo many categories, max 10`)
      if (rebase && !['line', 'area'].includes(viz)) throw new Error(`Invalid option rebase=${rebase}`)
      //* Validate axis types
      const type = x => Object.prototype.toString.call(x).slice(8, -1).toLowerCase()
      if (!typeCompatibility[viz]?.x.includes(type(dx))) throw new Error(`Invalid type x=${type(dx)}`)
      if (!typeCompatibility[viz]?.y.includes(type(dy))) throw new Error(`Invalid type y=${type(dy)}`)
      if ((z || viz === 'surface') && !typeCompatibility[viz]?.z.includes(type(dz)))
        throw new Error(`Invalid type z=${type(dz)}`)
      if (category && !typeCompatibility[viz]?.category.includes(type(dc)))
        throw new Error(`Invalid type category=${type(dc)}`)
    }

  return opts
}

export function transformData(data: any[], options: INxChartOptions) {
  const { x, y, z, category, aggregateFn, rebase, sort, limit, other, checks } = options

  //! HACK / TEMPORARY
  if (options.lib === 'plot') return data
  if (options.viz === 'box') return data

  //* 0. Check Options Validity
  checks(data, options)
  // If line chart dont group and rebase
  // TODO: confirm that we can ignore aggregate/sort/limit/other options for bubble and surface plots
  if (z) return data

  //* 1. Rebase
  if (rebase) {
    // TODO: decide if we should rebase series that don't start at the same date
    const first = data.reduce((acc, v) => ((acc[v[category]] = acc[v[category]] || v[y]), acc), {})
    data = data.map(v => {
      v[y] = v[y] / first[v[category]] - 1
      return v
    })
  }
  //* 2. Group by X + Aggregate
  data = Object.values(data.group(x)).map(group => ({ [x]: group[0][x], [y]: aggregateFn(group), group }))
  //* 3. Sort
  data = data.sort(sort)
  //* 4. Limit
  if (limit && data.length > limit) {
    const others = data.slice(limit - +other)
    data = data.slice(0, limit - +other)
    //* 5. Regroup Other X + Aggregate
    if (other) data = data.concat({ [x]: 'Other', [y]: aggregateFn(others.flatMap(v => v.group)), group: others })
  }
  return data
}
