interface Domain {
  start: Date
  end: Date
}
/**
 * Parse stringified domain to domain object
 */
export function parseDomainString(domain: string): Domain {
  const yearRegex = /^[0-9]{4}$/ // 2020
  const monthRegex = /^[0-9]{4}[-][0-9]{2}$/ // 2020-01
  const quarterlyRegex = /^[0-9]{4}-Q[0-9]$/
  const monthlyRegex = /^[0-9]{4}[-][0-9]{2}[|][0-9]{4}[-][0-9]{2}$/ // 2020-01|2020-02
  const weeklyRegex = /^[0-9]{4}[-][0-9]{2}[-][0-9]{2}[|][0-9]{4}[-][0-9]{2}[-][0-9]{2}$/ // 2020-01-01|2020-02-01
  const dayRegex = /^[0-9]{4}[-][0-9]{2}[-][0-9]{2}$/ // 2020-01-01
  let start
  let end

  if (yearRegex.test(domain)) {
    const year = parseInt(domain)

    start = new Date(Date.UTC(year, 0, 0))
    end = new Date(Date.UTC(year, 11, 31))

    return { start, end }
  }

  if (quarterlyRegex.test(domain)) {
    const year = parseInt(domain.split('-')[0])
    const quarter = parseInt(domain.split('-')[1].split('Q')[1])
    const month = quarter * 3 - 1

    start = new Date(Date.UTC(year, month, 0))
    end = new Date(Date.UTC(year, month + 3, 0))
    return { start, end }
  }

  if (monthRegex.test(domain)) {
    const month = parseInt(domain.split('-')[1]) - 1 // months are 0 based
    const year = parseInt(domain.split('-')[0])

    start = new Date(Date.UTC(year, month, 0))
    end = new Date(Date.UTC(year, month + 1, 0))

    return { start, end }
  }

  if (monthlyRegex.test(domain)) {
    const startMonth = parseInt(domain.split('|')[0].split('-')[1]) - 1 // months are 0 based
    const startYear = parseInt(domain.split('|')[0].split('-')[0])
    const endMonth = parseInt(domain.split('|')[1].split('-')[1]) - 1 // months are 0 based
    const endYear = parseInt(domain.split('|')[1].split('-')[0])

    start = new Date(Date.UTC(startYear, startMonth, 0))
    end = new Date(Date.UTC(endYear, endMonth + 1, 0))

    return { start, end }
  }

  if (weeklyRegex.test(domain)) {
    start = new Date(domain.split('|')[0])
    end = new Date(domain.split('|')[1])
    return { start, end }
  }

  if (dayRegex.test(domain)) {
    start = new Date(domain.split('|')[0])
    end = start
    return { start, end }
  }

  throw new Error(`Unknown domain format "${domain}"`)
}
