import auth0 from 'auth0-js'
import jwtDecode from 'jwt-decode'

function _parseIdToken(token, persist = true) {
  const decodedToken = jwtDecode(token)
  if (persist) localStorage.idToken = token
  // TODO needs object.__.filter to work
  const custom = decodedToken.__.filter((v, k) => /https:/.test(k)).__.v()
  const generic = decodedToken.__.filter((v, k) => !/https:/.test(k))
  const profile = Object.assign(generic, ...custom)
  profile.idToken = token
  profile.role = profile.role || 'user'
  return profile
}
export default function ({ domain, clientId, project, urls }) {
  window.authentication = new auth0.Authentication({ domain, clientID: clientId })
  window.webAuth = new auth0.WebAuth({ domain, clientID: clientId, responseType: 'id_token' })
  let idToken
  return {
    async login(email, password) {
      return new Promise((resolve, reject) => {
        webAuth.client.login(
          {
            realm: 'Username-Password-Authentication',
            username: email,
            password,
          },
          (err, result) => {
            if (err) return reject(err)
            idToken = result.idToken
            const profile = _parseIdToken(result.idToken)
            resolve(profile)
          },
        )
      })
    },
    async logout() {
      idToken = null
      localStorage.removeItem('idToken')
      localStorage.clear()
      sessionStorage.clear()
    },
    onRequest(config) {
      if (urls.some(url => config.url.startsWith(url))) {
        config.params = {
          project,
          auth0_token: idToken,
        }
      }

      config.headers['Authorization'] = `Bearer ${idToken}`
      return config
    },
    async initAuth() {
      // Login from query string token
      const params = new URLSearchParams(window.location.search)
      if (params.has('token')) {
        idToken = params.get('token')
        return _parseIdToken(idToken, false)
      }

      // Login from localStorage
      if (localStorage.idToken) {
        idToken = localStorage.idToken
        return _parseIdToken(idToken)
      }

      return null
    },
  }
}
