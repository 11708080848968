import { VizType } from '../../../components/common/nx-chart/nx-chart'
interface OptionAttributes {
  type?: 'text' | 'number' | 'checkbox'
  required?: boolean
  placeholder?: string
  class?: string
}

interface BuilderOptionMode {
  label: string
  mode: string
  options: BuilderOptions
}

interface BuilderOptionArgs {
  data_component: any[]
}
export interface BuilderOption {
  label?: string
  select?: (args: BuilderOptionArgs) => string[]
  default?: (args: BuilderOptionArgs) => string | number | boolean
  attrs?: OptionAttributes
  multiple?: {
    values: (args: BuilderOptionArgs) => string[]
    options: BuilderOptions
  }
  modes?: BuilderOptionMode[]
}

export interface BuilderOptions {
  x?: BuilderOption
  y?: BuilderOption
  unit?: BuilderOption
  digit?: BuilderOption
  limit?: BuilderOption
  other?: BuilderOption
  legend?: BuilderOption
  filter?: BuilderOption
  rebase?: BuilderOption
  formatTable?: BuilderOption
  horizontal?: BuilderOption
}

export interface ResolvedBuilderOptions {
  viz: VizType
  x: string
  y: string
  unit: string
  digit: number
  limit: number
  other: boolean
  legend: boolean
  filter: string
  rebase: boolean
  lang: string
  title?: string
  subtitle?: string
  disclaimer?: string
  class?: string | string[]
  horizontal?: boolean
  sort?: string
  sortAsc?: boolean
}

function getXValues({ data_component }: BuilderOptionArgs) {
  return (
    data_component &&
    Object.entries(data_component[0] || {})
      .filter(([k, v]) => typeof v === 'string')
      .map(([k, v]) => k)
  )
}
export const all: BuilderOptions = {
  unit: {
    label: 'Unit',
    default: () => '%',
  },
  digit: {
    label: 'Decimals',
    default: () => 2,
    attrs: {
      type: 'number',
    },
  },
  limit: {
    label: 'Limit',
    default: () => 10,
    attrs: {
      type: 'number',
    },
  },
  other: {
    label: 'Show Other',
    default: () => false,
    attrs: {
      type: 'checkbox',
      class: 'none',
    },
  },
  legend: {
    label: 'Legend',
    default: ({ data_component }) => {
      return !!data_component[0]?.category
    },
    attrs: {
      type: 'checkbox',
      class: 'none',
    },
  },
  filter: {
    label: 'Filter',
    attrs: {
      placeholder: 'YYYY-MM-DD',
    },
  },
  rebase: {
    label: 'Rebase',
    default: () => true,
    attrs: {
      type: 'checkbox',
      class: 'none',
    },
  },
  y: {
    default: () => 'value',
  },
  x: {
    label: 'By',
    select: getXValues,
    default: ({ data_component }) => {
      const keys = getXValues({ data_component })
      return keys.includes('key') ? 'key' : keys[0]
    },
    attrs: {
      required: true,
    },
  },
  horizontal: {
    label: 'Horizontal',
    default: () => true,
    attrs: {
      type: 'checkbox',
      class: 'none',
    },
  },
}

const standard = {
  x: all.x,
  y: all.y,
  unit: all.unit,
  digit: all.digit,
  limit: all.limit,
  other: all.other,
}

export const pie: BuilderOptions = { ...standard, legend: { ...all.legend, default: () => true } }
export const bar = {
  ...standard,
  legend: all.legend,
  horizontal: all.horizontal,
  sort: {
    label: 'Sort by',
    default: () => 'value',
    select: () => ['key', 'value'],
  },
  sortAsc: {
    label: 'Ascending sort',
    default: () => false,
    attrs: {
      type: 'checkbox',
      class: 'none',
    },
  },
}
export const table = { ...standard, legend: null }
export const line = {
  ...standard,
  legend: all.legend,
  unit: { ...all.unit, default: () => null },
  digit: { ...all.digit, default: () => 0 },
  limit: null,
}
function getColumns({ data_component }: BuilderOptionArgs) {
  const mode = Array.isArray(data_component[0]) ? 'array' : 'object'
  return mode === 'array' ? data_component[0] : Object.keys(data_component[0])
}
export const list: BuilderOptions = {
  columns: {
    label: 'Columns',
    multiSelect: {
      values: getColumns,
    },
    default: getColumns,
  },
  showTotal: {
    label: 'Show Total',
    default: () => false,
    attrs: {
      type: 'checkbox',
      class: 'none',
    },
  },
  formatTable: {
    label: 'Format',
    modes: [
      {
        label: 'Format Table',
        mode: 'table',
        options: {
          unit: {
            label: 'Unit',
            default: () => '%',
          },
          digit: {
            label: 'Decimals',
            default: () => 2,
            attrs: {
              type: 'number',
            },
          },
        },
      },
      {
        label: 'Format Columns',
        mode: 'columns',
        options: {
          formats: {
            label: 'Column formats',
            multiple: {
              values: ({ data_component }) =>
                Object.entries(data_component[0] || {})
                  .filter(([k, v]) => typeof v === 'number')
                  .map(([k, v]) => k),
              options: {
                unit: {
                  label: 'Unit',
                  default: () => '%',
                },
                digit: {
                  label: 'Decimals',
                  default: () => 2,
                  attrs: {
                    type: 'number',
                  },
                },
              },
            },
          },
        },
      },
      {
        label: 'Format Rows',
        mode: 'rows',
        options: {
          formats: {
            label: 'Row formats',
            multiple: {
              values: ({ data_component }) => {
                // NOTE: problem with this is the same logic needs to be implemented in the components that use formatTable
                const columns = Object.keys(data_component[0] || {})
                const rowName = columns.includes('name') ? 'name' : columns[0]
                return data_component.map(d => d[rowName])
              },
              options: {
                unit: {
                  label: 'Unit',
                  default: () => '%',
                },
                digit: {
                  label: 'Decimals',
                  default: () => 2,
                  attrs: {
                    type: 'number',
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
  firstColumnWidth: {
    label: 'First Column Width',
    default: () => '',
  },
  tableLayout: {
    label: 'Table layout',
    default: () => 'fixed',
    select: () => ['fixed', 'auto'],
  },
}

export const characteristics = {
  values: {
    label: 'Values',
    multiSelect: {
      values: ({ data_component }) => {
        return Object.keys(data_component)
      },
    },
  },
}
export const text = {
  values: {
    label: 'Values',
    multiSelect: {
      values: ({ data_component }) => {
        return data_component && Object.keys(data_component)
      },
    },
  },
  text: {
    label: 'Text',
    dataList: ({ context }) => {
      return Object.entries(context.translations).map(([key, value]) => ({ value: key, name: value }))
    },
  },
}
