<template>
  <builder-block
    :data="data"
    :options="options"
    :context="context"
    :class="context.isLast ? 'overflow-hidden' : ''"
    ref="block"
  >
    <div class="flex flex-col" ref="element">
      <!-- <div>{{ isOverflow }} {{ context.isLast }} {{ context.path }}</div> -->
      <div v-if="options.text" v-html="formatText(translate(options.text))"></div>
      <template v-for="key in options.values">
        <slot :name="key" :k="key" :value="translate(data[key])" :formattedValue="formatText(translate(data[key]))">
          <div
            class="text-justify leading-tight tracking-normal"
            :class="options?.class"
            v-html="formatText(translate(data[key]))"
            :key="key"
          ></div>
        </slot>
      </template>
    </div>
  </builder-block>
</template>

<script setup lang="ts">
// import { marked } from "marked"
import { ref, computed, onMounted, watch } from 'vue'
import { text as api } from '../composables/builderOptions'
import useOverflow from '../composables/overflow'
const { data, options, context } = defineProps(['data', 'options', 'context'])
const emit = defineEmits(['overflow'])
const block = ref()
const element = ref<HTMLElement>()

function translate(value: string) {
  return context.translations[value] || value
}
function formatText(value: string | number) {
  if (typeof value !== 'string') return value
  return value.replaceAll('\n', '<br>')
}
function onOverflow(value: boolean) {
  emit('overflow', value)
}
const { isOverflow, initOverflow } = useOverflow(element, block, onOverflow)
onMounted(() => {
  initOverflow()
  // const resizeObserver = new ResizeObserver(function () {
  //   if (!block.value) return
  //   isOverflow.value = block.value.element.scrollHeight > block.value.element.clientHeight
  //   emit('overflow', isOverflow.value)
  //   console.log('Size changed')
  // })
  // resizeObserver.observe(element.value)
})
</script>
<script lang="ts">
export default {
  api,
}
</script>
