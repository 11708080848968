<template>
  <button
    class="relative flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-3 py-2 hover:bg-[#00d9b8]/10"
    @click="open = !open"
  >
    <div
      class="text-xl"
      style="--un-icon: url(https://api.iconify.design/material-symbols:history.svg?color=%2302c5a4)"
    ></div>
    <div class="hidden font-semibold 2xl:block">History</div>
    <div class="fixed inset-0 bg-black/10" v-if="open"></div>
    <div class="absolute right-0 top-0 translate-y-12 border bg-white shadow" @click.stop v-if="open">
      <div class="m-2 flex gap-2">
        <button
          class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-2 text-[#00d9b8] hover:bg-[#00d9b8]/10"
          @click="emit('downloadVersion')"
        >
          Download
        </button>
        <label
          class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-2 text-[#00d9b8] hover:bg-[#00d9b8]/10"
        >
          <input type="file" class="hidden" accept="application/json" @input="ev => emit('uploadVersion', ev)" />
          Upload
        </label>
      </div>
      <div class="m-2 flex gap-2" v-for="(version, idx) in versions" :key="idx">
        <div class="flex w-24 truncate">{{ new Date(version.createdAt).format('MM/DD hh:mm') }}</div>
        <div class="flex w-60 truncate">{{ version.user }}</div>
        <div class="flex">{{ version.id }}</div>
        <button
          class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-2 text-[#00d9b8] hover:bg-[#00d9b8]/10"
          @click="emit('restoreVersion', version)"
        >
          Restore
        </button>
        <button
          class="flex items-center gap-1 rounded border border-[#00d9b8]/75 bg-transparent px-2 text-[#00d9b8] hover:bg-[#00d9b8]/10"
          @click="emit('deleteVersion', version)"
          :disabled="versions.length === 1"
        >
          Delete
        </button>
      </div>
    </div>
  </button>
</template>
<script setup>
import { ref } from 'vue'
const open = ref(false)
const props = defineProps(['versions'])
const emit = defineEmits(['restoreVersion', 'deleteVersion', 'downloadVersion', 'uploadVersion'])
</script>
