import { reactive, computed, watch, ref, Ref } from 'vue'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import templateService from '@100-m/hauru/src/services/TemplateService'
import { parseDomainString } from '../lib/domain'
import { DataReportDefinition } from '../builder'

export interface BuilderContext {
  variables?: BuilderVariables & DataReportVariables
  translations?: Record<string, string>
  t: Record<string, string>
}
interface VariablesOptions {
  fund: string[]
  share: Record<string, string[]>
  lang: string[]
  period: string[]
  date: string[]
}
interface BuilderVariables {
  fund: string
  share: string
  lang: string
  period: string
  date: string
}
interface PresetBuilderVariables {
  fund?: string
  share?: string
  lang?: string
  period?: string
  date?: string
}
interface DataReportVariables {
  shareId?: string
  fundId?: string
  benchmarkId?: string
  lang?: string
  period?: string
  domain?: string
  endDate?: string
}

// Regroups fund and share data into a single object to map shareId => to fund and share data
interface ShareInfo {
  shareId: string
  availableDates: string[]
  benchmarkId?: string
  fundId: string
  fundName: string
  axisOfAnalysis?: string[]
  characteristics?: Record<string, any>
}

// TODO: those types should be defined by the typeDef ?
interface Share {
  shareId: string
  availableDates: string[]
  benchmarkId?: string
}

interface Fund {
  fundId: string
  fundName: string
  shares: Share[]
}

const variablesOptions = reactive<VariablesOptions>({
  fund: [],
  share: {},
  lang: [],
  period: [],
  date: [],
})

const variables = reactive<BuilderVariables>({
  fund: '',
  share: '',
  lang: '',
  period: '',
  date: '',
})
const dataReports: Ref<DataReportDefinition[]> = ref([])
const templates: Ref<string[]> = ref([])
let shareMap: Record<string, ShareInfo> = {}

// init varaibles options from the builder context data report query
export async function initVariablesOptions() {
  const { data, error } = await dataReportService.run('builder-context', {}, { preProcess: false, postProcess: false })
  // TODO: on error show error message
  const fundData: Fund[] = data?.funds
  // Map shareId to fundName
  shareMap = fundData.reduce((acc: Record<string, ShareInfo>, fund: Fund) => {
    fund.shares.forEach(
      (share: Share) => (acc[share.shareId] = { ...share, fundId: fund.fundId, fundName: fund.fundName }),
    )
    return acc
  }, {})
  variablesOptions.fund = fundData.map(fund => fund.fundName).sort()
  variablesOptions.share = fundData.reduce((acc: Record<string, string[]>, fund) => {
    acc[fund.fundName] = fund.shares.map(share => share.shareId).sort()
    return acc
  }, {})
  variablesOptions.lang = ['FR', 'DE', 'EN', 'ES', 'FR', 'FR_BE', 'IT', 'NL', 'NL_BE', 'EN_HK', 'ZH_CN', 'ZH_HK'] // TODO get from API
  variablesOptions.period = ['Monthly', 'Quarterly', 'Yearly', 'Custom']
}
// Get preset variables from URL search params or reportVariables
// Check that they are valid with the builder context (exist in variablesOptions)
function getPresetBuilderVariables(
  search: URLSearchParams,
  reportVariables: DataReportVariables,
): PresetBuilderVariables {
  return {
    // TODO: using shareMap here is a bit wonky
    fund: search.get('fund') || (reportVariables.shareId && shareMap[reportVariables.shareId].fundName),
    share: search.get('share') || reportVariables.shareId,
    lang: search.get('lang') || reportVariables?.lang?.toUpperCase(),
    // @ts-expect-error capitalize method extends String prototype
    period: search.get('period') || reportVariables?.period?.capitalize(),
    date: search.get('date') || reportVariables?.domain,
  }
}

function onlyUnique(arr: any[]) {
  return Array.from(new Set(arr))
}
function getDatesFromPeriod(period: string, availableDates: string[]) {
  const sortedDates = availableDates.sort().reverse()
  if (period === 'Monthly') {
    return onlyUnique(sortedDates.map(date => date.slice(0, 7)))
  }
  if (period === 'Quarterly') {
    // @ts-expect-error extended Date prototype
    return onlyUnique(sortedDates.map(date => new Date(date).format('YYYY-QQ')))
  }
  if (period === 'Yearly') {
    // @ts-expect-error extended Date prototype
    return onlyUnique(sortedDates.map(date => new Date(date).format('YYYY')))
  }
  return availableDates
}
function updateDateOptions(period: string, share: ShareInfo) {
  const availableDates = share.availableDates
  if (!availableDates) return
  variablesOptions.date = getDatesFromPeriod(period, availableDates)
}

// Init variables from the URL search params and the dataReport metadata variables
export function initVariables(search: URLSearchParams, reportVariables: DataReportVariables) {
  const presetVariables = getPresetBuilderVariables(search, reportVariables)
  // If fund ex
  variables.fund =
    presetVariables.fund && variablesOptions.fund.includes(presetVariables.fund)
      ? presetVariables.fund
      : variablesOptions.fund[0]
  variables.share =
    presetVariables.share && variablesOptions.share[variables.fund].includes(presetVariables.share)
      ? presetVariables.share
      : variablesOptions.share[variables.fund][0]
  variables.lang =
    presetVariables.lang && variablesOptions.lang.includes(presetVariables.lang)
      ? presetVariables.lang
      : variablesOptions.lang[0]
  variables.period =
    presetVariables.period && variablesOptions.period.includes(presetVariables.period)
      ? presetVariables.period
      : variablesOptions.period[0]
  const activeShare = shareMap[variables.share]
  const dates = getDatesFromPeriod(variables.period, activeShare.availableDates)
  variables.date = presetVariables.date && dates.includes(presetVariables.date) ? presetVariables.date : dates[0]
}
watch(variables, setUrlVariables, { deep: true })
watch(
  () => variables.fund,
  fund => {
    if (!variablesOptions.share[fund] || variablesOptions.share[fund].includes(variables.share)) return
    variables.share = variablesOptions.share[fund][0]
  },
)
watch(
  () => variables.period,
  period => {
    updateDateOptions(period, shareMap[variables.share])
    variables.date = variablesOptions.date[0]
  },
)

watch(
  () => variables.share,
  share => {
    const activeShare = shareMap[share]
    const dates = getDatesFromPeriod(variables.period, activeShare.availableDates)
    if (dates.includes(variables.date)) return
    variables.date = dates[0]
  },
)

// Computed variables that format the builder variables into the format expected by the data report
export const dataReportVariables = computed(() => {
  if (!variables.share) return {}

  const activeShare = shareMap[variables.share]
  const domain = variables.date
  const parsedDomain = domain && parseDomainString(domain)
  const axisDimensions =
    activeShare.axisOfAnalysis &&
    activeShare.axisOfAnalysis.reduce((acc: any, v: string, idx) => {
      acc[`axis_${idx + 1}`] = v
      return acc
    }, {})
  const horizon = activeShare?.horizon
  return {
    shareId: variables.share,
    fundId: activeShare.fundId,
    benchmarkId: activeShare.benchmarkId,
    lang: variables.lang,
    period: variables.period,
    domain,
    endDate: parsedDomain && parsedDomain.end.toISOString().slice(0, 10),
    horizon,
    ...axisDimensions,
    // Legacy, use shareId instead
    isinShare: variables.share,
  }
})

async function getTranslations(lang: string, variables: DataReportVariables) {
  const { data, error } = await dataReportService.run('drTranslations', { lang })
  // NOTE: we could do this to have the app translations, but for now it only returns the eng trads ?
  // const translations = { ...$root.t, ...data }
  const translations = data
  Object.keys(variables)
    .filter(d => d.startsWith('axis_'))
    .forEach((axis: string) => {
      // @ts-ignore
      translations[axis] = translations[variables[axis]] || variables[axis]
    })
  return translations
}
const context: BuilderContext = reactive({})
watch(dataReportVariables, async () => {
  if (!dataReportVariables.value.lang) return
  context.variables = { ...variables, ...dataReportVariables.value }
  context.translations = await getTranslations(dataReportVariables.value.lang, dataReportVariables.value)
  const handler = {
    get(target: any, prop: string) {
      return target[prop] || prop
    },
  }
  context.t = new Proxy(context.translations, handler)
  console.log('Updating context')
})

export function setUrlVariables(variables: BuilderVariables) {
  const url = new URL(window.location.href)
  url.searchParams.set('fund', variables.fund)
  url.searchParams.set('share', variables.share)
  url.searchParams.set('lang', variables.lang)
  url.searchParams.set('period', variables.period)
  url.searchParams.set('date', variables.date)
  window.history.pushState({}, '', url)
}

export async function getDataReports() {
  const _dataReports = await dataReportService.list()
  return _dataReports.filter((dr: any) => typeof dr.id === 'number' || dr.id.includes('factsheet'))
}

async function initBuilder() {
  // Disable usage of built-in data report service (to prevent migration or change in the product)
  dataReports.value = (await getDataReports()).filter((d: DataReportDefinition) => typeof d.id === 'number')
  templates.value = await templateService.list()
  await initVariablesOptions()
  return { dataReports, templates }
}

// Note we dont really need to export this function, we could just use the variables directly,
// keeping it for composables consistency
export default function useBuilderContext() {
  return {
    initBuilder,
    dataReports,
    templates,
    variables,
    variablesOptions,
    dataReportVariables,
    initVariablesOptions,
    initVariables,
    context,
  }
}
