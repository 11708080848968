<template>
  <!-- :class="{ '!table-auto': _props._options.auto }" -->
  <builder-block v-bind="{ data, options, context }" :class="context.isLast ? 'overflow-hidden' : ''" ref="block">
    <!-- <div>{{ colSpans }}</div> -->
    <table
      class="builder-table all-table leading-sm bottom-0 h-fit w-full rounded-lg"
      :style="{ 'table-layout': options.tableLayout || 'fixed' }"
      v-if="data && columns"
      ref="element"
    >
      <!-- <colgroup>
        <col :span="colSpans[col]" v-for="col in columns" />
      </colgroup> -->
      <tr class="table-header">
        <th
          class="table-header-cell overflow-hidden p-1 text-center"
          :style="idx === 0 && options.firstColumnWidth ? { width: options.firstColumnWidth } : ''"
          :key="col"
          :colspan="colSpans[col]"
          v-for="(col, idx) in columns.filter(Boolean)"
        >
          <div v-html="translate(col)"></div>
        </th>
      </tr>
      <tr class="table-row" v-for="(line, idx) in tableData" :key="idx">
        <td
          class="table-cell overflow-hidden whitespace-nowrap p-1 text-center"
          v-for="(col, colIdx) in columns"
          :key="col"
        >
          <div v-html="translate(formatFn(getCellValue(line, col, colIdx), col, line))"></div>
        </td>
      </tr>
      <tr class="total-row" v-if="options?.showTotal">
        <td
          class="total-row-cell overflow-hidden whitespace-nowrap p-1 text-center"
          v-for="(col, idx) in columns"
          :key="col"
        >
          <template v-if="idx === 0">Total</template>
          <template v-else-if="numberColumns.includes(col)">{{ formatFn(getTotal(col), col) }}</template>
        </td>
      </tr>
    </table>
  </builder-block>
</template>
<script setup lang="ts">
import { computed, ref, onMounted } from 'vue'
import { list as api } from '../composables/builderOptions'
import { formatFactory } from '../lib/format'
import { table } from './stories'
import useOverflow from '../composables/overflow'
import useTranslations from '../composables/translations'

const props = defineProps(['data', 'options', 'context'])
const block = ref()
const element = ref<HTMLElement>()

const { translate } = useTranslations(props)

const emit = defineEmits(['overflow'])
function onOverflow(value: boolean) {
  emit('overflow', value)
}
const { isOverflow, initOverflow } = useOverflow(element, block, onOverflow)
onMounted(() => {
  initOverflow()
})
const columns = computed(() => props.options.columns || Object.keys(props.data[0]))
const mode = computed(() => (Array.isArray(props.data[0]) ? 'array' : 'object'))
let currentCol = ''
const colSpans = computed(() =>
  columns.value.reduce((acc, col) => {
    if (!col && currentCol) {
      acc[currentCol] += 1
    } else {
      acc[col] = 1
      currentCol = col
    }
    return acc
  }, {}),
)
const tableData = computed(() => (mode.value === 'array' ? props.data.slice(1) : props.data))
const numberColumns = computed(() => columns.value.filter(col => typeof tableData.value[0][col] === 'number'))
const getCellValue = computed(() => {
  return mode.value === 'array'
    ? (line: any, col: string, colIdx: number) => line[colIdx]
    : (line: any, col: string, colIdx: number) => line[col]
})
function getTotal(col: string) {
  return props.data.reduce((acc: number, line: any) => acc + line[col], 0)
}
const formatFn = computed(() => {
  const formatMode = props.options?.formatTable?.mode
  const lang = props.context.variables.lang
  if (formatMode === 'table') {
    // const _formatFn =
    return formatFactory(props.options.formatTable)
  } else if (formatMode === 'columns' && props.options.formatTable.formats) {
    // debugger
    const formats = Object.fromEntries(
      Object.entries(props.options.formatTable.formats || {}).map(([col, formatData]) => {
        // console.log('col', col, 'formatData', formatData)
        return [col, formatFactory({ ...formatData, lang })]
      }),
    )
    return (v: number, col: string) => {
      return formats[col] ? formats[col](v) : v
    }
  } else if (formatMode === 'rows' && props.options.formatTable.formats) {
    const rowName = columns.value.includes('name') ? 'name' : columns[0]
    const formats = Object.fromEntries(
      Object.entries(props.options.formatTable.formats || {}).map(([name, formatData]) => {
        return [name, formatFactory({ ...formatData, lang })]
      }),
    )
    return (v: number, col: string, row: Record<string, any>) => {
      return formats[row[rowName]] ? formats[row[rowName]](v) : v
    }
  }
  return (v: number, col: string, row?: any[]): string | number => v
})
</script>
<script lang="ts">
export default {
  api,
  styles: {
    'table-header': `font-weight: bold;
.table-header-cell:first-of-type {
  text-align: left;
}`,
    'table-row': `.table-cell:first-of-type {
  text-align: left;
}`,
    'table-cell': `padding: 4px;
text-align: center;`,
    'table-header-cell': `padding: 4px;
text-align: center;`,
    'all-table': {
      name: 'Table',
      css: `
.table-row:nth-child(even) {
  background-color: color-mix(in srgb, var(--primary) 10%, transparent);
}`,
    },
  },
  story: table,
}
</script>
<style scoped>
.builder-list td:first-child {
  width: auto; /* Ensures first column width fits content */
  /* min-width: 100px; */
}
th {
  font-weight: unset;
}
</style>
