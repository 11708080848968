<template>
  <label
    class="my-3 flex text-sm"
    :class="api.multiple || api.modes ? 'flex-col' : 'items-center'"
    v-if="api.label && !(api.hideFn && api.hideFn(blockReport)) && options"
  >
    <div class="w-32 shrink-0">{{ api.label }}</div>
    <template v-if="api.modes">
      <select
        v-bind="{
          class: 'w-48 [font-size:inherit] rounded bg-neutral-600 p-1 focus:outline-none focus-visible:ring-1',
          ...api.attrs,
        }"
        :list="name"
        v-model="selectedMode"
      >
        <option v-for="mode in api.modes" :value="mode" :key="mode.label">{{ mode.label }}</option>
      </select>
      <builderui-api-options
        v-for="(optionApi, optionName) in selectedMode?.options"
        :api="optionApi"
        :name="optionName"
        v-model="options[name]"
        :block-report="blockReport"
        :key="optionName"
      ></builderui-api-options>
    </template>
    <template v-else-if="api.multiSelect">
      <nx-dropdown v-if="true" class="flex-1">
        <template #button="{ shown }">
          <nx-button
            qa-hidden-columns
            class="input-border w-full rounded bg-neutral-600 p-1"
            :active="true"
            :selected="shown"
            :show-label="true"
          >
            <div>Select displayed values</div>
          </nx-button>
        </template>
        <div class="flex flex-col overflow-hidden">
          <div class="input-border max-h-[420px] overflow-y-auto rounded bg-neutral-600 text-white">
            <!-- <div>{{ options[name] }}</div> -->
            <nx-dropdown-select-list
              :list="options[name]"
              :toggle="toggleValue"
              :checked="value => options[name] && options[name].includes(value)"
              @onChange="onChange"
              group="sort"
            />
            <nx-dropdown-select-list
              :list="multiSelectValues"
              :toggle="toggleValue"
              :checked="value => options[name] && options[name].includes(value)"
              @onChange="onChange"
            />
          </div>
          <!-- <div class="flex shrink-0 grow-0 basis-auto gap-2 border-t border-solid border-gray-200 px-2 py-1">
            <nx-button qa-hide-all theme="grid.subactions.hideAll" :disabled="true" />
            <nx-button qa-show-all theme="grid.subactions.showAll" :disabled="false" />
          </div> -->
        </div>
      </nx-dropdown>
      <!-- <div>{{ api.multiSelect.values(blockReport) }}</div> -->
    </template>
    <template v-else-if="api.dataList">
      <input
        v-bind="{
          class: 'w-48 [font-size:inherit] rounded bg-neutral-600 p-1 focus:outline-none focus-visible:ring-1',
          type: 'email',
          ...api.attrs,
        }"
        :list="name"
        :value="options[name]"
        @change="options[name] = $event?.target?.value"
      />
      <datalist :id="name">
        <option
          v-for="option in api.dataList?.(blockReport)"
          :key="option?.value || option"
          :value="option?.value || option"
        >
          {{ option?.name || option?.value || option }}
        </option>
      </datalist>
    </template>
    <template v-else-if="api.multiple">
      <div>
        <select
          v-bind="{
            class: 'w-48 [font-size:inherit] rounded bg-neutral-600 p-1 focus:outline-none focus-visible:ring-1',
            ...api.attrs,
          }"
          :list="name"
          v-model="selectedOption"
        >
          <option v-for="option in multipleValues" :value="option" :key="options">{{ option }}</option>
        </select>
        <!-- <div>{{ options[name] }}</div> -->
        <!-- <div v-if="selectedOption">{{ api.select.options }} - {{ selectedOption }}</div>
        <div v-for="(optionApi, optionName) in api.select.options">{{ optionApi }} - {{ optionName }}</div> -->
        <template v-if="selectedOption && options && options[name] && options[name][selectedOption]">
          <builderui-api-options
            v-for="(optionApi, optionName) in api.multiple.options"
            :api="optionApi"
            :name="optionName"
            :options="options[name][selectedOption]"
            :block-report="blockReport"
            :key="optionName"
          ></builderui-api-options>
        </template>
      </div>
    </template>
    <template v-else-if="api.select">
      <select
        v-bind="{
          class: 'w-48 [font-size:inherit] rounded bg-neutral-600 p-1 focus:outline-none focus-visible:ring-1',
          ...api.attrs,
        }"
        :list="name"
        v-model="options[name]"
      >
        <option v-for="option in api.select?.(blockReport)" :key="option">{{ option }}</option>
      </select>
    </template>
    <template v-else-if="api.type === 'textarea'">
      <textarea
        v-bind="{
          class: '[font-size:inherit] rounded bg-neutral-600 p-1 focus:outline-none focus-visible:ring-1',
          ...api.attrs,
        }"
        :list="name"
        v-model="options[name]"
      ></textarea>
    </template>
    <template v-else>
      <input
        v-bind="{
          class: 'w-48 [font-size:inherit] rounded bg-neutral-600 p-1 focus:outline-none focus-visible:ring-1',
          type: 'text',
          ...api.attrs,
        }"
        :list="name"
        v-model="options[name]"
      />
    </template>
  </label>
</template>
<script setup lang="ts">
import { watch, ref, computed, toRefs, onMounted } from 'vue'
// import { BuilderOption } from '../../builder';

const props = defineProps(['api', 'name', 'blockReport'])
const { api, name, blockReport } = toRefs(props)
const options = defineModel<Record<string, any>>()
const multipleValues = computed(() => props.api.multiple && props.api.multiple.values(props.blockReport))
const selectedOption = ref()
const selectedMode = ref()
// onMounted(() => {
//   selectedOption.value = null
//   selectedMode.value = null
// })
watch(multipleValues, () => {
  if (!props.api.multiple || !multipleValues.value) {
    return (selectedOption.value = null)
  }
  if (!selectedOption.value || !multipleValues.value.includes(selectedOption.value)) {
    return (selectedOption.value = multipleValues.value[0])
  }
})
// HACK: force reset selectedMode when path changes
// there must be a better way to do this but I wasted too much time on this already
// debugger
// let currentPath = props.blockReport.path.join(',')
watch(
  () => props.blockReport,
  () => {
    selectedMode.value = null
    // if (props.api && props.api.modes && currentPath !== props.blockReport.path.join(',')) {
    //   currentPath = props.blockReport.path.join(',')
    // }
  },
)
watch(selectedOption, () => {
  if (!selectedOption.value || !options.value) {
    return
  }
  if (!options.value[props.name]) {
    options.value[props.name] = {}
  }
  if (!options.value[props.name][selectedOption.value]) {
    options.value[props.name][selectedOption.value] = {}
  }
})
watch(selectedMode, () => {
  if (!selectedMode.value) {
    return
  }
  if (!options.value[props.name]) {
    options.value[props.name] = {}
  }
  options.value[props.name].mode = selectedMode.value.mode
  // if (!options.value[props.name][selectedMode.value.mode]) {
  //   options.value[props.name][selectedMode.value.mode] = {}
  // }
})

const multiSelectValues = computed(() => {
  if (!props.api.multiSelect) {
    return
  }
  const values = props.api.multiSelect.values(props.blockReport)
  return values?.filter((v: any) => !options.value[props.name] || !options.value[props.name].includes(v))
})
watch(multiSelectValues, () => {
  if (!multiSelectValues.value) {
    return
  }
  if (!options.value[props.name]) {
    options.value[props.name] = []
  }
  console.log('multiSelectValues', multiSelectValues.value)
})

function toggleValue(value: any) {
  if (!options.value[props.name]) {
    options.value[props.name] = []
  }
  if (options.value[props.name].includes(value)) {
    options.value[props.name] = options.value[props.name].filter((v: any) => v !== value)
  } else {
    options.value[props.name].push(value)
  }
  console.log('toggle', value)
}

function onChange(header: any) {
  console.log('change', header)
}
</script>
<style scoped>
.input-border {
  border-color: #e5e7eb;
  border-width: 1px;
}
</style>
