import type { TemplateLayout, Store, LayoutNode, ComponentNode } from '../builder'
import { Component, Ref, nextTick } from 'vue'

type Direction = 'top' | 'bottom' | 'left' | 'right' | 'overlay'
// @ts-expect-error Object.traverse
Object.traverse = (obj, fnLeaf, fnNode, path = [], root) => {
  if (!obj) return
  if (obj instanceof Object) {
    fnNode(obj, path, root)
    // @ts-expect-error Object.traverse
    return Object.keys(obj).forEach((k, i) => Object.traverse(obj[k], fnLeaf, fnNode, path.concat(k), root || obj))
  }
  return fnLeaf(obj, path, root)
}

export default function useLayout(store: Store, templateRef: Ref<TemplateLayout>) {
  function getNode(path: number[]) {
    if (!path) return
    if (path.length === 0) return templateRef.value
    let current: TemplateLayout | LayoutNode | ComponentNode | undefined = templateRef.value
    path.forEach(p => {
      // @ts-expect-error ComponentNode has no nodes
      current = current?.nodes?.[p]
    })
    return current
  }
  function updateNode(path: number[], data: any) {
    const parentNode = getNode(path.slice(0, -1))
    parentNode.nodes[path.at(-1)] = data
  }
  function updateLayout(path: number[]) {
    nextTick(() => window.dispatchEvent(new CustomEvent('builderUpdate', { detail: { path } })))
  }
  function updateAll() {
    templateRef.value.overflowPages = {}
    const pages = templateRef.value.nodes
    pages.forEach((page, i) => {
      updateLayout([i])
      // Need to updaet all pages and not just the ones under it for the footer page count
      // if (i >= +store.active[0]) {
      //   window.dispatchEvent(new CustomEvent('builderUpdate', { detail: { path: [i] } }))
      // }
    })
  }
  const pageAdd = (offset = 1) => {
    const pages = templateRef.value.nodes
    const newBlock = { component: 'block', type: 'block' }
    pages.splice(+store.active[0] + offset, 0, {
      type: 'column',
      nodes: [{ component: 'header', type: 'block' }, newBlock, { component: 'footer', type: 'block' }],
    })
    store.active = [+store.active[0] + offset]
    // Update all pages
    updateAll()
  }
  const pageDel = (path = store.active) => {
    const pages = templateRef.value.nodes
    pages.splice(path[0], 1)
    updateAll()
  }
  const blockAdd = (dir: Direction, path = store.active) => {
    const layoutNode = getNode(path.slice(0, -1))
    if (!layoutNode) return
    const isLine = layoutNode.type === 'row'
    const isColumn = layoutNode.type === 'column'
    const newBlock = { component: 'block', type: 'block' }
    // CASE 1: Add new block
    if ((isLine && dir === 'right') || (isColumn && dir === 'bottom')) {
      const i = +path.at(-1)
      layoutNode.nodes.splice(i + 1, 0, newBlock)
      store.active = path.slice(0, -1).concat(i + 1)
    }
    if ((isLine && dir === 'left') || (isColumn && dir === 'top')) {
      const i = +path.at(-1)
      layoutNode.nodes.splice(i, 0, newBlock)
      store.active = path.slice(0, -1).concat('' + i)
    }
    // CASE 2: Create new container
    if (isLine && dir === 'bottom') {
      const element = getNode(path)
      layoutNode.nodes[path.at(-1)] = { type: 'column', nodes: [element, newBlock] }
      store.active = path.concat([1])
    }
    if (isLine && dir === 'top') {
      const element = getNode(path)
      layoutNode.nodes[path.at(-1)] = { type: 'column', nodes: [newBlock, element] }
      store.active = path.concat([0])
    }
    if (isColumn && dir === 'right') {
      const element = getNode(path)
      layoutNode.nodes[path.at(-1)] = { type: 'row', nodes: [element, newBlock] }
      store.active = path.concat([1])
    }
    if (isColumn && dir === 'left') {
      const element = getNode(path)
      layoutNode.nodes[path.at(-1)] = { type: 'row', nodes: [newBlock, element] }
      store.active = path.concat([0])
    }
    // CASE 3: overlay
    if (dir === 'overlay') {
      const element = getNode(path)
      element.height = '100%'
      layoutNode.nodes[path.at(-1)] = { type: 'overlay', nodes: [element, newBlock] }
      store.active = path.concat([1])
    }
    updateLayout([path[0]])
  }
  const blockDel = (path = store.active) => {
    // columns.0.lines.0
    const containerPreviousNode = getNode(path.slice(0, -2))
    const containerNode = getNode(path.slice(0, -1))
    // const builderNode = containerNode[path.at(-2)]
    if (containerNode?.nodes?.length === 1) containerPreviousNode.nodes.splice(+path.at(-2), 1)
    else containerNode.nodes?.splice(+path.slice(-1), 1)
    store.active = []
    updateLayout([path[0]])
  }
  const blockMove = $event => {
    const report = JSON.parse(JSON.stringify(templateRef.value))
    // @ts-expect-error Object.traverse
    Object.traverse(
      report,
      (v, path) => null,
      (obj, path, root) => {
        delete obj.path
        delete obj.tag_component
        delete obj.data_component
        if (
          (obj.columns?.length === 1 && obj.columns[0]?.component) ||
          (obj.lines?.length === 1 && obj.lines[0]?.component)
        ) {
          const singleNode = path.slice(0, -1).reduce((acc, p) => acc[p], root)
          singleNode.splice(path.at(-1), 1, (obj.columns || obj.lines)[0])
        }
        if (obj.pages?.length === 0 || obj.columns?.length === 0 || obj.lines?.length === 0) {
          const emptyNode = path.slice(0, -1).reduce((acc, p) => acc[p], root)
          emptyNode.splice(path.at(-1), 1)
        }
      },
    )
    templateRef.value = report
    // const from = $event.from.__draggable_context.element.path.concat($event.from.__draggable_context.element.lines ? 'lines' : 'columns', '' + $event.oldIndex)
    const to = $event.to.__draggable_context.element.path.concat(
      $event.to.__draggable_context.element.lines ? 'lines' : 'columns',
      '' + $event.newIndex,
    )
    store.active = to
  }

  return { pageAdd, pageDel, blockAdd, blockDel, blockMove, getNode, updateNode, updateLayout }
}
