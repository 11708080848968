export function useAllocation() {
  const getAllocationData = async (params, token) => {
    console.log('getAllocationData')
    const resp = await axios({
      url: '/dr',
      method: 'post',
      data: {
        variables: params,
        query: `query AllocationQuery(
          $isinShare: String!
          $asof: Asof
          $domain: Domain!
        ) {
          share(isin_share: $isinShare, asof: $asof) {
            characteristics
            asof
            axis_of_analysis
            slug
            benchmark {
              id
              label
            }
            allocation(domain: $domain) {
              date
              fund_id
              name
              asset_class
              currency
              quantity
              price
              fixing
              valuation
              id
              isin
              asof
              date_d_echeance
              date_d_echeance_with_call
              weight_fund
              weight_benchmark
              weight_diff
              weight_active_share
              axis
              exposure_fund
              contrib_sensi_fund
              contrib_ytm_fund
              contrib_deltaaction_fund
              contrib_spreadobl_fund
              exposure_benchmark
              contrib_sensi_benchmark
              contrib_ytm_benchmark
              contrib_deltaaction_benchmark
              contrib_spreadobl_benchmark
            }
          }
        }`,
      },
    })
    return resp.data.data
  }
  return {
    getAllocationData,
  }
}
