<template>
  <builder-chart v-bind="{ ...props, data: translateData(data), nxChartOptions, chartOptions }">
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </builder-chart>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { bar as api } from '../composables/builderOptions'
import { BuilderProps } from '../composables/builderComponent'
import { bar } from './stories'
import useTranslations from '../composables/translations'

const viz = 'bar'
const props = defineProps<BuilderProps>()
const { translateData } = useTranslations(props)

const nxChartOptions = computed(() => {
  return {
    viz,
    lib: props.options.horizontal ? 'nx' : 'dx',
    legendOptions: { position: 'bottom' },
  }
})
const chartOptions = {
  // legendOptions: {
  //   shape: 'line',
  // },
}
</script>

<script lang="ts">
export default {
  api,
  viz: 'bar',
  styles: {
    'bar-value': `font-weight: bold;`,
    'bar-label': '',
  },
  story: bar,
}
</script>
