<style>
.pdf .hide { display: none!important; }
.pdf-table { display: table;width: 100%; }
.pdf-table .line { display: table-row; }
.pdf-table .cell { display: table-cell; }
.pdf-table .line.header { font-weight: 600; }
.pdf-table .cell { padding: 4px;max-width: 140px; }
.pdf-table .cell > div { display: flex;overflow: hidden;font-size: 10px;text-overflow: ellipsis;white-space: nowrap; }
.pdf-table .cell:empty { background: none!important; }
.pdf-table.number .cell { justify-content: flex-end; }
.pdf-table .cell:first-child > div { font-weight: 600;justify-content: flex-start; }
.block.condensed .pdf-table { display: block;columns: 2;column-gap: 24px; }
.block.condensed .pdf-table .line { display: flex; }
.block.condensed .pdf-table .cell { display: flex;flex: 1;padding: 0;max-width: unset; }
.block.condensed .pdf-table .cell:last-child { justify-content: flex-end; }
.block.lines .pdf-table .cell { font-weight: normal; }
.block.lines .pdf-table .cell:nth-child(even) { font-weight: 600; }


</style>

<template lang="pug">
.pdf-table
  .line(:class="[line[class_index], { header: lnum === 0 && !metadata.noHeader, hide: lnum === 0 && metadata && metadata.hide }]" v-for="(line, lnum) in data_")
    .cell(:class="[data_[0][cnum], 'r' + (lnum + 1), 'c' + (cnum + 1)]" :contenteditable="isContentEditable(cell)" @blur="focus = []" @focus="focus = [lnum, cnum]" v-for="(cell, cnum) in line")
      // for arrow function
      wrapper(:value="display(cell, line, focus[0] === lnum && focus[1] === cnum, cnum, data_[0])" v-if="/=>/.test(cell)")
      // for analytics
      div(v-else-if="!t[cell] && /^(performance|performance_annualized|volatility|tracking_error|information_ratio|sharpe_ratio|max_drawdown_value|beta|alpha|var_X|var_X_Y_days)\./.test(cell) && cell.includes('.')") {{ format(cell.split('.')[0])(analytics(cell)) }}
      // for date
      div(v-else-if="/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(cell)") {{ display_date(cell) }}
      // for userflow data / share data / data
      div(v-else :class="[data_[lnum][0]]" v-html="t[cell] || (format(metadata.format || ($root.config.formats && $root.config.formats[data_[lnum][0]]) || ($root.config.formats && $root.config.formats[data_[0][cnum]])))(cell)")
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    metadata: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      focus: [],
    }
  },
  computed: {
    class_index() {
      return this.data_[0].findIndex('class')
    },
    data_() {
      // This component should only display, but here it get some data
      const data = ($root.userflow.table && $root.userflow.table[this.data]) ||
             ($root.db.table && $root.db.table[this.data]) ||
             ($root.config.table && $root.config.table[this.data]) ||
             this.data || []

      if (data && data.includes(',')) return data.split(',').map(d => [d, $root.share[d] || $root.userflow[d] || d])
      if (typeof data === 'object' && typeof data[0] === 'string' && typeof(data[0]) === 'string') return data.map(d => [d, $root.share[d] || $root.userflow[d] || null]).filter(d => d[1])
      if (typeof data === 'object' && typeof data[0] === 'object' && data[0].length === 2) return data.map(d => [d.first(), d.last() || $root.share[d.first()] || $root.userflow[d.first()] || null]).filter(d => d[1])
      return data
    },
    content_editable() {
      // Respect explicit override via metadata prop : otherwise comply with the 'old' hardcoded value (contenteditable: '')
      return [true, false].includes(this.metadata.contentEditable) ? this.metadata.contentEditable : ''
    }
  },
  methods: {
    display_date(cell){
      try{
        const format = $root.lang.startsWith('en') ? 'YYYY/MM/DD' : 'DD/MM/YYYY'
        return new Date(cell).format(format)
      }
      catch (e) {
        return cell
      }

    },
    isContentEditable(cell) {
      const isURL = /\b(?:http|https):\/\/[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\/[a-zA-Z0-9-._~:\/?#[\]@!$&'()*+,;=]*)?\b/gm.test(cell);
      if (isURL) return false;
      // Respect explicit override via metadata prop : otherwise comply with the 'old' hardcoded value (contenteditable: '')
      return this.metadata.contentEditable ? this.metadata.contentEditable : '';
    },
    display(cell, line, focus, cnum, header) {
      try {
        // Fix malformed parameters
        let metric
        let day
        const value = eval(cell)()
        return format(this.metadata.format || header[cnum])(this.t[value] || value) || (this.t[value] || value)
      } catch (e) {
        return ''
      }
    },
  },
}
</script>
