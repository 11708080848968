import { Ref } from 'vue'
import type { Stylesheet, TemplateStyle, TemplateLayout } from '../builder'
import stylesheetService from '@100-m/hauru/src/services/StylesheetService'

interface StyleAttribute {
  type: 'color' | 'pixel' | 'float' | 'select'
  default: number | string
  // If select mapping value name to css values
  values?: Record<string, any>
}
export const styleAttributes: Record<string, StyleAttribute> = {
  'font-size': {
    type: 'pixel',
    default: 14,
  },
  color: {
    type: 'color',
    default: '#000000',
  },
  'background-color': {
    type: 'color',
    default: '#ffffff',
  },
  padding: {
    type: 'pixel',
    default: 0,
  },
  'line-height': {
    type: 'float',
    default: 1.5,
  },
  'letter-spacing': {
    type: 'select',
    default: 'normal',
    values: {
      tight: '-0.025em',
      normal: '0em',
      wide: '0.025em',
    },
  },
  'font-style': {
    type: 'select',
    default: 'normal',
    values: {
      normal: 'normal',
      italic: 'italic',
      bold: { 'font-weight': 'bold' },
      underline: { 'text-decoration': 'underline' },
    },
  },
}
function parseStyle(styleName: string, styles: Record<string, TemplateStyle>) {
  if (!styleName) return {}
  const style = styles[styleName]
  if (!style) return {}
  return Object.entries(style.attributes).reduce((acc, [k, v]) => {
    const attrType = styleAttributes[k]?.type
    if (attrType === 'pixel') {
      acc[k] = `${v}px`
    } else if (attrType === 'float') {
      acc[k] = parseFloat(v)
    } else if (attrType === 'select') {
      const value = styleAttributes[k].values[v]
      if (typeof value === 'object') {
        Object.entries(value).forEach(([_key, _val]) => {
          acc[_key] = _val
        })
      } else {
        acc[k] = value
      }
    } else {
      acc[k] = v
    }
    return acc
  }, {})
}

export function createStyle(styleName: string, template: any) {
  if (template.theme.styles[styleName]) {
    return alert('Style already exists')
  }
  template.theme.styles[styleName] = { name: styleName, attributes: {} }
}

export function removeStyle(styleName: string, template: any) {
  delete template.theme.styles[styleName]
}

function traverseObject(obj, fnLeaf, fnNode = (v: any, path: string[]) => null, path = [], root = null) {
  if (!obj) return
  if (obj instanceof Object) {
    fnNode(obj, path, root)
    return Object.keys(obj).forEach((k, i) => traverseObject(obj[k], fnLeaf, fnNode, path.concat(k), root || obj))
  }
  return fnLeaf(obj, path, root)
}

// Get stylesheet from loadedStylesheets else fetch it
export async function getTemplateStylesheet(template: Ref<TemplateLayout>, stylesheetName?: string) {
  if (!stylesheetName) {
    return template.value.theme.loadedStylesheets[template.value.theme?.stylesheet]
  }
  if (!template?.value?.theme?.loadedStylesheets[stylesheetName]) {
    const stylesheet = await stylesheetService.get(stylesheetName)
    template.value.theme.loadedStylesheets[stylesheetName] = stylesheet
  }
  const stylesheet = template.value.theme.loadedStylesheets[stylesheetName]
  return stylesheet
}
export async function getTemplateCss(template: Ref<TemplateLayout>) {
  const blockStylesheets: Record<string, string> = {}
  const blockStyleOverrides: Record<string, any> = {}
  // Blocks with custom styles
  let css: string[] = []
  traverseObject(
    template.value,
    (v, path) => {
      if (path[path.length - 1] === 'stylesheet') {
        // Skip global stylesheet
        if (path[0] === 'theme' && path[1] === 'stylesheet') return
        const cssId =
          'block-' +
          path
            .filter(d => d !== 'nodes')
            .slice(0, -1)
            .join('-')
        console.log('??', cssId)
        blockStylesheets[cssId] = v
      }
      // if (path[path.length - 1] === 'styleOverrides') {
      //   const cssId = 'block-' + path.slice(0, -1).join('-')
      //   blockStyleOverrides[cssId] = v
      // }
      // console.log(path, path[path.length - 1])
    },
    (v, path) => {
      if (path[path.length - 1] === 'styleOverrides') {
        const cssId =
          'block-' +
          path
            .filter(d => d !== 'nodes')
            .slice(0, -1)
            .join('-')
        if (Object.keys(v).length === 0) return
        blockStyleOverrides[cssId] = v
      }
    },
  )
  const customStyleIds = [...new Set(Object.keys(blockStylesheets).concat(Object.keys(blockStyleOverrides)))]
  for (const blockId of customStyleIds) {
    // const [blockId, stylesheetName] = entry
    const stylesheet = await getTemplateStylesheet(template, blockStylesheets[blockId])
    if (blockStyleOverrides[blockId]) {
      const styles = {
        ...stylesheet.styles,
        ...blockStyleOverrides[blockId],
      }
      css = css.concat(parseStylesheet({ ...stylesheet, styles }, `#${blockId}`))
    } else {
      css = css.concat(parseStylesheet(stylesheet, `#${blockId}`))
    }
  }
  const globalStylesheet = template?.value?.theme?.stylesheet
  const scopeLimit = customStyleIds.map(k => `#${k}`)
  // @ts-expect-error is never undefined
  const stylesheet = template.value.theme.loadedStylesheets[globalStylesheet]
  css = css.concat(parseStylesheet(stylesheet, '.theme-all', scopeLimit))
  return css
}

function hexToRgb(hex: string) {
  return hex
    .slice(1)
    ?.match(/.{2}/g)
    ?.map(x => parseInt(x, 16))
    .join(',')
}
export function parseStylesheet(stylesheet: Stylesheet, scope = '.theme-all', limit: string[] = []) {
  const scopeLimit = limit.length ? `to (${limit.join(', ')})` : ''
  const styles = Object.entries(stylesheet.styles)
    .filter(([k, v]) => v.css)
    .map(([k, v]) => {
      // Special exception for container, it is at the same level at the block which caries the scope Id
      if (k === 'container' && scope !== '.theme-all') {
        return `${scope} { 
            ${v.css}
        }\n`
      }
      const className = k.startsWith('.') ? k : '.' + k
      return `@scope (${scope}) ${scopeLimit} { 
        ${className} {
          ${v.css}
        }
      }\n`
    })
  const variables = `${scope} {
    --primary: ${stylesheet.primary};
    --secondary: ${stylesheet.secondary};
    --primary-rgb: ${hexToRgb(stylesheet.primary)};
    --secondary-rgb: ${hexToRgb(stylesheet.secondary)};
  }`
  return [variables].concat(styles)
}
