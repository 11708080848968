import axios from 'axios'
export default {
  install(app) {
    window.axios = app.config.globalProperties.$axios = axios
  },
}
export function useAxios() {
  return axios
}

export function useGraphQL({ graphqlEndpoint }) {
  async function sendQuery(query, variables) {
    let result
    if (!localStorage.idToken) return null
    try {
      const response = await axios({
        url: graphqlEndpoint,
        method: 'post',
        data: {
          variables,
          query,
        },
      })
      result = response.data.data
    } catch (error) {
      console.error(error)
      result = null
    }
    return result
  }
  return { sendQuery }
}

export function useDataReport({ graphqlEndpoint }) {
  const getDataReport = async function (reportName, variables) {
    const params = {
      token: $root?.profile?.idToken,
      ...variables,
    }
    const url = `${graphqlEndpoint}/dr/name/${reportName}/run`
    const response = await axios.get(url, { params })
    return response.data.data
  }
  return { getDataReport }
}
