<template>
  <component
    :is="errorComponent"
    :data="error"
    :options="node.options || {}"
    :context="{ data, layout, store, node, ...context }"
    v-if="error"
  ></component>
  <component
    v-else-if="children"
    :style="style"
    :disabled="node.theme || !store.active.length"
    :is="componentTag"
    v-bind="componentProps"
    :class="containerClass"
    item-key="path"
  >
    <template v-for="(element, index) in children" :key="index">
      <!-- <div>{{ element.type || 'none' }}</div> -->
      <builder
        :id="'block-' + getNodePath(index).join('-')"
        :class="[
          element?.data?.format('dash'),
          node.type === 'overlay' && index !== 0 ? 'absolute' : '',
          element?.options?.class,
          isActive(getNodePath(index)) ? 'z-10 rounded-md ring-4 ring-yellow-400' : '',
          childIsActive(getNodePath(index)) ? 'z-10 rounded-md ring-4 ring-yellow-200' : '',
        ]"
        :node="element"
        :data="data"
        :layout="layout"
        :path="element.virtualPath || getNodePath(index)"
        :store="store"
        :context="context"
        :parentNode="node"
        :virtual="virtual"
        @mousedown.stop="onClick(index, element)"
        @active="$emit('active', $event)"
        @end="$emit('end', $event)"
        @overflow="emit('overflow', $event)"
      ></builder>
    </template>
  </component>
  <!-- <div
    :class="[
      isActive(path) ? 'z-10 rounded-md ring-4 ring-yellow-400' : '',
      childIsActive(path, store.active.slice(0, -2)) ? 'z-10 rounded-md ring-4 ring-yellow-200' : '',
    ]"
    v-else
  >
    {{ componentProps.options }}
  </div> -->
  <component
    :style="style"
    :is="componentTag"
    :class="[
      'pdf-block',
      isActive(path) ? 'z-10 rounded-md ring-4 ring-yellow-400' : '',
      childIsActive(path) ? 'z-10 rounded-md ring-4 ring-yellow-200' : '',
    ]"
    v-bind="componentProps"
    @overflow="onOverflow"
    v-else-if="componentProps && !isOverflowing"
  ></component>
</template>

<script setup>
import { ref, computed, onErrorCaptured, onMounted } from 'vue'
import { useComponents } from '../composables/builderComponent'
const { getComponentTag, getComponent } = useComponents()
const props = defineProps(['node', 'data', 'layout', 'store', 'context', 'path', 'parentNode', 'virtual'])
const emit = defineEmits(['overflow', 'active'])
const error = ref(false)

const getNodePath = index => {
  return [...props.path, index]
}

function onClick(index, element) {
  if (element.virtualPath) {
    emit('active', element.virtualPath)
  } else {
    emit('active', getNodePath(index))
  }
}

const isActive = path => {
  return path.join('.') === props.store.active.join('.')
}
const childIsActive = path => {
  return path.join('.') === props.store.active.slice(0, -2).join('.')
}
const containerClass = computed(() => {
  if (props.node.component) return 'pdf-block'
  if (props.node.type === 'row') return 'pdf-line'
  if (props.node.type === 'column') return 'pdf-column'
  if (props.node.type === 'overlay') return 'pdf-overlay'
  return 'pdf-block'
})

const style = computed(() => {
  if (props?.parentNode?.type === 'overlay') {
    const style = { ...props.node?.position }
    if (props.node.width === 'fit') {
      style.width = 'fit-content'
    }
    if (props.node.height) {
      style['max-height'] = props.node.height
      style['min-height'] = props.node.height
    }
    return style
  }
  if (props.node.width === 'fit' || props.node.height === 'fit') {
    return 'flex: 0 1 min-content;'
  }
  if (props.node.width) {
    return `max-width: calc(${props.node.width} - var(--spacing) * 1/2);min-width: calc(${props.node.width} - var(--spacing) * 1/2);`
  }
  if (props.node.height) {
    return `max-height: calc(${props.node.height} - var(--spacing) * 1/2);min-height: calc(${props.node.height} - var(--spacing) * 1/2);`
  }
  return ''
})

const errorComponent = computed(() => {
  if (!props.layout) return 'builder-error'
  // TODO: manage global components
  return getComponentTag('error')
})

const componentTag = computed(() => {
  if (!props.node.component) return 'div'
  return getComponentTag(props.node.component)
})
const componentData = computed(() => {
  if (!props.node.component) return
  console.log('updating component daata')
  const dataPath = (props.node.data || '').split('.')
  const dataValue = props.data[dataPath[0]]?.[dataPath.slice(1).join('.')]
  return dataValue
})

// Warning: we are mutating the props.node.options object in this computed
// Could lead to unexpected behavior
// let _componentProps = null
function getOptions(node, data, api) {
  // TODO: avoid doing JSON parse stringify
  const options = node.options ? JSON.parse(JSON.stringify(node.options)) : {}
  return options
}
const componentProps = ref(null)
function isLastOnPage(path, layout) {
  const page = layout.nodes[path[0]]
  const innerPath = path.slice(1)
  return innerPath.every((index, i) => {
    const node = innerPath.slice(0, i).reduce((acc, v) => acc.nodes[v], page)
    return node.nodes.filter(d => d?.component !== 'footer').length - 1 === index
  })
}
const updateComponentProps = () => {
  // if (_componentProps) return _componentProps
  // if (!props.node.component) return {}
  // const api = components.value[componentTag.value]?.api
  // If the dataPath changes, reset the options
  // if (!props.node?.options || props.node?.options?.dataPath !== props.node.data) {
  //   props.node.options = { dataPath: props.node.data }
  // }
  const data = componentData.value
  const component = getComponent(props.node.component)
  const options = getOptions(props.node, data, component?.api)
  const stylesheet = props.layout?.theme?.loadedStylesheets?.[props.node?.stylesheet || props.layout?.theme?.stylesheet]
  console.log('Redrawing props')
  const isLast = isLastOnPage(props.path, props.layout)
  console.log('isLast', isLast, props.path)
  componentProps.value = {
    data,
    options,
    context: {
      data: props.data,
      layout: props.layout,
      footnotes: props.node.footnotes || [],
      pageFootnotes: props.layout.nodes[props.path[0]]?.footnotes || [],
      globalFootnotes: props.layout.footnotes || [],
      stylesheet,
      node: props.node,
      isLast,
      path: props.path,
      virtual: props.virtual,
      ...props.context,
    },
  }
}

function shouldUpdate(eventPath, path) {
  return eventPath.every((v, i) => v === path[i])
}
onMounted(() => {
  updateComponentProps()
  window.addEventListener('builderUpdate', e => {
    // console.log('Update event', e.detail.path, props.path)
    if (shouldUpdate(e.detail.path, props.path)) {
      console.log('Update this path', props.path)
      isOverflowing.value = false
      updateComponentProps()
    }
    // console.log('There was an update', props.path, e.path)
  })
})
// Step 1 Render once no update
// Step 2 global
// onEvent (ev => {
//   if (ev.path = myPath) {
//     if component updateComponentProps()
//     // if layout updateLayout()
// })
const children = computed(() => {
  return props.node.nodes
})

const isOverflowing = ref(false)
function onOverflow(overflow) {
  console.log('Overflow builder', overflow, props.path)
  isOverflowing.value = overflow
  emit('overflow', { path: props.path, overflow })
}

onErrorCaptured(e => {
  error.value = e
  return false
})
</script>
