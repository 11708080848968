<style>

</style>

<template lang="pug">
.column.between
  brick.risk_disclaimer.narrow(v-if="data.share.characteristics.disclaimer_top")
    pdf-text(:data="t[data.share.characteristics.disclaimer_top] || data.share.characteristics.disclaimer_top")
  brick(title="investment_strategy")
    pdf-text(:data="t[data.share.characteristics.orientation] || data.share.characteristics.orientation")
  brick(title="performance_net").risk_disclaimer.middle
    div(v-html="t.not_enough_perf || 'not_enough_perf'")
  brick.center-value(title="performance_historical").risk_disclaimer.middle
    div(v-html="t.not_enough_perf || 'not_enough_perf'")
  brick(:title="['performance_evolution', data.share.characteristics.start_date_performance.split(' ').join('_')].filter(s => s).join('_')").risk_disclaimer.middle
    div(v-html="t.not_enough_perf || 'not_enough_perf'")
  brick.nomargin(title="performance_indicators").risk_disclaimer.middle
    div(v-html="t.not_enough_perf || 'not_enough_perf'")
</template>

<script>
export const additions = {}
export default {
  props: { 'data': Object }
}
</script>
