<template>
  <lfr-block :data="data" :options="options">
    <div class="flex-col">
      <div v-for="disc in disclaimerList" class="px-2 italic leading-tight tracking-tight text-gray-500 mt-auto" v-html="disc"></div>
    </div>
  </lfr-block>
</template>

<script setup>
import { marked } from 'marked'
import insane from 'insane'
import { computed } from 'vue'
const { data, options } = defineProps(['data', 'options'])
const disclaimerList = computed(() => {
  const list = options.split ? String(data).split(',') : [data]
  return list.map(disc => {
    if (typeof disc !== 'string') return disc
    return insane(marked(options.data.translation[disc] || disc))
  })
})
</script>

<script>
export default {
  api: {
    split: {
      label: 'Split',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
  },
}
</script>
