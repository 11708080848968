<template>
  <div
    class="-m border-primary bg-primary/10 !mb-0 flex h-fit w-[calc(100%+var(--spacing)*2)] flex-col items-center justify-between border-0 border-b-2"
  >
    <div v-if="headerData.error">{{ headerData.error }}</div>
    <div class="flex h-fit w-full flex-row items-center justify-between bg-transparent">
      <div class="flex flex-row items-center justify-between">
        <div class="m-2 flex h-12 w-12">
          <img :src="headerData.logoImg" alt="logo" />
        </div>
        <div class="flex flex-col">
          <div class="flex text-sm font-semibold">{{ headerData.fundName }}</div>
          <div class="flex font-bold">Reporting</div>
        </div>
      </div>
      <div class="flex flex-col items-end justify-between p-2 text-sm">
        <div class="flex">{{ translate(headerData.period) }} - {{ headerData.date }}</div>
        <div class="flex">
          {{ [headerData.shareLetter && 'Share ' + headerData.shareLetter, headerData.isinShare].filter().join(' - ') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps(['data', 'options', 'context'])
// const data = options.context
// debugger
function translate(key) {
  return props.context.translations[key] || key
}
const headerData = computed(() => {
  const fundCharacteristics =
    props.context?.data?.referential?.shareCharacteristics || props.context?.data?.referential?.fundCharacteristics
  try {
    const date = new Intl.DateTimeFormat(props.context.variables.lang.slice(0, 2), {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(new Date(props.context.variables.endDate))
    const logoNames = ['logo-header', 'logo']
    const findAssetByName = (names, assets) => {
      if (!assets) return null
      return names.reduce((acc, name) => {
        if (acc) return acc
        return assets.find(el => el.name.includes(name))
      }, null)
    }
    const logoImg = findAssetByName(logoNames, props.context?.layout?.theme.assets)?.src || '/logo-header.png'
    return {
      fundName: props.context.variables.fund.titleize(),
      period: props.context.variables.period,
      isinShare: props.context.variables.shareId,
      shareLetter: fundCharacteristics?.shareLetter,
      date,
      logoImg,
    }
  } catch (e) {
    return {
      error: e.message || e,
    }
  }
})
</script>
