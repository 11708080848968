<template>
  <div class="legend"></div>
  <div class="h-fit w-full flex-col divide-y self-center">
    <div class="flex h-fit w-full items-center space-x-3 py-1" :class="item.name?.format('dash')" v-for="(item, index) in data.slice(0, options?.limit ?? 10)">
      <div class="w-1/3">{{ item.name }}</div>
      <div class="w-1/2">
        <div class="bg-primary h-2 rounded-sm" :style="{ width: size(item.value) + '%' }"></div>
      </div>
      <div class="w-1/6 text-right">
        {{ format(item.value, options) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import format from '../../format.js'
const { data, options } = defineProps(['data', 'options'])
const max = data.map(v => v.value).max()
const size = val => Math.min(Math.round((val / max) * 100), 100)
</script>
