import { computed, ref } from 'vue'

export function useXfilter() {
  const _dimensions = ref()
  const _dataset = ref()

  const initXfilter = (dataset, dimensions) => {
    // allowedDimensions: Arr with dimensions expected in autocomplete (what you want filter)
    // dataSet: DataSet with following format: [obj, obj, obj]
    _dimensions.value = dimensions
    _dataset.value = dataset
  }

  const metric = computed(() => $root.query.metric?.includes('benchmark') ? 'benchmark' : $root.query.metric ? $root.query.metric : 'fund')
  const axis = computed(() => $root.query.axis || 'weight')
  const axisFormat = computed(() => ({ contrib_sensi: '.2f' })[axis.value] || '.2%')
  const axisMetric = computed(() => `${axis.value}_${metric.value}`)

  const filteredDataset = computed(() => {
    const filters = $root.$route.query
    if (!filters) return _dataset.value
    let result = _dataset.value
    Object.keys(filters)
      .filter(d => _dimensions.value.includes(d)) // Prevent unwanted filter from dirty query
      .forEach(dimension => {
        const arrCtriteria = filters[dimension].split('|')
        result = result.filter(d => arrCtriteria.includes('' + d.axis[dimension]) || arrCtriteria.includes('' + d[dimension]) || arrCtriteria.includes(d.axis[dimension].split('/')[2]) || arrCtriteria.includes('NA') && d.axis[dimension] === 'NA') // force null to str
      })
    return result
  })

  const datasetByDimensions = dim => {
    const _goupingDimensions = _dimensions.value.filter(d => d !== dim)
    const allFilters = $root.$route.query.filter((d, k) => _goupingDimensions.includes(k))
    // const allFilters = this.$route.query.filter((d, k) => this.availableDimensions.includes(k))
    let filtereDataset = _dataset.value
    Object.keys(allFilters).forEach(dimension => {
      const arrCriteria = allFilters[dimension].split('|')
      if (dimension.startsWith('date_')) {
        filtereDataset = filtereDataset
          .filter(d => arrCriteria.includes(d.axis[dimension].split('/')[2]) || (arrCriteria.includes('NA') && d.axis[dimension] === 'NA') || arrCriteria.includes('' + d.axis[dimension]))
      } else {
        filtereDataset = filtereDataset.filter(d => arrCriteria.includes('' + d.axis[dimension]))
      }
    })
    const groupedAllocation = filtereDataset
      .group(d=>dim.startsWith('date_') && d.axis[dim] !== 'NA' ? d.axis[dim].split('/')[2] || d.axis[dim] : d.axis[dim])
      .map(group => group.map(el=>metric.value==="diff" ? (el[axisMetric.value] || el[`${axis.value}_fund`] - el[`${axis.value}_benchmark`] ) : el[axisMetric.value])
      .sum())
    return groupedAllocation
  }

  return {
    initXfilter,
    filteredDataset, // old xf.data
    datasetByDimensions,
    metric,
    axis,
    axisFormat,
    axisMetric,
  }
}
