<style>

</style>

<template lang="pug">
screen-title
.row.stretch(v-if="post && $root.domain.length")
  kpi.performance(:data="[{ title: 'performance', value: post.total.fund }, { title: 'performance_benchmark', value: post.total.benchmark }]")
  kpi(:data="[{ title: t['top_contributors']}].concat(contributors_kpi.filter(positive_kpi).map(d => [d[0], format('+.2%')(d[1])]).reverse().map(kpi_rewrite))")
  kpi(:data="[{ title: t['worst_contributors']}].concat(contributors_kpi.filter(negative_kpi).map(d => [d[0], format('+.2%')(d[1])]).map(kpi_rewrite))")
.block.column.expand
  .row.expand(v-for="group in dimensions")
    block.allow-fullscreen(v-if="contribution" :title="'contribution,by,' + dimension" type="plot-bar" :data="contribution[dimension]" :metadata="metadata[dimension]" v-for="dimension in group")
</template>

<script>
export const additions = {"icon":"ic_search","datasets":["contribution","performance","mapping"]}
export default {
  computed: {
    contribution() {
      if (!$root.xf || !$root.xf.contribution) return
      return $root.xf.contribution.map(dim => dim.map((v, k) => v && v['contribution_' + ($root.query.metric || 'fund')]).filter())
    },
    post() {
      if (!$root || !$root.x) return
      const { filters, domain, screen, config, query } = $root
      const metric = $root.query.metric || 'fund'
      console.log('root.x', $root.x)
      const vl0 = $root.x.performance[$root.x.performance.dates[domain[0]] || 0]
      const total = {
        fund: $root.xf.contribution && $root.xf.contribution.data.sum(d => +d.pnl_share_fund) / vl0.fund || 0,
        benchmark: $root.xf.contribution && $root.xf.contribution.data.sum(d => +d.pnl_share_benchmark) / vl0.benchmark || 0,
      }
      if (metric === 'diff') {
        var data = $root.xf.contribution.map((obj, dim) => (dim === 'data' ? obj : obj.map((v, k) => v && v.pnl_share_fund / +vl0.fund - v.pnl_share_benchmark / +vl0.benchmark)))
        return { total, data }
      }
      var data = $root.xf.contribution && $root.xf.contribution.map((obj, dim) => (dim === 'data' ? obj : obj.map((v, k) => v / +vl0[metric]))) || []
      return { total, data }
    },
    contributors_kpi() {
      if (!$root.xf.performance) return []
      const vl0 = $root.xf.performance.data[0].fund
      return $root.xf.contribution && $root.xf.contribution.data
        .filter(d => (d.id || d.isin) !== 'UNEXPLAINED')
        .filter(d => d.asset_class !== 'fees')
        .filter(d => !d.flag_ignore_contribution)
        .group(this.id_isin)
        .reduce((acc, data, isin) => {
          acc.push([mapping[isin] ? mapping[isin].name : isin, data.sum('pnl_share_fund') / vl0])
          return acc
        }, [])
        .filter(d => d[1])
        .sort(d => d[1]) || []
    },
  },
}
</script>
