import { computed } from 'vue'

import { ResolvedBuilderOptions } from './builderOptions'
// TODO: have to put this override here else type checks fail
interface BuilderBlockProps {
  data?: any[] | Record<string, any[]>
  options: ResolvedBuilderOptions
  context: Record<string, any>
}
function parseFootnotes(footnotes: any[], translations: Record<string, string>) {
  if (!footnotes || !footnotes.filter(d => d.key).length) return {}
  // return null
  return footnotes?.reduce((acc, footnote, index) => {
    const value = footnote.value.replace(/^translation\./, '')
    acc[footnote.key.replace(/^translation\./, '')] = {
      value: translations[value] || value,
      index: index + 1,
      scope: footnote.scope,
    }
    return acc
  }, {})
}
export default function useTranslations(props: BuilderBlockProps) {
  // const allFootnotes = computed(() => {
  //   return parseFootnotes(
  //     [
  //       ...(props.context?.globalFootnotes || []).map(d => ({ ...d, scope: 'global' })),
  //       ...(props.context?.pageFootnotes || []).map(d => ({ ...d, scope: 'page' })),
  //       ...(props.context?.footnotes || []).map(d => ({ ...d, scope: 'block' })),
  //     ],
  //     props.context.translations,
  //   )
  // })
  const allFootnotes = computed(() => {
    if (props.context?.globalFootnotes && props.context?.globalFootnotes.length) {
      return parseFootnotes(
        props.context.globalFootnotes.map(d => ({ ...d, scope: 'global' })),
        props.context.translations,
      )
    }
    if (props.context?.pageFootnotes && props.context?.pageFootnotes.length) {
      return parseFootnotes(
        props.context.pageFootnotes.map(d => ({ ...d, scope: 'page' })),
        props.context.translations,
      )
    }
    if (props.context?.footnotes && props.context?.footnotes.length) {
      return parseFootnotes(
        props.context.footnotes.map(d => ({ ...d, scope: 'block' })),
        props.context.translations,
      )
    }
    return {}
  })
  const blockFootnotes = computed(() => {
    return Object.values(allFootnotes.value).filter(d => d.scope === 'block')
  })
  const pageFootnotes = computed(() => {
    return Object.values(allFootnotes.value).filter(d => d.scope === 'page')
  })
  const globalFootnotes = computed(() => {
    return Object.values(allFootnotes.value).filter(d => d.scope === 'global')
  })

  const translate = computed(() => {
    return (value?: string | number) => {
      if (!value) return
      if (typeof value === 'number') return value
      // if (!value.startsWith('translation.')) return value
      const footnotes = allFootnotes.value
      const key = value.replace(/^translation\./, '')
      const translatedKey = props.context.translations[key] || key
      if (footnotes && footnotes[key]) {
        return `<span>${translatedKey}</span><sup>${footnotes[key].index}</sup>`
      }
      return translatedKey
    }
  })

  const translateData = computed(() => {
    return (data: any) => {
      if (!Array.isArray(data)) return data
      if (Array.isArray(data[0])) {
        return data.map(row => {
          return row.map(cell => {
            return typeof cell === 'string' ? translate.value(cell) : cell
          })
        })
      }
      const stringValues = Object.keys(data[0]).filter(key => {
        return typeof data[0][key] === 'string'
      })
      return data.map(row => {
        return Object.fromEntries(
          Object.entries(row).map(([k, v]) => {
            const value = stringValues.includes(k) ? translate.value(v) : v
            return [k, value]
          }),
        )
      })
    }
  })

  return {
    translate,
    translateData,
    blockFootnotes,
    pageFootnotes,
    globalFootnotes,
  }
}
