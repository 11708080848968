import { onMounted, onUnmounted, ref } from 'vue'
import { commandrSDK } from '../../../features/commandr'
const workflows = ref([])
const initialized = ref(false)
export function useWorkflows() {
  const loaded = ref(false)
  const onUpdate = snap => {
    console.log((snap || {}).v())
    workflows.value = (snap || {}).v()
    loaded.value = true
    initialized.value = true
  }
  onMounted(() => {
    loaded.value = false
    commandrSDK.on('/data/workflows', 'value', onUpdate)
  })
  onUnmounted(() => commandrSDK.off('/data/workflows', 'value'))
  return {
    workflows,
    loaded,
    initialized,
  }
}
