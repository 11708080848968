import { splitFilenameToPathAndFilename } from '../utils/splitFilenameToPathAndFilename.js'
import { urlJoin } from '../../utils.js'

export default class DockerFileSystem {
  #dockerURL
  #token

  constructor(config) {
    this.#dockerURL = config.endpoint
    this.#token = config.token
  }

  async upload({ buffer, filenameWithPath, isPrivateFile }) {
    const { filename, bucketPath } = splitFilenameToPathAndFilename(
      filenameWithPath,
      isPrivateFile
    )

    let formattedBuffer = buffer

    if (ArrayBuffer.isView(buffer)) {
      formattedBuffer = { type: 'Buffer', data: Array.from(buffer) }
    }

    const body = JSON.stringify({
      buffer: formattedBuffer,
      bucketPath,
      filename
    })

    const res = await fetch(urlJoin(this.#dockerURL, `filesystem`), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.#token
      },
      body
    })

    if (res.status !== 200) {
      console.log({ resStatus: res.status, resMessage: res.statusText })
    }

    return res.status === 200
  }

  async get({ filenameWithPath }) {
    return (
      await fetch(urlJoin(this.#dockerURL, '/filesystem/', filenameWithPath), {
        method: 'GET',
        headers: {
          authorization: this.#token
        }
      })
    ).blob()
  }

  getPublicUrl({ filenameWithPath }) {
    return urlJoin(this.#dockerURL, '/filesystem/public/', filenameWithPath)
  }

  async deleteFile({ filenameWithPath, isPrivateFile }) {
    const { filename, bucketPath } = splitFilenameToPathAndFilename(
      filenameWithPath,
      isPrivateFile
    )
    const res = await fetch(urlJoin(this.#dockerURL, '/filesystem'), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.#token
      },
      body: JSON.stringify({ filename, bucketPath })
    })

    if (res.status !== 200) {
      console.log({ resStatus: res.status, resMessage: res.statusText })
    }

    return res.status === 200
  }

  async deleteFolder({ path }) {
    const res = await fetch(urlJoin(this.#dockerURL, '/filesystem'), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.#token
      },
      body: JSON.stringify({ path })
    })

    if (res.status !== 200) {
      console.log({ resStatus: res.status, resMessage: res.statusText })
    }

    return res.status === 200
  }
}
