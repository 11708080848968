<style>
.modal { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.7); display: table; transition: opacity 0.3s ease;}
.modal-wrapper { display: table-cell; vertical-align: middle;}
.modal-container { background: #fff; width: 450px; border-radius: 5px; box-shadow: 0 2px 8px rgba(0, 0, 0, .33);transition: all 0.3s ease; margin: 0 auto; padding: 20px 30px;}
.modal-footer {display: flex; justify-content: flex-end; margin-top: 15px;}
.modal-enter, .modal-leave { opacity: 0;}
.modal-enter .modal-container, .modal-leave .modal-container { -webkit-transform: scale(1.1);transform: scale(1.1);}
</style>

<template lang="pug">
.modal(v-show="show")
  .modal-wrapper
    .modal-container
      .content
        .modal-header
          h3
            slot(name="header")
      .modal-body
        slot(name="content")
      .modal-footer
        slot(name="action")
</template>

<script>
export const additions = {"props":["show"]}
export default {
  props:["show"]
}
</script>
