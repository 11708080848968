<style>
.bar-chart .row { align-items: center; }
.bar-chart .key {display: -webkit-box;-webkit-box-orient: vertical;overflow: hidden;text-align: right; flex: none}
.bar-chart .column { position: relative; flex: 1;padding: 2px 5em 2px 1em; }
.bar-chart .rect { position: relative; width: var(--value);height: calc(var(--size) * 1em);line-height: calc(var(--size) * 1em);margin: 1px 0 1px var(--offset);background: var(--colors-primary-default);border-radius: 2px; }
.bar-chart .value { position: absolute; transform: none }
.bar-chart .column .value:nth-child(1) { right:-4px;transform: translate(100%); }
.bar-chart .column .value:nth-child(2).multi { right: 32%; }
.bar-chart .column .value:nth-child(2).single { right: 15%; }
.bar-chart .column .value:nth-child(3) { right: 10%; color: var(--colors-secondary); }
.bar-chart .column .value.multi:nth-child(3) { right: 9%; color: var(--colors-secondary); }
</style>

<template lang="pug">
.bar-chart(:style="{ '--size': (data.v().length > 8 ? .5 : 1) * (data.v().find(v => typeof v !== 'number' && v.keys().length > 1) ? 0.5 : 1) * 2  }")
  .row.legend
    .div(:class="legend" v-for="legend in (data.v().filter(d => d.keys().length >=2).v().first() || {}).keys()" v-if="typeof data.v().first() !== 'number' && data.v().filter(d => d.keys().length >=2).v().length > 1")
      .color
      .label {{ t[legend] || legend }}
  .row(v-for="v, k in data.filter(d => ( (typeof d === 'number' && d) || (options.display_0 ? d.v().unique() : d.v().unique().filter(x => x!=0).length))).filter()")
    .key(:class="k" :style="{'width': `${key_width}px`}" ) {{ t[k] || k }}
    .column(v-if="!options.direction || options.direction==='column'")
      .rect(:class="k" :style="{ '--value': Math.abs(60 * v / diff) + '%', '--offset': Math.abs(60 * (Math.min(0, v) - min) / diff) + '%' }" v-if="typeof v === 'number'")
        .value {{ format(format_)(v) }}
      .rect(:class="k" :style="{ '--value': Math.abs(60 * v / diff) + '%', '--offset': Math.abs(60 * (Math.min(0, v) - min) / diff) + '%' }" v-for="v, k in v" v-else)
        .value {{ format(format_)(v) }}
    .column(v-else)
        .rect(:class="k" :style="{ '--value': Math.abs(60 * v / diff) + '%', '--offset': Math.abs(60 * (Math.min(0, v) - min) / diff) + '%' }" v-if="typeof v === 'number'")
          .value {{ format(format_)(v) }}
        .rect.row(:class="k" :style="{ '--value': Math.abs(60 * v.v()[0] / diff) + '%', '--offset': Math.abs(60 * (Math.min(0, v.v()[0]) - min) / diff) + '%' }" v-else)
        .value(v-for="v, k, in v" :class="`${num_of_val === 1 ? 'single' : 'multi'}`") {{ format(options && options.format && (options.format[k] || options.format) || '.2%')(v) }}
</template>

<script>
export const additions = {"props":["data","options"]}
export default {
  data() {
    return { min: null, max: null, diff: null, key_width: null }
  },
  computed: {
    format_(){
      if (this.options && this.options.format && typeof this.options.format !== 'object') return this.options.format // format: .2%
      if (this.options && this.options.format) return this.options.format.v().first() // format: {fund: .2% }
      return '.2%'
    },
  },
  created() {
    this.$watch('data', data => {
      const traverse = x => ({
        Object: x => x.v().map(traverse),
      }[Object.prototype.toString.call(x).slice(8, -1)] || (x => x))(x)
      if (this.options.direction === 'row'){
        //min max computed only on displayed data
        var list = traverse(this.data.map(d => d[Object.keys(d)[0]]).filter()).flat(Infinity).filter()
      } else {
        var list = traverse(this.data.filter()).flat(Infinity).filter()
      }
      if (Object.keys(data).length === 0) return
      this.num_of_val = Object.keys(data[Object.keys(data)[0]]).length
      var max_label_size = Object.keys(this.data).map(d => $root.t[d] || d).sort(d => d.length)[Object.keys(this.data).length-1].length
      this.key_width = Math.min(max_label_size*5.5,100)
      this.min = Math.min(0, list.min())
      this.max = list.max()
      // For data range entirely in negatives, find diff relative to absolute min and divide by 2 to account for a half axis offset,
      // otherwise calculate difference in real terms
      this.diff = (this.min < 0 && this.max < 0) ? (this.max - Math.abs(this.min)) / 2 : this.max - this.min
    }, { immediate: true })
  }
}
</script>
