<template>
  <builder-chart class="graph-table" v-bind="{ ...props, nxChartOptions }">
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </builder-chart>
</template>

<style scoped></style>

<script setup lang="ts">
import { table as api } from '../composables/builderOptions'
import { bar as story } from './stories'
import { BuilderProps } from '../composables/builderComponent'
const viz = 'table'

const props = withDefaults(defineProps<BuilderProps>(), {})

const nxChartOptions = { viz, lib: 'nx' }
</script>

<script lang="ts">
export default {
  api,
  styles: {
    '.graph-table .table-header': `font-weight: bold;
.table-header-cell:first-of-type {
  text-align: left;
}`,
    '.graph-table .table-row': `.table-cell:first-of-type {
  text-align: left;
}`,
    '.graph-table .table-cell': `padding: 4px 8px;
text-align: center;`,
    '.graph-table .table-header-cell': `padding: 4px 8px;
text-align: center;`,
    '.graph-table .all-table': {
      name: 'Table',
      css: `
.table-row:nth-child(even) {
  background-color: color-mix(in srgb, var(--primary) 10%, transparent);
}`,
    },
  },
  story,
}
</script>
