<style>

</style>

<template lang="pug">
div(v-if="render") {{ this.value }}
</template>

<script>
import { h } from 'vue'

export default {
  props:['value'],
  computed:{
    render () {
      return this.value && typeof this.value !== 'function'
    }
  },

}
</script>
