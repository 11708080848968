<template>
  <section class="flex flex-col gap-4 px-8" v-if="template?.theme">
    <div class="flex flex-col">
      <div class="flex items-center gap-4">
        <div class="w-40 text-sm">Stylesheet</div>
        <select
          class="w-82 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
          v-model="template.theme.stylesheet"
        >
          <option v-for="stylesheet in stylesheets" :key="stylesheet" :value="stylesheet">
            {{ stylesheet }}
          </option>
        </select>
      </div>
      <a
        class="flex cursor-pointer flex-row items-center justify-end gap-1 hover:underline"
        target="_blank"
        :href="`/builder/stylesheet?stylesheetName=${template.theme.stylesheet}`"
      >
        <span>Edit stylesheet</span>
        <div style="--un-icon: url(https://api.iconify.design/heroicons:arrow-top-right-on-square-16-solid.svg)"></div>
      </a>
    </div>
    <div class="flex items-center gap-4">
      <div class="w-40 text-sm">Page Size</div>
      <div class="flex w-48 gap-4">
        <button
          class="flex-1 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
          :class="template.theme.page_size === 'A4' ? '!bg-[#00d9b8]/75' : ''"
          @click="template.theme.page_size = 'A4'"
        >
          A4
        </button>
        <button
          class="flex-1 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
          :class="template.theme.page_size === 'letter' ? '!bg-[#00d9b8]/75' : ''"
          @click="template.theme.page_size = 'letter'"
        >
          Letter
        </button>
      </div>
    </div>
    <div class="flex items-center gap-4">
      <div class="w-40 text-sm">Page Orientation</div>
      <div class="flex w-48 gap-4">
        <button
          class="flex flex-1 justify-center rounded bg-neutral-600 px-3 py-1 hover:bg-neutral-400"
          :class="template.theme.page_orientation === 'portrait' ? '!bg-[#00d9b8]/75' : ''"
          @click="template.theme.page_orientation = 'portrait'"
        >
          <div class="text-xl" style="--un-icon: url(https://api.iconify.design/icon-park-outline:rectangle.svg)"></div>
        </button>
        <button
          class="flex flex-1 justify-center rounded bg-neutral-600 px-3 py-1 hover:bg-neutral-400"
          :class="template.theme.page_orientation === 'landscape' ? '!bg-[#00d9b8]/75' : ''"
          @click="template.theme.page_orientation = 'landscape'"
        >
          <div
            class="text-xl"
            style="--un-icon: url(https://api.iconify.design/icon-park-outline:rectangle-one.svg)"
          ></div>
        </button>
      </div>
    </div>
    <div class="flex items-center gap-4">
      <div class="flex w-40 justify-between text-sm">
        <span>Spacing</span>
        <pre class="text-neutral-400">{{ template.theme.spacing * 4 }}px</pre>
      </div>
      <input
        class="w-48 border-transparent bg-transparent"
        type="range"
        min="0"
        max="12"
        step="0.5"
        v-model="template.theme.spacing"
      />
    </div>
    <!-- <div class="flex items-center gap-4">
      <div class="flex w-40 justify-between text-sm">
        <span>Title style</span>
      </div>
      <builderui-style-input
        :template="template"
        v-model="template.theme.title"
        @add-style="() => (activeStyle = { name: '' })"
        @edit-style="style => (activeStyle = style)"
      />
    </div> -->

    <!-- <label class="flex cursor-pointer items-center">
      <div class="flex w-40 justify-between text-sm">Primary color</div>
      <input
        class="!h-7 !w-7 border-transparent bg-transparent"
        type="color"
        :value="template.theme.primary"
        @input="window.requestAnimationFrame(() => (template.theme.primary = $event.target.value))"
      />
    </label>
    <label class="flex cursor-pointer items-center">
      <div class="flex w-40 justify-between text-sm">Secondary color</div>
      <input
        class="!h-7 !w-7 border-transparent bg-transparent"
        type="color"
        :value="template.theme.secondary"
        @input="window.requestAnimationFrame(() => (template.theme.secondary = $event.target.value))"
      />
    </label> -->
    <label
      class="flex cursor-pointer items-center gap-4 text-sm"
      v-for="(asset, i) in template.theme?.assets"
      :key="asset.name"
    >
      <div class="flex w-40 justify-between">
        <input class="hidden" type="file" accept="image/*" @change="uploadImage($event, i)" />
        <span>{{ asset?.name + ' (' + (i + 1) + ')' || 'Image ' + (i + 1) }}</span>
        <div class="text-neutral-400">{{ asset.size }}</div>
      </div>
      <div class="flex w-48 items-center">
        <img :src="asset.src" class="h-7 max-w-[120px] rounded object-contain" />
        <button
          class="ml-auto flex flex-col items-center rounded bg-transparent p-2 text-black hover:bg-neutral-700"
          @click="removeAsset(i)"
        >
          <div
            class="text-2xl text-neutral-400"
            style="--un-icon: url(https://api.iconify.design/heroicons-outline:trash.svg)"
          ></div>
        </button>
      </div>
    </label>
    <div class="flex items-center gap-4 pt-2">
      <label
        class="flex cursor-pointer justify-center gap-1 rounded bg-neutral-600 px-3 py-1 text-sm hover:bg-neutral-400"
      >
        <div
          class="text-xl"
          style="--un-icon: url(https://api.iconify.design/material-symbols:add-box.svg?color=%236f7675)"
        ></div>
        <div class="text-sm">Add image</div>
        <input class="hidden" type="file" accept="image/*" @change="uploadImage" multiple />
      </label>
    </div>
  </section>
  <section class="!mt-auto flex flex-col border-t border-t-gray-500 p-8 text-xs">
    <div class="text-lg font-bold">CSS</div>
    <textarea
      class="h-28 w-full resize-none rounded-lg bg-black p-2 text-sm text-white"
      v-model="template.theme.globalCss"
    ></textarea>
  </section>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue'
import type { TemplateLayout, Store } from '../../builder'
import { uploadAsset, getFileName } from '../../lib/assetManagement'
import useLayout from '../../composables/layout'
interface IProps {
  stylesheets: string[]
  store: Store
}
const props = defineProps<IProps>()
const template = defineModel<TemplateLayout>()
// @ts-expect-error template is never undefined
const { updateLayout } = useLayout(props.store, template)
function updateAllPages() {
  if (!template.value) return
  template.value.nodes.forEach((node, i) => {
    updateLayout([i])
  })
}
watch(() => template.value?.theme?.stylesheet, updateAllPages)
function removeAsset(assetIndex: number) {
  template.value?.theme?.assets?.splice(assetIndex, 1)
}
// This has to stay here because it modifies the template ref
const uploadImage = (ev, i) => {
  ev.target.files.forEach(async file => {
    if (!template.value.theme.assets) template.value.theme.assets = []
    if (i === undefined) i = template.value.theme.assets.length

    const reader = new FileReader()

    reader.onload = async e => {
      const arrayBuffer = e.target.result
      // debugger
      uploadAsset(arrayBuffer, template.value?.name, template.value?.id, file).then(asset => {
        template.value.theme.assets[i] = asset
      })
    }

    reader.readAsArrayBuffer(file)
  })
}
</script>
