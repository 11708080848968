import { GraphQlService } from './GraphQlService'

// Convert number strings into actual number
function parseStylesheet(stylesheet) {
  return {
    ...stylesheet,
    defaultPalette: stylesheet.defaultPalette && parseInt(stylesheet.defaultPalette),
  }
}
export class StylesheetService extends GraphQlService {
  async list() {
    return (
      await this.query(`
      query Stylesheet {
        builder {
          stylesheets {
            name
          }
        }
      }`)
    ).data.builder.stylesheets.map(stylesheet => stylesheet.name)
  }

  async listWithStylesheetContext() {
    return (
      await this.query(`
      query Query {
      builder {
        stylesheets {
          name
          createdAt
          readOnly
          usage
        }
      }
    }
      `)
    ).data.builder.stylesheets
  }

  async get(name) {
    const stylesheet = (
      await this.query(
        `
      query Stylesheet($name: String!, $limit: Float) {
        builder {
          stylesheet(name: $name, limit: $limit) {
            id
            createdAt
            styles
            primary
            secondary
            palettes
            defaultPalette
            name
            user
            readOnly
          }
        }
      }`,
        { name, limit: 1 },
      )
    ).data.builder.stylesheet[0]
    return parseStylesheet(stylesheet)
  }

  async all(name, limit) {
    const stylesheets = (
      await this.query(
        `
      query StylesheetVersions($name: String!, $limit: Float) {
        builder {
          stylesheet(name: $name, limit: $limit) {
            id
            createdAt
            styles
            primary
            secondary
            palettes
            defaultPalette
            name
            user
            readOnly
          }
        }
      }`,
        { name, limit: limit || 50 },
      )
    ).data.builder.stylesheet
    return stylesheets.map(parseStylesheet)
  }

  async create({ name, styles, primary, secondary, palettes, defaultPalette }) {
    return (
      await this.query(
        `
      mutation AddBuilderStylesheet($name: String!, $styles: JSON!, $primary: String!, $secondary: String!, $palettes: JSON, $defaultPalette: Int) {
        addBuilderStylesheet(name: $name, styles: $styles, primary: $primary, secondary: $secondary, palettes: $palettes, defaultPalette: $defaultPalette) {
          id
          createdAt
          styles
          primary
          secondary
          palettes
          defaultPalette
          name
          user
          readOnly
        }
      }`,
        { name, styles, primary, secondary, palettes, defaultPalette },
      )
    ).data.addBuilderStylesheet
  }

  async remove(id) {
    return await this.query(
      `
      mutation RemoveBuilderStylesheet($id: Int!) {
        removeBuilderStylesheet(id: $id)
      }`,
      { id },
    )
  }

  async removeAll(name) {
    return await this.query(
      `
      mutation RemoveBuilderStylesheetsByName($name: String!) {
        removeBuilderStylesheetsByName(name: $name)
      }`,
      { name },
    )
  }
}

const stylesheetService = new StylesheetService()
export default stylesheetService
