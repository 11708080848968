<template>
  <div class="builder-footer border-primary flex h-fit flex-col justify-between !border-0 !border-t-2 bg-white">
    <div class="flex h-fit w-full flex-row items-center justify-between bg-transparent px-6 py-2">
      <div class="items start flex flex-col justify-between space-y-1">
        <div v-for="v in pageFootnotes" class="flex flex-row text-[7px]" :key="k">
          <sup>{{ v.index }}</sup>
          <span class="leading-[11.2px]" v-html="v.value"></span>
        </div>
      </div>
      <!-- <img class="ml-2 mr-2 h-12 p-1" :src="footerImg" alt="Logo LFR" /> -->
      <div class="text-primary text-xs font-medium">{{ pageNumber }}/{{ pageCount }}</div>
    </div>
  </div>
</template>

<style>
.builder-footer {
  margin-top: auto !important;
  margin-bottom: calc(var(--spacing) * -1) !important;
}
</style>

<script setup>
import usePagination from '../composables/pagination'
import useTranslations from '../composables/translations'

const props = defineProps(['data', 'options', 'context'])
const { pageFootnotes } = useTranslations(props, 'page')

// const disclaimer = computed(() => {
//   const disc = data['nxpack-fund-ref'].disclaimer_footer || data.translation.disclaimer_footer
//     if (typeof disc !== 'string') return disc
//     return insane(marked(disc))
// })

const { pageCount, pageNumber } = usePagination(props)
// onMounted(() => {
//   initPagination()
// })
// const footerImg = computed(() => options.layout.theme?.assets?.find(el=>el.name==='logo-footer.png')?.src || '/logo-footer.png' )
</script>
