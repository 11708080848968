<style scoped>
.board .line .cell:first-child { align-items: flex-start;text-align: left; }
.block .download { max-width: 200px; }
.block.column { min-height: calc(100vh - 300px); }
.legend { display: none; }
</style>

<template lang="pug">
screen-title
.row.stretch
  kpi.performance(v-if="kpis" :data="[{ title: 'excess_return', value: kpis.performance.excess_return }, { title: 'performance', value: kpis.performance.fund }, { title: 'performance_benchmark', value: kpis.performance.benchmark }]")
  kpi(v-if="kpis" :data="kpis.attribution.allocation_effect.map((v,k) => ({ title: (t[k] || k) + ' ' + t.allocation_effect, value: format('allocation_effect')(v)})).v()")
  kpi(v-if="kpis" :data="kpis.attribution.selection_effect.map((v,k) => ({ title: (t[k] || k) + ' ' + t.selection_effect, value: format('selection_effect')(v)})).v()")
.block.column.expand
  .row.expand(v-for="group in dimensions")
    block.allow-fullscreen(type="plot-bar" :data="post_data(dimension)" :metadata="{ dimension: dimension, max: 8 }" :title="'attribution,by,' + dimension" @plotclick="plotclikFilterDimension(dimension)($event)" v-for="dimension in group")
h1 Attribution details
.block.column.expand
  .row
    select(v-if="$root.xf.attribution" :value="$root.query.attribdim || $root.xf.attribution.keys().filter(d => d !== 'data').slice(1).first()" @change="update_query({ attribdim: $event.target.value })")
      option(:value="dim" v-for="dim in $root.xf.attribution.keys().filter(d => d !== 'data')") {{ t[dim] || dim }}
    button.download(@click="download_attrib")
      span {{ t.export_table }}
  board(:data="attribution_table" :metadata="{ sort: ['-order'], columns: ['dimension', 'average_weight_fund', 'fund_contribution', 'average_weight_benchmark', 'benchmark_contribution', 'selection_effect', 'allocation_effect', 'excess_return'] }")
</template>

<script>
export const additions = {"icon":"ic_bar_chart","datasets":["attribution","contribution","performance","mapping"]}
export default {
  computed: {
    attribution_table(){
      if(!$root.xf.attribution || !$root.xf.contribution) return []
      const dim = $root.query.attribdim|| $root.xf.attribution.keys().filter(d => d !== 'data').slice(1).first()
      const attrib = this.$root.xf.attribution[dim]
          .filter()
          .map((d,k) => ({
              order: k === 'NA' ? -1000 : (d.selection_effect + d.allocation_effect),
              dimension: this.t[k] || k,
              'average_weight_fund': d.average_weight_fund,
              'fund_contribution': $root.xf.contribution[dim][k].contribution_fund,
              'average_weight_benchmark': d.average_weight_benchmark,
              'benchmark_contribution': $root.xf.contribution[dim][k].contribution_benchmark,
              'selection_effect': d.selection_effect,
              'allocation_effect': d.allocation_effect,
              'excess_return': (d.selection_effect + d.allocation_effect),
            }))
          .v()
      const total = {
        order: 1000,
        dimension: 'TOTAL',
        'average_weight_fund': 1,
        'fund_contribution': attrib.sum(d => d.fund_contribution),
        'average_weight_benchmark': 1,
        'benchmark_contribution': attrib.sum(d => d.benchmark_contribution),
        'selection_effect': attrib.sum(d => d.selection_effect),
        'allocation_effect': attrib.sum(d => d.allocation_effect),
        'excess_return': attrib.sum(d => d.excess_return)
      }
      return attrib
          .concat(total)
          .sort(d => -d.order)
    },
    kpis() {
      if (!$root.xf || !$root.xf.attribution) return
      let performance = $root.x.performance
      const dates = performance.dates
      const domain = $root.domain
      if (domain && dates) {
        const before_d0 =
          dates[
            dates
              .keys()
              .reverse()
              .find(k => domain[0] > k)
          ] // prev date
        const d0 = dates[dates.keys().find(k => k >= domain[0])] // same date (or next)
        let after_d1 = dates[dates.keys().find(k => k > domain[1])] // next date
        performance = performance.slice(d0, after_d1)
      }
      performance = performance.group('date').map(d => d[0])
      const excess_return = performance.v().last().fund / performance.v().first().fund - performance.v().last().benchmark / performance.v().first().benchmark
      const dimensions = this.dimensions.flat(Infinity).concat(['isin'])
      const allocation_effect = dimensions.reduce((acc, d) => { acc[d] = ($root.xf.attribution[d] || {}).v().filter(d => d).sum('allocation_effect'); return acc; }, {})
      const selection_effect = dimensions.reduce((acc, d) => { acc[d] = ($root.xf.attribution[d] || {}).v().filter(d => d).sum('selection_effect'); return acc; }, {})
      return {
        performance: { excess_return, fund: performance.v().last().fund / performance.v().first().fund - 1, benchmark: performance.v().last().benchmark / performance.v().first().benchmark - 1 },
        attribution: { allocation_effect, selection_effect },
      }
    },
  },
  methods: {
    download_attrib() {
      const filename = ['attribution', this.$route.params.userflow].join('-') + '.csv'
      const dim = $root.query.attribdim|| $root.xf.attribution.keys().filter(d => d !== 'data').slice(1).first()
      return this.attribution_table.map(o => o.filter((d,k) => !['_line_id', '_group', '_group_id', 'order'].includes(k))).toCSV(this.$root.lang).replace('dimension', this.t[dim] || dim).download(filename)
    },
    post_data(dimension) {
      if (!$root.xf || !$root.xf.attribution) return {}
      if (!$root.xf.attribution.keys().includes(dimension)) return {}
      return $root.xf.attribution[dimension].filter().map((d) => {return {allocation_effect:d.allocation_effect, selection_effect: d.selection_effect } })
    },
  },
  mounted() {
    this.$router.push({ query: { domain: this.$route.query.domain } })
  },
  watch: {
    '$route.fullPath'() {
      this.$router.push({ query: { domain: this.$route.query.domain, attribdim: $root.query.attribdim } })
    },
  },
}
</script>
