<script setup lang="ts">
import { ref, computed } from 'vue'
import { validate, INxChartOptions } from './nx-chart'
const hover = ref(null)
const emit = defineEmits(['seriesHover', 'seriesClick', 'parsed'])
const props = defineProps<{
  data: any[]
  options: INxChartOptions
}>()
const _props = computed(() => {
  const parsedProps = validate(props)
  emit('parsed', parsedProps)
  return parsedProps
})
const _options = computed(() => _props.value.options)
const _events = {
  seriesHover(event) {
    if (event) {
      event.data = _props.value.data
      event.options = _props.value.options
    }
    emit('seriesHover', event)
    if (props.options.tooltip === false) return
    hover.value = event
  },
  seriesClick() {
    emit('seriesClick', hover.value)
  },
}
const tooltip = computed(() => {
  const select = hover.value
  const data = _props.value.data
  const opts = _props.value.options
  const agg = v => (opts.formatLabel || (v => v))(opts.aggregateFn(v))
  const fx = opts.formatX
  const fy = opts.formatLabel
  const fz = opts.formatZ
  if (select == null && opts.viz === 'pie') return { title: [fx(opts.x), agg(props.data)] }
  if (select == null) return null
  if (opts.viz === 'surface') {
    return {
      items: [
        [opts.x, fx(select.x)],
        [opts.y, fy(select.y)],
        [opts.z, fz(select.z)],
      ],
      select: select,
    }
  }
  // hover on legend
  if (!select[opts.x] && opts.category) {
    const categories = props.data.map(opts.category).unique().sort()
    const cat = select[opts.category]
    const color = opts.palette[categories.indexOf(cat)]
    const catFn = cat === 'Other' ? v => !categories.includes(v[opts.category]) : v => v[opts.category] === cat
    return {
      title: [fx(cat)],
      items: select.data.map(v => [v[opts.x], agg(v.group.filter(catFn)), color]),
      select: select,
    }
  }
  // if (!select[opts.x]) return null // hover on legend
  const point = data.find(v => Object.equal(v[opts.x], select[opts.x]))
  if (!point) return
  if (opts.viz === 'dot') {
    if (opts.category) {
      const categories = props.data.map(opts.category).unique().sort()
      return {
        title: [select[opts.category], '', opts.palette[categories.indexOf(select[opts.category])]],
        items: [
          [opts.x, fx(select[opts.x])],
          [opts.y, fy(select[opts.y])],
          select[opts.z] && [opts.z, fz(select[opts.z])],
        ].filter(v => v),
        select: select,
        point: point,
      }
    }
    return {
      items: [
        [opts.x, fx(select[opts.x])],
        [opts.y, fy(select[opts.y])],
        select[opts.z] && [opts.z, fz(select[opts.z])],
      ].filter(v => v),
      select: select,
      point: point,
    }
  }
  if (opts.category) {
    const categories = props.data.map(opts.category).unique().sort()
    return {
      title: [fx(select[opts.x])],
      items: categories.map((v, i) => [v, agg(point.group.filter(g => g[opts.category] === v)), opts.palette[i]]),
      select: select,
      point: point,
    }
  }
  return {
    title: [fx(point._other || point[opts.x]), fy(point._total || point[opts.y])],
    select: select,
    point: point,
  }
})
const legend = computed(() => {
  const data = _props.value.data
  const opts = _props.value.options
  let agg = v => (opts.formatLabel || (v => v))(opts.aggregateFn(v))
  if (opts.viz === 'dot') agg = v => ''
  if (opts.legend === false) return []
  if (opts.viz === 'pie') return Object.entries(data.group(opts.x)).map(([k, v], i) => [k, agg(v), opts.palette[i]])
  if (opts.legend == null && !opts.category) return []
  if (!opts.category) return [[opts.x, agg(props.data), opts.palette[0]]]
  return Object.entries(props.data.group(opts.category))
    .sort(([k, v]) => k)
    .map(([k, v], i) => [k, agg(v), opts.palette[i]])
})
</script>

<template>
  <div class="nx-chart relative flex h-full min-h-[100px] w-full">
    <template v-if="_props.error">
      <slot name="error" :error="_props.error">
        <div class="flex h-full w-full flex-col items-center justify-center">
          <div class="i-carbon/warning-other !h-16 !w-16 text-gray-300"></div>
          <div class="py-4 text-center leading-snug text-gray-600">{{ _props.error.message }}</div>
        </div>
      </slot>
    </template>
    <template v-else>
      <slot name="side" v-bind="{ ..._props, legend }">
        <div
          class="flex w-[min(50%,10rem)] flex-col gap-1 text-sm"
          v-if="_options.title || _options.description || _options.legend"
        >
          <slot name="title">
            <div class="line-clamp-2 px-1 text-[110%] font-medium leading-tight" v-if="_options.title">
              {{ _options.title }}
            </div>
          </slot>
          <slot name="description">
            <div class="line-clamp-3 px-1 leading-tight" v-if="_options.description">{{ _options.description }}</div>
          </slot>
          <slot name="legend" v-bind="{ ..._props, legend }">
            <nx-chart-legend
              class="flex flex-col gap-1"
              :legend="legend"
              :options="_options"
              :hover="hover"
              :eventListeners="_events"
            ></nx-chart-legend>
          </slot>
          <slot name="tooltip" :tooltip="tooltip">
            <nx-chart-tooltip :tooltip="tooltip" :options="_options"></nx-chart-tooltip>
          </slot>
        </div>
      </slot>
      <slot name="chart">
        <div class="relative max-h-full max-w-full flex-1">
          <slot name="tooltip-center" :tooltip="tooltip">
            <div
              class="pointer-events-none absolute inset-0 z-10 m-auto flex aspect-1 flex-col items-center justify-center @container"
              :class="_options.label ? 'max-h-[35%] max-w-[35%]' : 'max-h-[50%] max-w-[50%]'"
              v-if="_options.viz === 'pie'"
            >
              <div
                class="invisible max-w-[-webkit-fill-available] truncate text-xl font-bold @[50px]:visible @[120px]:text-3xl"
              >
                {{ tooltip ? tooltip.title[1] : '' }}
              </div>
              <div
                class="invisible line-clamp-2 max-w-[-webkit-fill-available] whitespace-pre-line text-center text-sm leading-tight @[50px]:visible @[120px]:text-lg"
              >
                {{ tooltip ? tooltip.title[0] : '' }}
              </div>
            </div>
          </slot>
          <component class="m-auto h-full" :is="_props.is" :data="_props.data" :options="_props.options" v-on="_events">
            <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
              <slot :name="name" v-bind="slotData"></slot>
            </template>
          </component>
        </div>
      </slot>
    </template>
  </div>
</template>
