<style>
.pdf-esg-carbon-1 .row .column:nth-child(1) { display: flex; min-width: 260px; max-width: 260px; overflow: hidden; }
.pdf-esg-carbon-1 .row .column:nth-child(2) { display: flex; flex-grow: 1; min-width: unset!important; max-width: unset!important;}
.pdf .carbon_intensity .bar-chart .rect { --size: 3;}
.pdf .carbon_intensity .bar-chart .rect.benchmark { background: var(--secondary)!important; }
.pdf .carbon_intensity .bar-chart .row { padding: 8px 0px; }

.sfdr-block { display: flex;flex-direction: row;justify-content: center; }
.sfdr-block .row { width: 200px;background: var(--background);height: 40px;border-radius: 8px;padding: 8px; }
.sfdr-block .row .sfdr-text { display: flex;flex: 2;align-items: center;text-align: center;font-weight: 600; }
.sfdr-block .row .sfdr-value { display: flex;flex: 1;align-items: center;justify-content: center;font-weight: 600;font-size: 12px; }

.pdf .block-data_impact { min-width: 100%;height: 300px; }
.table_footer { font-weight: bold; }
.carbon_footprint_by_sector { margin-top: 0px!important; margin-bottom: 0px!important;}
.pdf-esg-carbon-1 .subtitle.row { margin-left: 8px; }
.pdf-esg-carbon-1 .top5_esg .pdf-table .cell:not(.c1),
.pdf-esg-carbon-1 .worst5_esg .pdf-table .cell:not(.c1) {
  width: 57px;
}

</style>

<template lang="pug">
.pdf-esg-carbon-1
  .subtitle.row(v-if="data.share.characteristics.benchmark_esg") {{ (t['univers_definition'] || 'univers_definition') + ': ' + t[data.share.characteristics.benchmark_esg] }}
  .row
    .column
      brick.no-bold.center-value(:title="data.share.characteristics.esg_coverage_table" :subtitle="$root.t[`${data.share.characteristics.esg_coverage_table}_subtitle`] || 'subtitle_esg_coverage'" :data="data.tables.esg_coverage_table")
        div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
      brick.esg_vbar(title="esg_distribution" type="vbar" :data="data.esg.esg_distribution" :options="{ format: '.2%', sort: 'key', variant: 'clustered-bar'}" subtitle="subtitle_distribution_esg")
        div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
      brick(title="carbon_intensity" subtitle="subtitle_carbon_intensity")
        pdf-horizontalbar-icon(:data="data.esg.carbon_intensity.data" :options="{ format: '.0f'}")
        .sfdr-block(v-if="data.share.characteristics.display_carbon_intensity_bench && data.share.characteristics.benchmark_esg && ['fund', 'univers'].every(d => data.esg.carbon_intensity.data.map(d => d.key).includes(d))")
          .row
            .sfdr-text {{ t.carbon_intensity_difference || 'carbon_intensity_difference' }}
            .sfdr-value {{ format('.0%')(data.esg.carbon_intensity_difference) }}
        div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
      brick.no-bold.center-value(:title="data.share.characteristics.carbon_coverage_table" subtitle="subtitle_carbon_coverage" :data="data.tables.carbon_coverage_table")
        div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
      brick.carbon_vbar(title="carbon_distribution" type="vbar" :data="data.esg.carbon_distribution" :options="{ format: '.2%', sort: 'key', variant: 'clustered-bar'}" subtitle="subtitle_distribution_carbon")
        div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column
      // TODO : add v-if to top5 best/worst
      //brick.no-bold.main_table_esg(title="top5_esg" :data="data.esg.main_lines_table_esg.desc" :options="{ limit: 6 }" subtitle="subtitle_main_lines_esg" v-if="isExist")
      //  div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
      //brick.no-bold.main_table_esg(title="worst5_esg" :data="data.esg.main_lines_table_esg.asc" :options="{ limit: 6 }" subtitle="subtitle_main_lines_esg" v-if="isExist")
      //  div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
      brick(title='carbon_footprint_by_sector' :subtitle="t['carbon_footprint_by_sector_subtitle'] || 'carbon_footprint_by_sector_subtitle'")
      table-graph(:data="data.esg.carbon_footprint_by_sector")
        .row.table_footer(v-if="data.share.characteristics.thematique_fonds === 'credit' ") {{ (t['carbon_footprint_hc'] || 'carbon_footprint_hc') + ' ' + data.esg.high_carbon + ' ' + (t['tco2m']|| 'tco2m') }}
        .row.table_footer(v-if="data.share.characteristics.thematique_fonds === 'credit' ") {{ (t['carbon_footprint_lc'] || 'carbon_footprint_lc') + ' ' + data.esg.low_carbon + ' ' + (t['tco2m'] || 'tco2m') }}
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
      brick.no-bold.main_table_carbon(title="top5_carbon" :data="data.esg.main_lines_table_carbon.desc" :options="{ limit: 6 }" subtitle="subtitle_main_lines_carbon")
        div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
      brick.no-bold.main_table_carbon(title="worst5_carbon" :data="data.esg.main_lines_table_carbon.asc" :options="{ limit: 6 }" subtitle="subtitle_main_lines_carbon")
        div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
      .column
        .row
          brick(title="carbon_footprint" subtitle="subtitle_carbon_footprint")
            pdf-horizontalbar-icon(:data="data.esg.carbon_footprint" :options="{ format: '.0f'}")
            .sfdr-block(v-if="data.esg.carbon_footprint && ['fund', 'univers'].every(d => data.esg.carbon_footprint.map(d => d.key).includes(d))")
              .row
                .sfdr-text {{ t.carbon_footprint_difference || 'carbon_footprint_difference' }}
                .sfdr-value {{ format('.0%')(data.esg.carbon_footprint_difference) }}
            div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
          .column
            brick(v-if='data.share.characteristics.benchmark_esg && !data.share.characteristics.esg_thematik_carbon_hide_benchmark' title="theme_breakdown" :subtitle="t['theme_breakdown_subtitle'] || 'theme_breakdown_subtitle'" type='hbar' :data="data.esg.theme_breakdown" :options="{ format: '.2%'}")
              div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
            brick(v-if='!data.share.characteristics.benchmark_esg || data.share.characteristics.esg_thematik_carbon_hide_benchmark' title="theme_breakdown" :subtitle="t['theme_breakdown_subtitle'] || 'theme_breakdown_subtitle'" type='pie' :data="data.esg.theme_breakdown" :options="{ format: '.2%'}")
              div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
      .column
        brick.no-bold(v-if="data.share.characteristics.thematique_fonds === 'credit'" title="main_green_bonds" :data="data.esg.main_green_bonds" :options="{ limit: 6 }")
          .sfdr-block
            .row
              .sfdr-text {{'Green Bonds: ' }}
              .sfdr-value {{ format('.2%')(data.esg.main_green_bonds.sum('weight_fund_pct')) }}
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        brick(v-if="data.share.characteristics.display_greefin_repart && data.share.characteristics.thematique_fonds === 'action'" title="repart_greenfin" :subtitle="t['repart_greenfin_subtitle'] || 'repart_greenfin_subtitle'" :disclaimer="t['repart_greenfin_disclaimer'] || ''" type="pie" :data="data.esg.greenfin_repartition" :options="{ sort: d => d.key}")
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
        brick(v-else-if="data.share.characteristics.thematique_fonds === 'action'" title="carbon_solution_providers" :subtitle="t['carbon_solution_providers_subtitle'] || 'carbon_solution_providers_subtitle'" :data="data.esg.main_carbon_provider" :options="{ format: '.0f' }")
          div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
</template>

<script>
export const additions = {}
export default {
  props: { 'data': Object }
}
</script>
