import { GraphQlService } from './GraphQlService'

export class TemplateService extends GraphQlService {
  async list() {
    return (
      await this.query(`
      query Template {
        builder {
          templates {
            name
          }
        }
      }`)
    ).data.builder.templates.map(template => template.name)
  }

  async listWithTemplateContext() {
    return (
      await this.query(`
      query Query {
      builder {
        templates {
          name
          id
          createdAt
          dataReportId
          readOnly
          type
        }
      }
    }
      `)
    ).data.builder.templates
  }

  async get(name) {
    return (
      await this.query(
        `
      query Template($name: String!, $limit: Float) {
        builder {
          template(name: $name, limit: $limit) {
            createdAt
            id
            layout
            name
            user
            dataReportId
            readOnly
            type
          }
        }
      }`,
        { name, limit: 1 },
      )
    ).data.builder.template[0]
  }

  async all(name, limit) {
    return (
      await this.query(
        `
      query TemplateVersions($name: String!, $limit: Float) {
        builder {
          template(name: $name, limit: $limit) {
            createdAt
            id
            layout
            name
            user
            dataReportId
            readOnly
            type
          }
        }
      }`,
        { name, limit: limit || 50 },
      )
    ).data.builder.template
  }

  async create(name, layout, type, dataReportId) {
    return (
      await this.query(
        `
      mutation AddBuilderTemplate($name: String!, $layout: JSON!, $type: String!, $dataReportId: Int) {
        addBuilderTemplate(name: $name, layout: $layout, type: $type, dataReportId: $dataReportId, ) {
          createdAt
          id
          layout
          name
          user
          dataReportId
          type
        }
      }`,
        { name, layout, type, dataReportId },
      )
    ).data.addBuilderTemplate
  }

  async remove(id) {
    return await this.query(
      `
      mutation RemoveBuilderTemplate($id: Int!) {
        removeBuilderTemplate(id: $id)
      }`,
      { id },
    )
  }

  async removeAll(name) {
    return await this.query(
      `
      mutation RemoveBuilderTemplatesByName($name: String!) {
        removeBuilderTemplatesByName(name: $name)
      }`,
      { name },
    )
  }
}

const templateService = new TemplateService()
export default templateService
