import { pagesFromGlobRecord, componentsFromGlobRecord } from '../../utils/platformBuilder'
import globalMixin from './mixins/global'
import Layout from './Layout.vue'

const config = {
  userflow: ['overview', 'performance', 'contribution', 'attribution', 'allocation', 'details', 'documents'],
  formats: {
    nav: '.2f',
    aum: '.1fs',
    pnl: '.3s€',
    fees: '+.2%',
    price: '.3s€',
    price_change: '+.2%',
    active_share: '.2f',
    diff: '.2f',
    weight: '.2%',
    quantity: '.3s',
    position: '.3s',
    allocation: '.2%',
    sensitaux: 'pt',
    performance: '+.2%',
    contribution: '+bp',
    attribution: '+bp',
    volatility: '.2%',
    drawdown: '.2%',
    tracking_error: '.2%',
    information_ratio: '.2f',
    sharpe_ratio: '.2f',
    excess_return: '+.2%',
    allocation_effect: '+.2%',
    selection_effect: '+.2%',
    sum: '+.2%',
    contrib_deltaaction: '.2%',
    pnlOrFeesPerShare: '.2f',
    pnlOrFeesPerFund: '.3s€',
  },
  translation: {
    en: {
      'asset-management': 'Digital Edition',
      slash: 'Funds',
      overview: 'Overview',
      performance: 'Performance',
      contribution: 'Contribution',
      allocation: 'Allocation',
      attribution: 'Attribution',
      details: 'Details',
      documents: 'Documents',
      templates: 'Templates',
      de_performance_historical: 'Historical performance',
      de_real_nav: 'Historical net asset value',
      de_aum_fund_currency: 'Historical Asset under management',
      performance_yearly: 'Yearly performance',
      performance_cumulated_annualized: 'Cumulated performance / annualized',
      performance_cumulated: 'Cumulated performance',
      performance_annualized: 'Annualized performance',
      performance_monthly: 'Monthly performance',
      performance_rolling: 'Rolling performance',
      no_lookthrough: 'No lookthrough',
      performance_benchmark: 'Benchmark performance',
      fees: 'Fees',
      allocation_benchmark: 'Benchmark risk',
      allocation_evolution: 'Risk evolution',
      volatility: 'Volatility',
      volatility_fund: 'Fund volatility',
      volatility_benchmark: 'Benchmark volatility',
      drawdown: 'Drawdown',
      drawdown_benchmark: 'Benchmark drawdown',
      recovery: 'Recovery',
      recovery_benchmark: 'Benchmark recovery',
      tracking_error: 'Tracking error',
      information_ratio: 'Information ratio',
      sharpe_ratio: 'Sharpe ratio',
      sharpe_ratio_benchmark: 'Benchmark Sharpe ratio',
      excess_return: 'Excess return',
      allocation_effect: 'Allocation effect',
      selection_effect: 'Selection + interaction',
      inception: 'Inception',
      compare: 'Compare',
      diff: 'Fund vs Benchmark',
      lines: 'Lines',
      evolution: 'Evolution',
      dedicated: 'Dedicated funds',
      open_ended: 'Open ended funds',
      top_buy: 'Top buy',
      top_sell: 'Top sell',
      management_comment: 'Management comment',
      management_orientation: 'Management orientation',
      managers: 'Managers',
      weight_benchmark: 'Weight benchmark',
      contribution_benchmark: 'Contribution benchmark',
      pnl_benchmark: 'PnL benchmark',
      top_contributors: 'Top contributors',
      worst_contributors: 'Worst contributors',
      worst_allocators: 'Worst allocators',
      main_movements: 'Main movements',
      main_contributors: 'Main contributors',
      main_lines: 'Main Lines',
      fund_main_lines: 'Fund Main Lines',
      benchmark_main_lines: 'Benchmark Main Lines',
      fund: 'Fund',
      benchmark: 'Benchmark',
      share: 'Share',
      nav: 'Net asset value',
      performance_brut: 'Performance',
      aum: 'Asset under management',
      isin: 'ISIN',
      pnl: 'PnL',
      price: 'Price',
      price_change: 'Price change',
      weight: 'Weight',
      weight_fund: 'Weight',
      quantity: 'Quantity',
      position: 'Position',
      active_share: 'Active share',
      factsheet: 'Factsheet',
      risk_scale: 'Risk Scale',
      morningstar: 'Morningstar™️',
      exposure: 'Equity exposure',
      holding_number: 'Number of Holdings',
      average_cap: 'Average capitalization',
      growth: 'EPS growth (Next 12 M./Last 12 M.)',
      forecasts: 'PE (Next 12 Months)',
      risk_indicators: 'Risk indicators',
      average_weight_fund: 'Average weight fund',
      average_weight_benchmark: 'Average weight benchmark',
      fund_contribution: 'Fund contribution',
      benchmark_contribution: 'Benchmark contribution',
      weekly_report: 'Weekly Report',
      monthly_report: 'Monthly Report',
      quarterly_report: 'Quarterly Report',
      yearly_report: 'Yearly Report',
      performance_and_risk: 'Performances and risks',
      portfolio: 'Portfolio',
      profile: 'Profile',
      save_pdf: 'Download pdf',
      annual_rate: 'Annual Rate',
      avg_maturity: 'Average Maturity',
      avg_rating_warf: 'Average WARF Rating',
      avg_rating: 'Average Rating',
      bfloor: 'Distance Bfloor',
      convexity_minus: 'Convexity -20%',
      convexity_plus: 'Convexity +20%',
      dbfloor: 'Downside Risk',
      delta: 'Delta %',
      gamma: 'Gamma',
      government: 'Gov.',
      maturity_avg: 'Average Maturity',
      premium: 'Premium',
      rating_avg: 'Average Rating',
      scr_equity: 'Equity',
      scr_interest: 'Interest',
      scr_market: 'Market',
      scr_spread: 'Spread',
      sensibility: 'Rate Sensitivity',
      spread_avg: 'Average Spread',
      sps: 'SPS',
      vega: 'Vega',
      volatility_implied: 'Vol Implicite',
      scr_indicators: 'Solvency ratio (SCR)',
      weight_non_rated: 'Weight Non Rated',
      net_income: 'Net Income',
      custodian: 'Custodian',
      fund_administration: 'Fund administration',
      recommended_investment: 'Recommended Investment',
      updated_prospectus: 'Updated prospectus',
      valuation_frequency: 'Valuation Frequency',
      fixed_management_fees: 'Fixed management Fees',
      performance_fees: 'Performance fees',
      subscription_redemption_fees: 'S/R fees',
      subscription_redemption_custodian: 'S/R custodian',
      registered_country: 'Registered country',
      technical_indicators: 'Technical Indicators',
      disclaimer: 'Disclaimer',
      visualisation: 'Visualisation',
      perf_ytd: 'YTD',
      perf_ytd_benchmark: 'YTD Benchmark',
      risk_scale_kpi: 'SRI',
      main_in: 'Top buy',
      main_out: 'Top sell',
      attribution_details: 'Attribution details',
      export_table: 'Export table',
      download_detailed_attrib: 'Export raw data',
      download_detailed_attrib_deep: 'Export detailed data',
    },
    fr: {
      'asset-management': 'Digital Edition',
      slash: 'Fonds',
      overview: "Vue d'ensemble",
      performance: 'Performance',
      contribution: 'Contribution',
      allocation: 'Allocation',
      attribution: 'Attribution',
      details: 'Ligne-à-Ligne',
      documents: 'Documents',
      templates: 'Templates',
      de_performance_historical: 'Historique des performances',
      de_real_nav: 'Historique des valeurs liquidatives',
      de_aum_fund_currency: "Historique de l'actif sous gestion",
      performance_yearly: 'Performances annuelles',
      performance_cumulated_annualized: 'Performances cumulées / annualisées',
      performance_cumulated: 'Performances cumulées',
      performance_annualized: 'Performances annualisées',
      performance_monthly: 'Performance mensuelle',
      performance_rolling: 'Performance glissante',
      no_lookthrough: 'Pas de transparisation',
      performance_benchmark: 'Performance indice',
      fees: 'Frais',
      allocation_benchmark: 'Positionnement indice',
      allocation_evolution: 'Évolution du positionnement',
      volatility: 'Volatilité',
      volatility_fund: 'Volatilité du fonds',
      volatility_benchmark: "Volatilité de l'indice",
      drawdown: 'Drawdown',
      drawdown_benchmark: 'Drawdown indice',
      recovery: 'Recouvrement',
      recovery_benchmark: 'Recouv. indice',
      tracking_error: 'Tracking error',
      information_ratio: "Ratio d'information",
      sharpe_ratio: 'Ratio de Sharpe',
      sharpe_ratio_benchmark: 'Ratio de Sharpe Indice',
      excess_return: 'Rendement excédentaire',
      allocation_effect: "Effet d'allocation",
      selection_effect: 'Effet de selection + interaction',
      inception: 'Création',
      compare: 'Comparaison',
      diff: 'Fonds vs Indice',
      lines: 'Lignes',
      evolution: 'Évolution',
      dedicated: 'Fonds dédiés',
      open_ended: 'Fonds ouverts',
      top_buy: 'Principales entrées',
      top_sell: 'Principales sorties',
      management_comment: 'Commentaire de gestion',
      management_orientation: 'Orientation de gestion',
      managers: 'Gérants',
      weight_benchmark: 'Poids indice',
      contribution_benchmark: 'Contribution indice',
      pnl_benchmark: 'PnL indice',
      top_contributors: 'Meilleurs contributeurs',
      worst_contributors: 'Moins bons contributeurs',
      worst_allocators: 'Moins bons allocateurs',
      main_movements: 'Principaux mouvements',
      main_contributors: 'Principaux contributeurs',
      main_lines: 'Principales lignes',
      fund_main_lines: 'Principales lignes du fonds',
      benchmark_main_lines: 'Principales lignes du benchmark',
      fund: 'Fonds',
      benchmark: 'Indice',
      share: 'Part',
      nav: 'Valeur liquidative',
      performance_brut: 'Performance',
      aum: 'Actif sous gestion',
      isin: 'ISIN',
      pnl: 'PnL',
      price: 'Prix',
      price_change: 'Var. du prix',
      weight: 'Poids',
      weight_fund: 'Poids',
      quantity: 'Quantité',
      position: 'Position',
      active_share: 'Active share',
      factsheet: 'Rapport de Gestion',
      risk_scale: 'Echelle de risque',
      morningstar: 'Morningstar™️',
      exposure: 'Exposition actions',
      holding_number: 'nombre de lignes',
      average_cap: 'Capitalisation moyenne',
      growth: 'Croissance EPS (Next 12 M./Last 12 M.)',
      forecasts: 'PE (12 prochains mois)',
      risk_indicators: 'Indictateurs de risque',
      average_weight_fund: 'Average weight fund',
      average_weight_benchmark: 'Average weight benchmark',
      fund_contribution: 'Fund contribution',
      benchmark_contribution: 'Benchmark contribution',
      weekly_report: 'Rapport Hebdomadaire',
      monthly_report: 'Rapport Mensuel',
      quarterly_report: 'Rapport trimestriel',
      yearly_report: 'Rapport Annuel',
      performance_and_risk: 'Performances et risques',
      portfolio: 'Portefeuille',
      profile: 'Profil',
      save_pdf: 'Télécharger en pdf',
      annual_rate: 'Taux annualisé',
      avg_maturity: 'Maturité moyenne',
      avg_rating_warf: 'Notation WARF moyenne',
      avg_rating: 'Notation moyenne',
      bfloor: 'Distance Bfloor',
      convexity_minus: 'Convexité -20%',
      convexity_plus: 'Convexité +20%',
      dbfloor: 'Downside Risque',
      delta: 'Delta %',
      gamma: 'Gamma',
      government: 'Gov.',
      maturity_avg: 'Maturité moyenne',
      premium: 'Premium',
      rating_avg: 'Notation moyenne',
      scr_equity: 'Action',
      scr_interest: 'Interêts',
      scr_market: 'Marché',
      scr_spread: 'Spread',
      sensibility: 'Sensi taux',
      spread_avg: 'Spread moyen',
      sps: 'SPS',
      vega: 'Vega',
      volatility_implied: 'Vol Implicite',
      scr_indicators: 'Solvency ratio (SCR)',
      weight_non_rated: 'Poid Non notés',
      net_income: 'Revenu net',
      custodian: 'Dépositaire',
      fund_administration: 'Administrateur du fonds',
      recommended_investment: 'Investment conseillé',
      updated_prospectus: 'Modification prospectus',
      valuation_frequency: 'Frequence de valo.',
      fixed_management_fees: 'Frais de gestion fixes',
      performance_fees: 'Frais de performance',
      subscription_redemption_fees: 'Frais de S/R',
      subscription_redemption_custodian: 'Dépositaire des S/R',
      registered_country: "Pays d'enregistrement",
      technical_indicators: 'Indicateurs techniques',
      disclaimer: 'Avertissement',
      visualisation: 'Visualisation',
      perf_ytd: 'YTD',
      perf_ytd_benchmark: 'YTD Benchmark',
      risk_scale_kpi: 'SRI',
      main_in: 'Principales entrées',
      main_out: 'Principales sorties',
      attribution_details: "Détails de l'attribution",
      export_table: 'Exporter la table',
      download_detailed_attrib: 'Exporter les données brutes',
      download_detailed_attrib_deep: 'Exporter les données détaillées',
    },
  },
}

const translations = config.translation

export default {
  /**
   * App name
   */
  name: 'digital-edition',
  /**
   * Layout component
   */
  layout: Layout,
  /**
   * Listed
   */
  listed: true,
  /**
   * If common, this app will be merged with others
   */
  common: false,
  /**
   * Prefix to apply on the pages routes
   */
  routePrefix: '/digital-edition',
  /**
   * Pages components to map to the router
   */
  pages: pagesFromGlobRecord(import.meta.globEager('./screens/**/*.vue'), './screens'),
  /**
   * Components to add to the app
   */
  components: componentsFromGlobRecord(import.meta.globEager('./components/**/*.vue')),
  /**
   * Translations to add
   */
  translations,
  /**
   * Config partial
   */
  config,
  /**
   * globalMixins backport
   */
  globalMixin,
}
