<style>
.stylesheets .cell-format {
  flex: unset !important;
}
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1 Stylesheets ({{stylesheets?.length || 0}})
.block.expand
  spreadsheet.stripped.expand.nosort(:data="stylesheets" :options="{ columns: ['name', 'Last Update', 'usage', 'actions'], editable: false }")
    template(v-slot:cell-last-update="{ column, line }")
      div {{ line.createdAt }}
    template(v-slot:cell-actions="{ column, line }")
      .row
        button.ghost(tt="View" @click="edit(line)" v-if="line.readOnly")
          svg-icon(name="pt-icon-eye-open")
        button.ghost(tt="Edit" @click="edit(line)" v-else)
          svg-icon(name="pt-icon-edit")
        button.ghost(tt="Duplicate" @click="duplicate(line)")
          svg-icon(name="ic_file_copy")
        button.ghost(tt="Delete" @click="confirmRemoveId=line" v-if="true" :disabled="line.readOnly || line.usage")
          svg-icon(name="pt-icon-trash")
  .row
    button.primary(@click="create") Create stylesheet
  popup(:show="confirmRemoveId")
      template(v-slot:header)
        | {{t["confirmation_remove"]}}
      template(v-slot:content)
        | {{t["text_remove"]}}
      template(v-slot:action)
        button.secondary-action(@click="confirmRemoveId = null") {{t['confirmation_btn_close']}}
        button.main-action(@click="remove(confirmRemoveId)") {{t["confirmation_btn_delete"]}}
</template>

<script setup>
import { ref, onMounted } from 'vue'
import Popup from '../../../components/ui/popup.vue'
import stylesheetService from '../../../services/StylesheetService'
import { merge } from '@100-m/hauru/src/utils/merge'

const isLoading = ref(true)
const stylesheets = ref([])
const confirmRemoveId = ref(null)

const getStylesheets = async () => {
  isLoading.value = true
  const stylesheets = await stylesheetService.listWithStylesheetContext()
  isLoading.value = false
  return stylesheets
}

const create = async () => {
  const newName = prompt('Enter template name', new Date().format('YYYY-MM-DD hh:mm'))
  if (stylesheets.value && stylesheets.value.find(v => v === newName)) return alert('Stylesheet already exists')
  const defaultStylesheet = {
    styles: {},
    palettes: [],
  }
  const newStylesheet = merge(defaultStylesheet, config.defaultStylesheet)
  await stylesheetService.create({ name: newName, ...newStylesheet })
  edit({ name: newName })
}
const edit = stylesheetInfo => {
  $root.$router.push(`/builder/stylesheet?stylesheetName=${stylesheetInfo.name}`)
}
const duplicate = async stylesheetInfo => {
  const stylesheet = await stylesheetService.get(stylesheetInfo.name)
  const newName = prompt(
    'Enter stylesheet name',
    `${stylesheetInfo.name.replace(/ \d\d\d\d-\d\d-\d\d \d\d:\d\d/, '')} ${new Date().format('YYYY-MM-DD hh:mm')}`,
  )
  await stylesheetService.create({ ...stylesheet, name: newName })
  edit({ name: newName })
}
const remove = async stylesheetInfo => {
  if (stylesheetInfo.readOnly) return
  confirmRemoveId.value = null
  await stylesheetService.removeAll(stylesheetInfo.name)
  stylesheets.value = await getStylesheets()
}
onMounted(async () => {
  stylesheets.value = await getStylesheets()
})
</script>

<script>
export default {
  icon: 'ic_paint',
}
</script>
