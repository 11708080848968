<style>
.large {
  flex: 3;
}
.small {
  flex: 2;
}
.pdf-esg-diversifie_dynamique .pdf-table .cell:not(.c1) {
  width:18%;
}
.small .block-pdf-table {
  width: 80%;
}
.pdf-esg-diversifie_dynamique > .row.margin_space{
  margin-top: 40px;
}
.pdf-esg-diversifie_dynamique .main_lines_pocket { flex: 1; }
</style>

<template lang="pug">
.pdf-esg-diversifie_dynamique
  .subtitle.row(v-if="data.share.characteristics.benchmark_esg") {{ (t['univers_definition'] || 'univers_definition') + ': ' + t[data.share.characteristics.benchmark_esg] }}
  .row
    .column.small
      .row
        brick(:title="data.share.characteristics.esg_coverage_table" v-if="data.share.characteristics.score_esg_dirr")
          pdf-esgscore-scale(
            :score_esg_ex_derivative="data.share.characteristics.score_esg_ex_derivative"
            :score_esg_benchmark="parseFloat(data.tables.esg_coverage_table.find(el=>el[0]==='esg_score')[2].replace(',','.')) || 0"
          )
      .row
        brick.no-bold.right-value(
          :data="data.tables.esg_coverage_table"
        )
          .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column.large
      brick.no-bold.right-value(
        subtitle="esg_score_by_pocket_subtitle"
        title="esg_score_by_pocket"
        :data="data.esg.esg_score_by_pocket"
      )
        .row.risk_disclaimer(v-if="data.share.characteristics.benchmark_poche_action") {{ ($root.t['univers_definition_poche_action'] || 'univers_definition_poche_action')  + ': ' +( $root.t[data.share.characteristics.benchmark_poche_action] || data.share.characteristics.benchmark_poche_action) }}
        .row.risk_disclaimer(v-if="data.share.characteristics.benchmark_poche_credit") {{ ($root.t['univers_definition_poche_credit'] || 'univers_definition_poche_credit')  + ': ' +( $root.t[data.share.characteristics.benchmark_poche_credit] || data.share.characteristics.benchmark_poche_credit) }}
        .row.risk_disclaimer(v-if="data.share.characteristics.benchmark_poche_obligations") {{ ($root.t['univers_definition_poche_obligations'] || 'univers_definition_poche_obligations')  + ': ' +( $root.t[data.share.characteristics.benchmark_poche_obligations] || data.share.characteristics.benchmark_poche_obligations) }}
        .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .row.margin_space
    brick.no-bold(subtitle="main_esg_scores_subtitle" title="main_esg_scores")
      .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .row
    .column.main_lines_pocket(v-if="data.esg.main_lines_table_esg_action.desc.length>1 || data.esg.main_lines_table_esg_action.asc.length>1 ")
      brick.no-bold.right-value(title="action_pocket" :data="data.esg.main_lines_table_esg_action.desc" :options="{ limit: 6 }")
      brick.no-bold.right-value(:data="data.esg.main_lines_table_esg_action.asc" :options="{ limit: 6 }")
      .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.characteristmain_lines_table_esg_actionic.userflow.management_company] }}
    .column.main_lines_pocket(v-if="data.esg.main_lines_table_esg_credit.desc.length>1 || data.esg.main_lines_table_esg_credit.asc.length>1 ")
      brick.no-bold.right-value(title="credit_pocket" :data="data.esg.main_lines_table_esg_credit.desc" :options="{ limit: 6 }")
      brick.no-bold.right-value(:data="data.esg.main_lines_table_esg_credit.asc" :options="{ limit: 6 }")
      .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
    .column.main_lines_pocket(v-if="data.esg.main_lines_table_esg_govies.desc.length>1 || data.esg.main_lines_table_esg_govies.asc.length>1 ")
      brick.no-bold.right-value(title="govies_pocket" :data="data.esg.main_lines_table_esg_govies.desc" :options="{ limit: 6 }")
      brick.no-bold.right-value(:data="data.esg.main_lines_table_esg_govies.asc" :options="{ limit: 6 }")
      div.source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .row.margin_space
    brick.risk_disclaimer(title="definitions_esg" v-if="(data.share.characteristics.definition_esg_list || []).length > 0")
      div(v-for="definition in data.share.characteristics.definition_esg_list.split(',')" v-html="t[definition] || definition")
</template>

<script>
export default {
  props: { data: Object },
}
</script>
