<template>
  <builder-chart class="builder-pie" v-bind="{ ...props, data: translateData(data), nxChartOptions, chartOptions }">
    <template #tooltip-center><div></div></template>
    <template v-for="(_, name) in $slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>
  </builder-chart>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { pie as api } from '../composables/builderOptions'
import { pie } from './stories'
import { BuilderProps } from '../composables/builderComponent'
import useTranslations from '../composables/translations'

const props = defineProps<BuilderProps>()
const { translateData } = useTranslations(props)
// const count = computed(() => (Array.isArray(props.data) && props.data.length) || 10)
// const paletteFn = computed(() => (idx: number) => `rgb(var(--primary), ${(1 - idx / count.value).toFixed(2)})`)
const nxChartOptions = computed(() => {
  // debugger
  return {
    viz: 'pie',
    lib: 'nx',
    // palette: Array.from({ length: count.value }, (_, idx) => paletteFn.value(idx)),
  }
})
const chartOptions = {
  legendOptions: {
    showValues: true,
    position: 'bottom',
    orientation: 'vertical',
  },
  ...props.chartOptions,
}
</script>

<script lang="ts">
export default {
  api,
  styles: {
    '.builder-pie .legend-color': {
      name: 'Legend Color',
      css: `width: 12px;
height: 12px;`,
    },
    '.builder-pie .legend-value': '',
    '.builder-pie .legend-label': '',
  },
  story: pie,
}
</script>
<style scoped>
.builder-pie :deep(.nx-pie) {
  max-width: 200px;
}
</style>
