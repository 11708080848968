<template>
  <lfr-block v-if="data" class="relative" :data="data" :options="options">
    <nx-colors-legend v-if="options.legend" :legends="legends"/>
    <div class="w-full flex-col">
      <div v-for="(item, index) in data.slice(0, options.limit || Infinity)" >
        <div v-if="item[options.y[0]] || item[options.y[1]]" class="flex w-full items-center space-x-2">
          <div class="key flex-[3] text-right leading-[11.2px]">{{ item[options.x] }}</div>
          <div class="flex-1">
            <div class="bg-primary h-2 rounded-sm" :style="{ width: size(item[options.y[0]]) + '%' }"></div>
          </div>
          <div :class="'px-1'+(options.y[1] ? ' text-right double-value' : ' single-value')">{{ format(item[options.y[0]], options) }}</div>
          <div class="px-1 text-left text-secondary empty:hidden double-value">{{ (item[options.y[1]] > 0 ? '+' : '') + format(item[options.y[1]], options) }}</div>
        </div>
      </div>
    </div>
  </lfr-block>
</template>

<style>
.lfr-bar .key {
  max-width: 30%;
}
.lfr-bar .double-value {
  width: 15%;
}
.lfr-bar .single-value {
  width: 30%;
}
</style>

<script setup>
import format from '../../format.js'
import useLegends from './useLegends'
const { data, options } = defineProps(['data', 'options'])
const legends = useLegends(options.y.slice(0, 2), options.data.data.header.domain, options.data.data.header.domainPreviousMonth, options.data.translation)
const max = data.map(v => v[options.y[0]]).max()
const size = val => [Math.round((val * 100) / max), 100].min()
</script>

<script>
export default {
  api: {
    x: {
      label: 'X Axis',
      autocomplete: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === String)
          .map(([k, v]) => k),
      default: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === String)
          .map(([k, v]) => k)[0],
      attrs: {
        required: true,
      },
    },
    y: {
      label: 'Y Axis',
      autocomplete: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === Number)
          .map(([k, v]) => k),
      default: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === Number)
          .map(([k, v]) => k)
          .slice(0, 1),
      attrs: {
        required: true,
        multiple: true,
      },
    },
    unit: {
      label: 'Unit',
      default: () => '%',
    },
    digit: {
      label: 'Decimals',
      default: () => 2,
      attrs: {
        type: 'number',
      },
    },
    limit: {
      label: 'Limit',
      default: () => 10,
      attrs: {
        type: 'number',
      },
    },
    legend: {
      label: 'Legend',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
  },
}
</script>
