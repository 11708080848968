<style>
.pdf-esg-cigg-2 .legend {
  display: none;
}
</style>

<template lang="pug">
.pdf-esg-cigg-2
  //-  ** NXIMP-944 - Commenting the Impact section out (against considerations for better practice - strong possiblilty 
  //-  **  that client will want it restored in near future)
  //- .subtitle.row(v-if="data.share.characteristics.benchmark_esg") {{ (t['univers_definition'] || 'univers_definition') + ': ' + t[data.share.characteristics.benchmark_poche_credit] }}
  //- .column(v-if="!data.share.characteristics.hide_data_impact")
  //-   brick(:subtitle="t['data_impact_subtitle'] || 'data_impact_subtitle'" :title="t['data_impact_credit_pocket'] || 'data_impact_credit_pocket'")
  //-   pdf-pictogram(:data="data.esg.esg_data_impact")
  //-   .source(v-if="$root.lang.includes('be')") {{ t.source + ' ' + t[data.share.characteristics.management_company] }}
  .column
    brick.risk_disclaimer(title="definitions_esg" v-if="(data.share.characteristics.definition_esg_list || []).length > 0")
      div(v-for="definition in data.share.characteristics.definition_esg_list.split(',')" v-html="t[definition] || definition") 
</template>

<script>
export default {
  props: { data: Object },
}
</script>
