<template>
  <div class="flex flex-row items-center gap-1">
    <input class="w-fit min-w-[80px] border-none p-1" :value="palette.name" @change="updateName" />
    <div
      class="color-indicator flex flex-row items-center gap-1"
      v-for="(color, index) in palette.colors"
      :key="index"
      :data-index="index"
      draggable="true"
      @dragstart="dragStart($event, index)"
      @dragover.prevent="dragOver(index)"
      @drop="drop($event, index)"
    >
      <div class="border-1 relative rounded border border-gray-200 hover:ring-1 hover:ring-black">
        <input :id="'color-' + index" class="!h-6 !w-6 cursor-pointer" v-model="palette.colors[index]" type="color" />
        <div
          class="trash-icon absolute top-full z-20 w-full cursor-pointer bg-transparent pt-[2px]"
          @click="deleteColor(index)"
        >
          <div class="rounded bg-white p-1">
            <svg-icon class="h-4 w-4 fill-gray-600" name="pt-icon-trash"></svg-icon>
          </div>
        </div>
      </div>
      <div class="h-6 w-1 bg-opacity-50" :class="{ 'bg-gray-500': dragOverIndex === index }"></div>
    </div>
    <button
      class="border-1 flex !h-6 !w-6 flex-col items-center justify-center rounded text-center text-gray-400 hover:bg-gray-200"
      @click="addColor"
    >
      <div
        class="bg-gray-400 fill-white text-xl"
        style="--un-icon: url(https://api.iconify.design/material-symbols:add.svg)"
      ></div>
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Palette } from '../../builder'
interface IProps {
  palette: Palette
}
const props = defineProps<IProps>()
const emit = defineEmits(['update'])
const dragOverIndex = ref<number | null>(null)

const dragStart = (event: DragEvent, index: number) => {
  event.dataTransfer!.setData('index', index.toString())
  const imgElement = document.querySelector(`#color-${index}`) as HTMLElement
  console.log('imgElement', imgElement)
  event.dataTransfer!.setDragImage(imgElement, 0, 0)
}

const dragOver = (index: number) => {
  dragOverIndex.value = +index
}

const drop = (event: DragEvent, targetIndex: number) => {
  if (!props.palette.colors) return
  const sourceIndex = Number(event.dataTransfer!.getData('index'))
  const draggedColor = props.palette.colors[sourceIndex]
  const colors = props.palette.colors
  colors.splice(sourceIndex, 1)
  colors.splice(targetIndex, 0, draggedColor)
  emit('update', { ...props.palette, colors })
  dragOverIndex.value = null
}
function lightenColor(color: string, amount: number) {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
  )
}

function addColor() {
  if (!props.palette) return
  const lastColor = props.palette.colors[props.palette.colors.length - 1]
  const newColor = lightenColor(lastColor, 20)
  emit('update', { ...props.palette, colors: [...props.palette.colors, newColor] })
}
function deleteColor(index: number) {
  const colors = props.palette.colors
  colors.splice(index, 1)
  emit('update', { ...props.palette, colors })
}
function updateName(event: InputEvent) {
  emit('update', { ...props.palette, name: (event.target as HTMLInputElement).value })
}
</script>

<style scoped>
.trash-icon {
  display: none;
}
.color-indicator:hover .trash-icon {
  display: block;
}
</style>
