import { ref, Ref, watch } from 'vue'
export default function useOverflow(targetRef: Ref<HTMLElement>, containerRef: Ref<any>, onOverflow: any) {
  const isOverflow = ref(false)
  function initOverflow() {
    let ready = false
    const resizeObserver = new ResizeObserver(function () {
      if (!containerRef.value) return
      isOverflow.value = containerRef.value.element.scrollHeight > containerRef.value.element.clientHeight
      onOverflow(isOverflow.value)
    })
    watch(
      targetRef,
      () => {
        if (ready) return
        if (targetRef.value) {
          resizeObserver.observe(targetRef.value)
          ready = true
        }
      },
      { immediate: true },
    )
    // targetRef.value && resizeObserver.observe(targetRef.value)
  }
  return { isOverflow, initOverflow }
}
