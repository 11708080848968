<template>
  <lfr-block :data="data" :options="options">
    <nx-colors-legend v-if="options.legend" :legends="legends"/>
    <cjs-bar-chart 
      class="w-full"
      :data="_data"
      :options="_opt"
    >
      <template v-slot:tooltip="{tooltip}">
        <table-tooltip v-if="tooltip.shown" :title=tooltip.title :rows="formatToolTipRows(tooltip.rows)">
        </table-tooltip>
      </template>
    </cjs-bar-chart>
  </lfr-block>
</template>

<script setup>
import useLegends from './useLegends'
const { data, options } = defineProps(['data', 'options'])
const legends = useLegends(options.y.slice(0, 2), options.data.data.header.domain, options.data.data.header.domainPreviousMonth, options.data.translation)
</script>

<script>
export default {
  api: {
    x: {
      label: 'X Axis',
      autocomplete: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === String)
          .map(([k, v]) => k),
      default: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === String)
          .map(([k, v]) => k)[0],
      attrs: {
        required: true,
      },
    },
    y: {
      label: 'Y Axis',
      autocomplete: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === Number)
          .map(([k, v]) => k),
      default: ({ data_component }) =>
        Object.entries(data_component[0] || {})
          .filter(([k, v]) => v?.constructor === Number)
          .map(([k, v]) => k)
          .slice(0, 1),
      attrs: {
        required: true,
        multiple: true,
      },
    },
    unit: {
      label: 'Unit',
      default: () => '%',
    },
    digit: {
      label: 'Decimals',
      default: () => 2,
      attrs: {
        type: 'number',
      }
    },
    limit: {
      label: 'Limit',
      default: () => 10,
      attrs: {
        type: 'number',
      }
    },
    legend: {
      label: 'Legend',
      default: () => true,
      attrs: {
        type: 'checkbox',
        class: 'none',
      },
    },
  },
  data() {
    return {
      argData: this.data,
      argOptions: this.options
    }

  },
  computed: {
    _data() {
      return this.argData.slice(0, this.argOptions.limit || Infinity).filter(el=>this.argOptions.y.some(axis=>el[axis]))
    },
    _opt() {
      return {
        datalabels: true,
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        datasets: [
          {
            borderColor: 'none',
            backgroundColor: this.argOptions.store.versions[0].layout.theme.primary,
            borderRadius: '3',
            key: this.argOptions.y[0],
            datalabels: {
              color: this.argOptions.store.versions[0].layout.theme.primary,
              formatter: value => ' '.concat(format(this.format_spec)((value))), 
              offset: '-3',
              font: {
                size: this.argOptions.store.versions[0].layout.theme.text_size * 4
              },
            }
          },
          {
            borderColor: 'none',
            backgroundColor: this.argOptions.store.versions[0].layout.theme.seondary || '#D78233',
            borderRadius: '3',
            key: this.argOptions.y[1],
            datalabels: {
              color: this.argOptions.store.versions[0].layout.theme.seondary || '#D78233',
              formatter: value => '  '.concat(format(this.format_spec)((value))),
              offset: '-3',
              font: {
                size: this.argOptions.store.versions[0].layout.theme.text_size * 4
              },
            }
          },
        ].slice(0, this.argOptions.y.length),
        labelKey: this.argOptions.x,
        labelFormatter: value => format(this.format_spec)((value)),
        formatY: value => format('.0%')(value),
        textSize: this.argOptions.store.versions[0].layout.theme.text_size * 4,
        //todo: use mergeableOptions
        hideCustomLegend: true,
        hideExternalTooltip: true,
        mergeableOptions: {
          animation: false,
          plugins: {
            tooltip: {
              external: false
            }
          }
        }
      }
    },
    format_spec() {
      return '.'.concat(this.argOptions.digit).concat(this.argOptions.unit)
    }
  },
  methods: {
    formatToolTipRows(rows) {
      return rows
        .map(r => {
            r.value = format(this.format_spec)(r.value);
            return r;
        })
    },
  }
}
</script>
